.recommendation-body {
    margin-top: 30px;
    max-height: 45vh;
    height: auto;
    overflow-y: auto; 
    padding-bottom: 10px;
    padding-left: 10px
}
.recommendation-modal-body {
    margin-top: 30px;
    max-height: 60vh;
    height: auto;
    overflow-y: auto; 
    padding-bottom: 10px;
    padding-left: 15px
}
.item-container {
    display: flex;
}
.title {
    font-weight: 800; 
    margin-bottom: 17px;
}
@media(max-width: 768px) {
    .recommendation-body {
        display: none;
    }
    .title {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}
.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px 40px 0px;
}
.checkout-button {
    padding-top: 40px;
    font-weight: 800;
    font-size: 14px;
    color: #CF0082;
    text-transform: uppercase;
}