import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { cartActions } from '../../store/cart-slice';
import classes from './TypeTextCard.module.css';

const TypeTextCard = (props) => {
  const option = props.option;
  const [secondaryText, setSecondaryText] = useState('');
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();
  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
    );
    const isEdit = useSelector(
      (state: RootState) => state.cart.isEdit
      );
  const toggleSelection = (option) => event => {
    setSelected((prevState) => {
      {
        props.cardClick(option, !prevState);
        if (prevState) {
          dispatch(
            cartActions.removeItemCost({ cost: option.cost })
          );
          dispatch(
            cartActions.removeModifiers({ modifiers: option.id })
          );
          return !selected;
        } else {
          if (!props.maxselects || props.selectedOptions.length <= props.maxselects - 1) {
            dispatch(
              cartActions.addItemCost({ cost: option.cost })
            );
            dispatch(
              cartActions.addModifiers({ modifiers: option.id })
            );
            return !selected;
          } else {
            props.showError();
            return false;
          }
        }
      }
    });
  }
  useEffect(() => {
    if (option.metadata && option.metadata.length) {
        for (let element of option.metadata) {
            if (element.key == 'text') {
                if (element.value) {
                  setSecondaryText(element.value);
                }
            }
        }
    }
  }, [option]);
  
    useEffect(() =>{
      if(props.selectedOptions.some((selectedOption) => selectedOption.id === option.id))
        setSelected(true);
      if(isEdit){
        if(currentAddedModifiers.some((selectedOption) => selectedOption === option.id))
        setSelected(true);
      }
    },[props.selectedOptions])
  

  return (
    <button className={selected ? classes['card'] + ' ' + classes['selected-card'] : classes['card']} onClick={toggleSelection(option)}>
      <span className={classes['span-main']}>
        <i className={selected ? classes['bi-dash-circle-fill'] : ''}></i>
      </span>
      <div className={classes['textcard-name']}>{option.name}</div>
      <div className={classes['textcard-second-name']}>{secondaryText}</div>
      {option.basecalories ? <div className={classes['textcard-calories']}>{option.basecalories} Calories</div> : null}
      {option.cost ? <div className={classes['textcard-cost']}>+ ${option.cost.toFixed(2)}</div> : null}
    </button>

  );
};

export default TypeTextCard;