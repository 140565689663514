import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import classes from './MenuHeading.module.css';

const MenuHeading = (props) => {
  const content: any = useSelector((state: RootState) => state.buttercms.content);
  return (
    <h1 className={classes.heading}>{content.welcomeMsg}</h1>
  );
};

export default MenuHeading;
