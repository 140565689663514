import { useSelector } from "react-redux";
import { RootState } from "../../store";
import TSCButton from "../UI/Button";
import TextInput from "../UI/TextInput";
import classes from "./Search.module.css";

const Search = (props) => {
  const content: any = useSelector((state: RootState) => state.buttercms.content);
  return (
    <div>
       <p className={classes['cart-go-ahead']}>{content.cartFooterText}</p>
      <div className={classes['search-main']}>
      <div className={classes['search-div']}>
        <TextInput className={classes['search-text']} onTextChange={props.onSearchChangeHandler} textValue={props.searchTextValue}
          placeholder={content.searchText} />
        <TSCButton className={classes['search-btn']}>{content.searchButtonCta}</TSCButton>
      </div>
    </div>
    </div>
  );
};

export default Search;
