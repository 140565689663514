.name-main {
    display: flex;
    align-items: center;
    min-height: 150px;
    margin-left: 20px;
  }
.name-text {
    width: auto;
    flex-grow: 1;
  }
  @media (max-width: 470px) {
    .name-main {
      flex-direction: column;
      margin-top: 15px;
    }
    .name-text {
      flex-grow: unset;
    }
    .save-btn {
      margin-top: 20px;
    }
  }