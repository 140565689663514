"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = exports.Group = exports.UpsellResponse = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * UpsellResponse Model
 */
var UpsellResponse = /** @class */ (function (_super) {
    __extends(UpsellResponse, _super);
    function UpsellResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Basket class
     * @return {ObjectSchema}
     */
    UpsellResponse.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return UpsellResponse;
}(BaseModel_1.BaseModel));
exports.UpsellResponse = UpsellResponse;
/**
 * Group model
 */
var Group = /** @class */ (function (_super) {
    __extends(Group, _super);
    function Group() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Group class
     * @return {ObjectSchema}
     */
    Group.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Group;
}(BaseModel_1.BaseModel));
exports.Group = Group;
/**
 * Item model
 */
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Item class
     * @return {ObjectSchema}
     */
    Item.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Item;
}(BaseModel_1.BaseModel));
exports.Item = Item;
