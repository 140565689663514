.card {
    min-width: 129px;
    max-width: 129px;
    min-height: 106px;
    background: #FFFFFF;
    box-shadow: -4px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border: 4px solid transparent;
}
.textcard-image {
    width: 106px;
    min-height: 97px;
    align-self: center;
    margin-bottom: 10px;
    margin-top: 2px;
}
.textcard-name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
}
.textcard-second-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
}
.textcard-calories {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #5C5C5C;
    margin-top: 4px;
}
.textcard-cost {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    margin-top: 6px;
    margin-bottom: 19px;
}
.bi-dash-circle-fill:before {
    color: #000;
    font-size: 30px;
    box-shadow: -4px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 20px;
}
.bi-dash-circle-fill:before {
    content: "\F26B";
    color: #fff;
    background: #FFBA00;
}
.selected-card {
    border: 4px solid #FFBA00;
}
.span-main {
    height: 26px;
    align-self: end;
    margin-top: -14px;
    margin-right: -14px;
}