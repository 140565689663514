import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Card from "../UI/Card";
import classes from "./DropDownModal.module.css";
import CloseButton from "react-bootstrap/CloseButton";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import { firebaseConfig, TRANSLATION_PATH } from "../../constants/constants";
const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onConfirm} />;
};
const ModalOverlay = (props) => {
  const [vegitarianTitle, setVegitarianTtile] = useState('');
  const [glutenTitle, setGlutenTitle] = useState('');
  const [vegitarianDescription, setVegitarianDescription] = useState('');
  const [glutenDescription, setGlutenDescription] = useState('');
    useEffect( async () => {
      const app = initializeApp(firebaseConfig);   
      const firebaseDB = getDatabase(app);
      const query = ref(firebaseDB, TRANSLATION_PATH);
      var firebaseData = null;
      await onValue(query, (snapshot) => {
          firebaseData = snapshot.val();
          setVegitarianTtile(firebaseData.vegiterian_title);
          setGlutenTitle(firebaseData.gluten_friendly_title);
          setVegitarianDescription(firebaseData.vegitarian_description);
          setGlutenDescription(firebaseData.gluten_friendly_description);
      });
    }, [props]);
    
    let concernedElement = null;
    document.addEventListener("mousedown", (event) => {
      concernedElement = document.querySelector(".backdrop_441f84");
      if(concernedElement != null){
        if (concernedElement.contains(event.target)) {
          concernedElement = null;
          props?.onClose();
        }
      }
    });
  return (
    <Card className={classes.modal_dietary}>
      <CloseButton
        className={classes["close-button"]}
        onClick={props.onClose}
      />
      <Fragment>
        <div tabIndex={0}>
          <div className={classes["dietary-indicator-popup"]}>
            {props?.indicatorKeyData === "V" && vegitarianTitle.length > 0 ? (
              <h2 className={classes["dietary-popup-heading"]}>
                {vegitarianTitle}
              </h2>
            ) : glutenTitle.length > 0 ? (
              <h2 className={classes["dietary-popup-heading"]}>
                {glutenTitle}
              </h2>
            ) : null}
            {props?.indicatorKeyData === "V" && vegitarianDescription.length > 0 ? (
              <p>{vegitarianDescription}</p>
            ) : glutenDescription.length > 0 ? (
              <p>{glutenDescription}</p>
            ) : null}

            <button
              className={classes["dietary-close-button"]}
              onClick={props.onClose}
            >
              <div className={classes["dietary-close-text"]}>Okay</div>
            </button>
          </div>
        </div>
      </Fragment>
    </Card>
  );
};
const DropDownModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-modal")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={props.onClose}
          indicatorKeyData={props?.indicatorKey}
        />,
        document.getElementById("overlay-modal")
      )}
    </React.Fragment>
  );
};
export default DropDownModal;
