"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveVehicleDetailsRequest = exports.AddUserRelationRequest = exports.FetchUserBalance = exports.AppleRegistrationRequest = exports.UserConnectFbRequest = exports.UserBarCodeRequest = exports.UserLogoutRequest = exports.UserForgotPasswordRequest = exports.UserLoginRequest = exports.UserUpdateRequest = exports.UserRegisterRequest = void 0;
var validations_1 = require("../../utils/validations");
var crypto_1 = require("../../utils/crypto");
var BaseModel_1 = require("../BaseModel");
var _ = require("lodash");
/**
 * Data required to make register request
 */
var UserRegisterRequest = /** @class */ (function (_super) {
    __extends(UserRegisterRequest, _super);
    /**
     * Constructor for UserRegisterRequest
     * @param {Object} obj - contains all the properties in UserRegisterRequest
     */
    function UserRegisterRequest(obj) {
        var _this = _super.call(this, obj) || this;
        _this.password = crypto_1.aesEncrypt(_this.password);
        _this.passwordConfirmation = crypto_1.aesEncrypt(_this.passwordConfirmation);
        return _this;
    }
    /**
     * Validation schema for UserRegisterRequest
     * @return {ObjectSchema}
     */
    UserRegisterRequest.prototype.schema = function () {
        return validations_1.type({
            email: validations_1.isEmail,
            password: validations_1.string(),
            firstName: validations_1.string(),
            lastName: validations_1.string(),
            passwordConfirmation: validations_1.string(),
            phone: validations_1.optional(validations_1.string()),
            termsAndConditions: validations_1.optional(validations_1.boolean()),
            birthday: validations_1.optional(validations_1.string()),
            anniversary: validations_1.optional(validations_1.isDate),
            state: validations_1.optional(validations_1.string()),
            gender: validations_1.optional(validations_1.string()),
            city: validations_1.optional(validations_1.string()),
            avatarRemoteUrl: validations_1.optional(validations_1.string()),
            apnToken: validations_1.optional(validations_1.string()),
            gcmToken: validations_1.optional(validations_1.string()),
            zipCode: validations_1.optional(validations_1.string()),
            favouriteLocationIds: validations_1.optional(validations_1.string()),
            address: validations_1.optional(validations_1.string()),
            sendComplianceSms: validations_1.optional(validations_1.boolean()),
            profileFieldAnswers: validations_1.optional(validations_1.type({
                description: validations_1.string(),
            })),
            inviteCode: validations_1.optional(validations_1.string()),
            externalSource: validations_1.optional(validations_1.string()),
            externalSourceId: validations_1.optional(validations_1.string()),
            signupChannel: validations_1.optional(validations_1.string()),
            marketingEmailSubscription: validations_1.optional(validations_1.boolean()),
            marketingPnSubscription: validations_1.optional(validations_1.boolean()),
        });
    };
    return UserRegisterRequest;
}(BaseModel_1.BaseModel));
exports.UserRegisterRequest = UserRegisterRequest;
/**
 * Data required to make user update request
 */
var UserUpdateRequest = /** @class */ (function (_super) {
    __extends(UserUpdateRequest, _super);
    /**
     * Constructor for UserUpdateRequest
     * @param {Object} obj - contains all the properties in UserRegisterRequest
     */
    function UserUpdateRequest(obj) {
        var _a, _b, _c;
        var _this = _super.call(this, obj) || this;
        if (!_.isEmpty(_this.password))
            _this.password = crypto_1.aesEncrypt((_a = _this.password) !== null && _a !== void 0 ? _a : '');
        if (!_.isEmpty(_this.passwordConfirmation))
            _this.passwordConfirmation = crypto_1.aesEncrypt((_b = _this.passwordConfirmation) !== null && _b !== void 0 ? _b : '');
        if (!_.isEmpty(_this.currentPassword))
            _this.currentPassword = crypto_1.aesEncrypt((_c = _this.currentPassword) !== null && _c !== void 0 ? _c : '');
        return _this;
    }
    /**
     * Validation schema for UserUpdateRequest
     * @return {ObjectSchema}
     */
    UserUpdateRequest.prototype.schema = function () {
        return validations_1.type({
            email: validations_1.optional(validations_1.isEmail),
            password: validations_1.optional(validations_1.string()),
            firstName: validations_1.optional(validations_1.string()),
            lastName: validations_1.optional(validations_1.string()),
            passwordConfirmation: validations_1.optional(validations_1.string()),
            currentPassword: validations_1.optional(validations_1.string()),
            phone: validations_1.optional(validations_1.string()),
            termsAndConditions: validations_1.optional(validations_1.boolean()),
            birthday: validations_1.optional(validations_1.string()),
            anniversary: validations_1.optional(validations_1.isDate),
            state: validations_1.optional(validations_1.string()),
            gender: validations_1.optional(validations_1.string()),
            city: validations_1.optional(validations_1.string()),
            avatarRemoteUrl: validations_1.optional(validations_1.string()),
            apnToken: validations_1.optional(validations_1.string()),
            gcmToken: validations_1.optional(validations_1.string()),
            zipCode: validations_1.optional(validations_1.string()),
            favouriteLocationIds: validations_1.optional(validations_1.string()),
            address: validations_1.optional(validations_1.string()),
            sendComplianceSms: validations_1.optional(validations_1.boolean()),
            profileFieldAnswers: validations_1.optional(validations_1.type({
                description: validations_1.string(),
            })),
            inviteCode: validations_1.optional(validations_1.string()),
            externalSource: validations_1.optional(validations_1.string()),
            externalSourceId: validations_1.optional(validations_1.string()),
            signupChannel: validations_1.optional(validations_1.string()),
            marketingEmailSubscription: validations_1.optional(validations_1.boolean()),
            marketingPnSubscription: validations_1.optional(validations_1.boolean()),
        });
    };
    return UserUpdateRequest;
}(BaseModel_1.BaseModel));
exports.UserUpdateRequest = UserUpdateRequest;
/**
 * Data required to make login request
 */
var UserLoginRequest = /** @class */ (function (_super) {
    __extends(UserLoginRequest, _super);
    /**
     * Constructor for UserLoginRequest
     * @param {Object} obj - contains all the properties in UserLoginRequest
     */
    function UserLoginRequest(obj) {
        var _this = _super.call(this, obj) || this;
        _this.password = crypto_1.aesEncrypt(_this.password);
        return _this;
    }
    /**
     * Validation schema for UserLoginRequest
     * @return {ObjectSchema}
     */
    UserLoginRequest.prototype.schema = function () {
        return validations_1.object({
            email: validations_1.string(),
            password: validations_1.string(),
            deviceToken: validations_1.optional(validations_1.string()),
        });
    };
    return UserLoginRequest;
}(BaseModel_1.BaseModel));
exports.UserLoginRequest = UserLoginRequest;
/**
 * Data required to make forgot password request
 */
var UserForgotPasswordRequest = /** @class */ (function (_super) {
    __extends(UserForgotPasswordRequest, _super);
    function UserForgotPasswordRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for UserForgotPasswordRequest
     * @return {ObjectSchema}
     */
    UserForgotPasswordRequest.prototype.schema = function () {
        return validations_1.object({
            email: validations_1.string(),
        });
    };
    return UserForgotPasswordRequest;
}(BaseModel_1.BaseModel));
exports.UserForgotPasswordRequest = UserForgotPasswordRequest;
/**
 * Data required to make logout request
 */
var UserLogoutRequest = /** @class */ (function (_super) {
    __extends(UserLogoutRequest, _super);
    function UserLogoutRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for UserLogoutRequest
     * @return {ObjectSchema}
     */
    UserLogoutRequest.prototype.schema = function () {
        return validations_1.object({
            accessToken: validations_1.string(),
            oloAccessToken: validations_1.string(),
            deviceToken: validations_1.optional(validations_1.string()),
        });
    };
    return UserLogoutRequest;
}(BaseModel_1.BaseModel));
exports.UserLogoutRequest = UserLogoutRequest;
/**
 * Data required to make bar code auth request
 */
var UserBarCodeRequest = /** @class */ (function (_super) {
    __extends(UserBarCodeRequest, _super);
    function UserBarCodeRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for BarCodeRequest
     * @return {ObjectSchema}
     */
    UserBarCodeRequest.prototype.schema = function () {
        return validations_1.object({
            barCode: validations_1.string(),
            client: validations_1.optional(validations_1.string()),
            gpsAccuracy: validations_1.optional(validations_1.string()),
            latitude: validations_1.number(),
            locationId: validations_1.optional(validations_1.string()),
            longitude: validations_1.number(),
            manual: validations_1.optional(validations_1.string()),
        });
    };
    return UserBarCodeRequest;
}(BaseModel_1.BaseModel));
exports.UserBarCodeRequest = UserBarCodeRequest;
/**
 * Data required to make facebook connect
 */
var UserConnectFbRequest = /** @class */ (function (_super) {
    __extends(UserConnectFbRequest, _super);
    function UserConnectFbRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for facebok connect
     * @return {ObjectSchema}
     */
    UserConnectFbRequest.prototype.schema = function () {
        return validations_1.object({
            facebookAccessToken: validations_1.string(),
            deviceToken: validations_1.string(),
        });
    };
    return UserConnectFbRequest;
}(BaseModel_1.BaseModel));
exports.UserConnectFbRequest = UserConnectFbRequest;
/**
 * Data required for making connect with apple request
 */
var AppleRegistrationRequest = /** @class */ (function (_super) {
    __extends(AppleRegistrationRequest, _super);
    /**
     * Constructor for AppleRegistrationRequest
     * @param {AppleRegistrationRequest} obj
     */
    function AppleRegistrationRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for AppleRegistrationRequest
     * @return {ObjectSchema}
     */
    AppleRegistrationRequest.prototype.schema = function () {
        return validations_1.object({
            firstName: validations_1.string(),
            lastName: validations_1.string(),
            gender: validations_1.optional(validations_1.string()),
            externalSource: validations_1.string(),
            externalSourceId: validations_1.string(),
            authorizationCode: validations_1.string(),
            deviceToken: validations_1.string(),
        });
    };
    return AppleRegistrationRequest;
}(BaseModel_1.BaseModel));
exports.AppleRegistrationRequest = AppleRegistrationRequest;
/**
 * Data required for making fetch user balance request
 */
var FetchUserBalance = /** @class */ (function (_super) {
    __extends(FetchUserBalance, _super);
    /**
     * Constructor for FetchUserBalance
     * @param {FetchUserBalance} obj
     */
    function FetchUserBalance(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for FetchUserBalance
     * @return {ObjectSchema}
     */
    FetchUserBalance.prototype.schema = function () {
        return validations_1.object({
            filterBy: validations_1.optional(validations_1.string()),
        });
    };
    return FetchUserBalance;
}(BaseModel_1.BaseModel));
exports.FetchUserBalance = FetchUserBalance;
/**
 * Data required for making add user relation requestj
 */
var AddUserRelationRequest = /** @class */ (function (_super) {
    __extends(AddUserRelationRequest, _super);
    /**
     * Constructor for AddUserRelationRequest
     * @param {AddUserRelationRequest} obj
     */
    function AddUserRelationRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for AddUserRelationRequestj
     * @return {ObjectSchema}
     */
    AddUserRelationRequest.prototype.schema = function () {
        return validations_1.object({
            relation: validations_1.string(),
            name: validations_1.string(),
            birthday: validations_1.isDate,
        });
    };
    return AddUserRelationRequest;
}(BaseModel_1.BaseModel));
exports.AddUserRelationRequest = AddUserRelationRequest;
/**
 * Data required for making save vehicle details
 */
var SaveVehicleDetailsRequest = /** @class */ (function (_super) {
    __extends(SaveVehicleDetailsRequest, _super);
    /**
     * Constructor for SaveVehicleDetailsRequest
     * @param {SaveVehicleDetailsRequest} obj
     */
    function SaveVehicleDetailsRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for SaveVehicleDetailsRequest
     * @return {ObjectSchema}
     */
    SaveVehicleDetailsRequest.prototype.schema = function () {
        return validations_1.object({
            name: validations_1.optional(validations_1.string()),
            make: validations_1.optional(validations_1.string()),
            model: validations_1.optional(validations_1.string()),
            color: validations_1.optional(validations_1.string()),
            isdefault: validations_1.optional(validations_1.boolean()),
        });
    };
    return SaveVehicleDetailsRequest;
}(BaseModel_1.BaseModel));
exports.SaveVehicleDetailsRequest = SaveVehicleDetailsRequest;
