.detail-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}
.product-name {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #111111;
  align-self: center;
}
.product-cost {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #111111;
  margin-top: 5px;
  margin-left: 3px;
}
.product-weight {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #111111;
  mix-blend-mode: multiply;
}
.product-description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
  margin-top: 10px;
}
.indicator-div {
  margin: 40px 0 20px 0;
}

.indicator-div-nutrition {
  margin: 1em 0em;
}
.nutrition-main-more{
  border: 2px solid #82cf00;
  background: white;
  box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
  border-radius: 50px;
  width: 90%;
  margin: 0;
  display: flex;
  justify-content: center;
  margin: auto;
}
.nutrition-anchor{
  text-decoration: none;
}
.nutrition-allergeon{
  padding: 0 1.2em;
  margin: 4em 0em 2em 0em;
}
.nutrition-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
}
.nutrition-allergeon-text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d232e;
}
.nutrition-allergeon-heading{
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #111111;
  mix-blend-mode: multiply;
  margin: 1em 0em;
}
.moreInfo-text{
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #111111;
  mix-blend-mode: multiply;
  margin: 1em 0em;
  padding: 0 1.2em;
}
@media (max-width: 470px) {
  .product-name {
    font-size: 23px;
  }
  .detail-row {
    margin-bottom: 8px;
  }
}

.nutrition-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid rgb(190, 190, 190);
  padding: 0.5em 0em;
}

.nutrition-list:nth-child(1) {
  border-top: 1px solid rgb(190, 190, 190);
}

.nutrition-list:nth-child(14) {
  border-bottom: none
}

.nutrition-info-modal-header {
  padding: 0 1.2em;
}

.nutrition-info-modal-container {
  padding: 0 1.2em;
  margin: 1em 0em;
}

.nutrient-item-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d232e;
}

.nutrient-item-value {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1d232e;
}
