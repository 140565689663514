"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Choices = exports.Products = exports.FavOrders = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * FavOrders Model
 */
var FavOrders = /** @class */ (function (_super) {
    __extends(FavOrders, _super);
    function FavOrders() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for FavOrders class
     * @return {ObjectSchema}
     */
    FavOrders.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return FavOrders;
}(BaseModel_1.BaseModel));
exports.FavOrders = FavOrders;
/**
 * Data  Model
 */
var Data = /** @class */ (function (_super) {
    __extends(Data, _super);
    function Data() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Taxes class
     * @return {ObjectSchema}
     */
    Data.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Data;
}(BaseModel_1.BaseModel));
/**
 * Products  Model
 */
var Products = /** @class */ (function (_super) {
    __extends(Products, _super);
    function Products() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Products class
     * @return {ObjectSchema}
     */
    Products.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Products;
}(BaseModel_1.BaseModel));
exports.Products = Products;
/**
 * Choices  Model
 */
var Choices = /** @class */ (function (_super) {
    __extends(Choices, _super);
    function Choices() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Choices class
     * @return {ObjectSchema}
     */
    Choices.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Choices;
}(BaseModel_1.BaseModel));
exports.Choices = Choices;
