"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aesEncrypt = exports.base64Decode = exports.base64Encode = void 0;
var CryptoJS = require("crypto-js");
var key = CryptoJS.enc.Utf8.parse('aesEncryptionTSC');
var iv = CryptoJS.enc.Utf8.parse('encryptionIntVec');
var base64Encode = function (str) {
    try {
        return btoa(str);
    }
    catch (err) {
        return Buffer.from(str).toString('base64');
    }
};
exports.base64Encode = base64Encode;
var base64Decode = function (str) {
    try {
        return atob(str);
    }
    catch (err) {
        return Buffer.from(str, 'base64').toString();
    }
};
exports.base64Decode = base64Decode;
var aesEncrypt = function (str) {
    return CryptoJS.AES.encrypt(str, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
};
exports.aesEncrypt = aesEncrypt;
