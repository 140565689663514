.logo {
    margin-bottom: 53px;
    width: 200px;
    height: 43px;
}
@media(max-width: 960px) {
    .logo {
        align-self: center;
        margin-bottom: 10px;
        height: 30px;
    }
  }
  @media(max-width: 768px) {
    .logo {
        width: 150px;
    }
  }