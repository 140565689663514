import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Loader.module.css';

const Backdrop = (props) => {
  return <div className={classes.backdrop} />;
};

const LoaderOverlay = (props) => {
  return (
    <div className={classes.divLoader}>
      <img className={classes.svgLoader} alt=""/>
    </div>
  );
};

const Loader = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <LoaderOverlay />,
        document.getElementById('overlay-loader')
      )}
    </React.Fragment>
  );
};

export default Loader;