"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserBalance = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * UserBalance model
 */
var UserBalance = /** @class */ (function (_super) {
    __extends(UserBalance, _super);
    function UserBalance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for UserBalance class
     * @return {ObjectSchema}
     */
    UserBalance.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return UserBalance;
}(BaseModel_1.BaseModel));
exports.UserBalance = UserBalance;
/**
 * AccountBalance model
 */
var AccountBalance = /** @class */ (function (_super) {
    __extends(AccountBalance, _super);
    function AccountBalance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for AccountBalance class
     * @return {ObjectSchema}
     */
    AccountBalance.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return AccountBalance;
}(BaseModel_1.BaseModel));
/**
 * ActiveRedemption model
 */
var ActiveRedemption = /** @class */ (function (_super) {
    __extends(ActiveRedemption, _super);
    function ActiveRedemption() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for ChallengeBadge class
     * @return {ObjectSchema}
     */
    ActiveRedemption.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return ActiveRedemption;
}(BaseModel_1.BaseModel));
/**
 * ChallengeBadge model
 */
var ChallengeBadge = /** @class */ (function (_super) {
    __extends(ChallengeBadge, _super);
    function ChallengeBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for ChallengeBadge class
     * @return {ObjectSchema}
     */
    ChallengeBadge.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return ChallengeBadge;
}(BaseModel_1.BaseModel));
/**
 * Checkin model
 */
var Checkin = /** @class */ (function (_super) {
    __extends(Checkin, _super);
    function Checkin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Checkin class
     * @return {ObjectSchema}
     */
    Checkin.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Checkin;
}(BaseModel_1.BaseModel));
/**
 * ConvertedCategoryBalance model
 */
var ConvertedCategoryBalance = /** @class */ (function (_super) {
    __extends(ConvertedCategoryBalance, _super);
    function ConvertedCategoryBalance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for ConvertedCategoryBalance class
     * @return {ObjectSchema}
     */
    ConvertedCategoryBalance.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return ConvertedCategoryBalance;
}(BaseModel_1.BaseModel));
/**
 * Coupon model
 */
var Coupon = /** @class */ (function (_super) {
    __extends(Coupon, _super);
    function Coupon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Coupon class
     * @return {ObjectSchema}
     */
    Coupon.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Coupon;
}(BaseModel_1.BaseModel));
/**
 * Message model
 */
var Message = /** @class */ (function (_super) {
    __extends(Message, _super);
    function Message() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Message class
     * @return {ObjectSchema}
     */
    Message.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Message;
}(BaseModel_1.BaseModel));
/**
 * Content model
 */
var Content = /** @class */ (function (_super) {
    __extends(Content, _super);
    function Content() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Content class
     * @return {ObjectSchema}
     */
    Content.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Content;
}(BaseModel_1.BaseModel));
/**
 * Cta model
 */
var Cta = /** @class */ (function (_super) {
    __extends(Cta, _super);
    function Cta() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Cta class
     * @return {ObjectSchema}
     */
    Cta.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Cta;
}(BaseModel_1.BaseModel));
/**
 * Notification model
 */
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Notification class
     * @return {ObjectSchema}
     */
    Notification.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Notification;
}(BaseModel_1.BaseModel));
/**
 * PreferredCharity model
 */
var PreferredCharity = /** @class */ (function (_super) {
    __extends(PreferredCharity, _super);
    function PreferredCharity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for PreferredCharity class
     * @return {ObjectSchema}
     */
    PreferredCharity.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return PreferredCharity;
}(BaseModel_1.BaseModel));
/**
 * UserDonations model
 */
var UserDonations = /** @class */ (function (_super) {
    __extends(UserDonations, _super);
    function UserDonations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for UserDonations class
     * @return {ObjectSchema}
     */
    UserDonations.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return UserDonations;
}(BaseModel_1.BaseModel));
/**
 * Currency model
 */
var Currency = /** @class */ (function (_super) {
    __extends(Currency, _super);
    function Currency() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Currency class
     * @return {ObjectSchema}
     */
    Currency.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Currency;
}(BaseModel_1.BaseModel));
/**
 * Reward model
 */
var Reward = /** @class */ (function (_super) {
    __extends(Reward, _super);
    function Reward() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Reward class
     * @return {ObjectSchema}
     */
    Reward.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Reward;
}(BaseModel_1.BaseModel));
/**
 * PunchCard model
 */
var PunchCard = /** @class */ (function (_super) {
    __extends(PunchCard, _super);
    function PunchCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for PunchCard class
     * @return {ObjectSchema}
     */
    PunchCard.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return PunchCard;
}(BaseModel_1.BaseModel));
/**
 * Progress model
 */
var Progress = /** @class */ (function (_super) {
    __extends(Progress, _super);
    function Progress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Progress class
     * @return {ObjectSchema}
     */
    Progress.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Progress;
}(BaseModel_1.BaseModel));
