import OptionsImageCard from "./OptionsImageCard";
import OptionsSlide from "./OptionsSlide";
import OptionsTextCard from "./OptionsTextCard";
import OptionsDropdown from "./OptionsDropdown";

const OptionGroups = (props) => {
  const optionGroups = props.optionGroups;
  let structure = [];
  for (let optionGroup of optionGroups) {
    if (optionGroup.metadata && optionGroup.metadata.length) {
      for (let optionMeta of optionGroup.metadata) {
        if (optionMeta.key == "type") {
          switch (optionMeta.value) {
            case "imagecard":
              structure.push(
                <OptionsImageCard
                  product={props.product}
                  optionGroup={optionGroup}
                  key={optionGroup.id}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              );
              break;
            case "expand":
              structure.push(
                <OptionsImageCard
                  optionGroup={optionGroup}
                  key={optionGroup.id}
                  type={optionMeta.value}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              );
              break;
            case "slide":
              structure.push(
                <OptionsSlide optionGroup={optionGroup} 
                key={optionGroup.id} 
                isEdit = {props.isEdit ?? false}
                uniqueId = {props.uniqueId}
                />
              );
              break;
            case "textcard":
              structure.push(
                <OptionsTextCard
                  optionGroup={optionGroup}
                  key={optionGroup.id}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              );
              break;
            case "dropdown":
              structure.push(
                <OptionsDropdown
                  optionGroup={optionGroup}
                  key={optionGroup.id}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              );
              break;
            default:
              structure.push(
                <OptionsTextCard
                  optionGroup={optionGroup}
                  key={optionGroup.id}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              );
              break;
          }
          break;
        }
      }
    } else {
      structure.push(
        <OptionsTextCard 
        optionGroup={optionGroup} 
        key={optionGroup.id} 
        isEdit = {props.isEdit ?? false}
        uniqueId = {props.uniqueId} />
      );
    }
  }
  return <div style={{ margin: "0 0 25px 0" }}>{structure}</div>;
};

export default OptionGroups;
