import { combineReducers, configureStore } from '@reduxjs/toolkit';

import uiSlice from './ui-slice';
import catelogSlice from './catalog-slice';
import productDetailSlice from './product-details-slice';
import cartSlice from './cart-slice';
import loaderSlice from './loader-slice';
import buttercmsSlice from './buttercms-slice';

export const rootReducer = combineReducers(
  {
    ui: uiSlice.reducer,
    catelog: catelogSlice.reducer,
    productDetail: productDetailSlice.reducer,
    cart: cartSlice.reducer,
    buttercms: buttercmsSlice.reducer,
    loader: loaderSlice.reducer
  });

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer
})

export default store;
