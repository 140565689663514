"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountHistory = exports.connectApple = exports.connectFB = exports.logout = exports.login = exports.register = void 0;
var Register_1 = require("./Register");
Object.defineProperty(exports, "register", { enumerable: true, get: function () { return Register_1.register; } });
var Login_1 = require("./Login");
Object.defineProperty(exports, "login", { enumerable: true, get: function () { return Login_1.login; } });
var Logout_1 = require("./Logout");
Object.defineProperty(exports, "logout", { enumerable: true, get: function () { return Logout_1.logout; } });
var ConnectFB_1 = require("./ConnectFB");
Object.defineProperty(exports, "connectFB", { enumerable: true, get: function () { return ConnectFB_1.connectFB; } });
var ConnectApple_1 = require("./ConnectApple");
Object.defineProperty(exports, "connectApple", { enumerable: true, get: function () { return ConnectApple_1.connectApple; } });
var AccountHistory_1 = require("./AccountHistory");
Object.defineProperty(exports, "accountHistory", { enumerable: true, get: function () { return AccountHistory_1.accountHistory; } });
