import RecommendationRow from "./RecommendationRow";
import classes from "./RecommendationBody.module.css";
import { useLocation } from "react-router-dom";
import { API } from "tsc-core";
import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { firebaseConfig, TRANSLATION_PATH } from "../../../constants/constants";
import { getDatabase, onValue, ref } from "firebase/database";

const RecommendationBody = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const basketId = queryParams.get("basket");
  const basketIdGO = queryParams.get("basketid")
  const [upsellData, setUpsellData] = useState({
    groups: [],
  });
  const [youMightAlsoEnjoy, setYouMightAlsoEnjoy] = useState('');

  const getRecommendation = async () => {
    let response = await API.CART.getUpsell(basketId ?? basketIdGO as any);
    setUpsellData({
      groups: response?.groups as any,
    });
  };

  useEffect(() => {
    getRecommendation();
  }, []);

  useEffect(() => {
    const fetchFromFirebase = async() =>{
      const app = initializeApp(firebaseConfig);   
      const firebaseDB = getDatabase(app);
      const query = ref(firebaseDB, TRANSLATION_PATH);
      var firebaseData = null;
      await onValue(query, (snapshot) => {
          firebaseData = snapshot.val(); 
          setYouMightAlsoEnjoy(firebaseData.Group_Order_Upsell_Heading)
      });
    }
    fetchFromFirebase().catch(console.error)
  }, []);
  
  return upsellData.groups.length > 0 && (
    <div className={classes["recommendation-body"]}>
      <h6 className={classes["title"]}>{youMightAlsoEnjoy}</h6>
      {upsellData.groups.length > 0 &&
        upsellData.groups.map((group) => (
          <RecommendationRow
            key={group.title}
            items={group.items}
            title={group.title}
          />
        ))}
    </div>
  );
};

export default RecommendationBody;
