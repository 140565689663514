.notification {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem 10%;
  align-items: center;
  color: white;
  opacity: 0.8;
  position: absolute;
}

.notification h2,
.notification p {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
}

.error {
  background-color: #690000;
}

.success {
  background-color: #1ad1b9;
}
.remove {
  display: none;
}