import { API } from 'tsc-core';
import { AddProductToBasketRequest } from 'tsc-core/dist/models/request/CartRequest';
import { firebaseAnalytics } from '../components/helpers/FirebaseAnalytics';
import { cartActions } from './cart-slice';
import { loaderActions } from './loader-slice';
import { uiActions } from './ui-slice';

export const updateCartData = (productId, quantity, choices, basketId2) => {

    
    const prodId = parseInt(productId);
    let options = [];
    for (let i = 0; i < choices.length; i++) {
        options.push(choices[i].optionid)
    }

    return async (dispatch: any, getState) => {
        dispatch(
            cartActions.showError({
                errorMessage: ''
            })
        );
        const addData = async () => {
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: true
                })
            );
            const name = localStorage.getItem('name');
            const queryParams = new URLSearchParams(location.search);
            const basketId = queryParams.get('basket') ?? basketId2;
            const { modifiers } = getState().cart;
            const { isEdit } = getState().cart;
            try {
                const response: any = await API.CART.updateProductInBasket(
                    basketId,
                    prodId,
                    new AddProductToBasketRequest({
                        "productid": prodId,
                        "quantity": quantity,
                        "options": isEdit ? modifiers.toString() : options.toString(),
                        "recipient": name ? name : ''
                    })
                )
                return response;
            } catch (error) {
                firebaseAnalytics(error, 'GO_ERR_UPDATE_PRODUCT_IN_BASKET_API')
                return error;
            }
        };

        try {
            const cartData: any = await addData();
            if (cartData && cartData.hasOwnProperty('products')) {
                dispatch(
                    cartActions.getProducts({
                        productsInCart: cartData.products,
                        errorMessage: ''
                    })
                );
                dispatch(
                    uiActions.showNotification({
                        status: 'success',
                        title: 'Success!',
                        message: 'Product Updated Successfully!',
                    })
                );
            } else {
                dispatch(
                    uiActions.showNotification({
                        status: 'error',
                        title: 'Error!',
                        message: cartData.text,
                    })
                );
            }
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        } catch (error) {
            uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: error.text,
            })
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        }
    };
};