import TypeSlide from "./TypeSlide";
import classes from './OptionsSlide.module.css';
import OptionGroupHeading from "./OptionGroupHeading";
import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

const OptionsSlide = (props) => {
    const [customizedSelections, setCustomizedSelections] = useState([]);
    const [showResetButton, setShowResetButton] = useState(false)
    const [resetClicked, setResetClicked] = useState(false);
    const productInCart = useSelector(
        (state: RootState) => state.cart.productsInCart
      );
      const currentAddedModifiers = useSelector(
        (state: RootState) => state.cart.modifiers
      );
      const isEdit = useSelector(
        (state: RootState) => state.cart.isEdit
      );
    
    const optionGroup = props.optionGroup;
    
    const addCustomization = param => {
        if (customizedSelections.length == 0) {
            setResetClicked(prevState => false);
        }
        if (param) {
            setCustomizedSelections(prevState => {
                if (prevState && prevState.length) {
                    return [...prevState, param]
                } else {
                    return [param]
                }
            })
        }
    }
    const removeCustomization = param => {
        setResetClicked(false)
        if (param) {
            setCustomizedSelections(prevState => {
                if (prevState) {
                    let data = prevState;
                    const filtered = data.filter(dt => {
                        return dt != param;
                    })
                    return filtered;
                } else {
                    return [];
                }
            })
        }
    }
    
    const resetIngredients = () => {
        setResetClicked(prevState => true);
        setCustomizedSelections(prevState => []);
        setShowResetButton(false)
    }
    let optionData = [];

    const setResetButton = (param) => {
        setShowResetButton(param);
    }

    if (optionGroup.options && optionGroup.options.length) {
        optionData = optionGroup.options.map(option => {
            return (
              <TypeSlide
                customizedSelections={customizedSelections}
                option={option}
                key={option.id}
                addCustomization={addCustomization}
                removeCustomization={removeCustomization}
                resetClicked={resetClicked}
                isEdit = {props.isEdit ?? false}
                uniqueId = {props.uniqueId}
                setResetButton={setResetButton}
              />
            );
        })

    }
    useEffect(()=>{
        if (productInCart.length > 0 && isEdit) {
            productInCart.forEach((product) => {   
              if (product.id === props.uniqueId) 
                product.choices.forEach((choice) => {
                    if(choice.name.includes("No ")) {
                        setCustomizedSelections((prev) => [...prev, choice.optionid])
                    }
                });
            });
          }
    },[productInCart.length])
    
    return (
        <section tabIndex={0} aria-label={optionGroup.description}>
            <OptionGroupHeading heading={optionGroup.description} />
            <div className={classes['slide-parent']}>
                <div className={classes['slide-main']}>{optionData}</div>
                {showResetButton && customizedSelections && customizedSelections.length ? <button className={classes['reset-ingredients']} onClick={resetIngredients}>Reset Ingredients</button> : null}
            </div>
        </section>

    );
};

export default OptionsSlide;