.product-name {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #111111;
    margin-bottom: 8px;
}
.show-details {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #057D8F;
    margin-bottom: 12px;
    cursor: pointer;
    margin-top: 8px;
}
@media only screen
    and (max-device-width : 768px){ 
      .remove {display: none;}
  }
.remove {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #CF0082;
    margin-left: 8px;
}
.product-modify-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bi-plus-circle-fill,
.bi-dash-circle-fill,
.bi-trash {
    align-self: center;
    cursor: pointer;
}
.bi-plus-circle-fill::before {
    content: "\F4F9";
    font-size: 30px;
}
.bi-dash-circle-fill:before {
    content: "\F2E5";
    font-size: 30px;
}
.bi-trash::before {
    content: '\F5DE';
    color: #CF0082;
} 
.remove-div {
    display: flex;
    cursor: pointer;
    flex: 1;
    align-items: center;
}
.product-hr {
    margin-top: 30px;
    border: 1px solid #E6E7EB;
}
.quantity {
    margin: 0 11px 0 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: right;
    color: #111111;
}
.quantity-div {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}
.product-choice {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}
.disable-remove {
    color: #cbcdd0;
    cursor: none;
}
.product-totalcost {
    flex: 1;
    display: flex;
    justify-content: end;
}