import { createSlice } from '@reduxjs/toolkit';

const buttercmsSlice = createSlice({
    name: 'buttercms',
    initialState: {
        content: {}
    },
    reducers: {
        setContent(state, action) {
            const contents = action.payload.content;
            if (contents && contents.length) {
                state.content = contents[0]
            } else {
                state.content = [];
            }
        }
    },
});

export const butterCmsActions = buttercmsSlice.actions;

export default buttercmsSlice;