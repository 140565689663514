import { Fragment, useEffect, useState } from "react";
import ProductDescription from "./ProductDescription";
import ProductImage from "./ProductImage";
import classes from "./ProductDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductDetail } from "../../store/product-details-actions";
import { RootState } from "../../store";
import OptionGroups from "./OptionGroups";
import AddToCart from "./AddToCart";
import { addCartData } from "../../store/cart-actions";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import { firebaseConfig, TRANSLATION_PATH } from "../../constants/constants";
import mParticle from '@mparticle/web-sdk';
import { updateCartData } from "../../store/update-cart-actions";

const ProductDetails = (props) => {
  const productId = props.product.id;
  const productCategory = props.product.category;
  const dispatch = useDispatch();
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const optionGroups = useSelector(
    (state: RootState) => state.productDetail.optionGroups
  );
  const isEdit = useSelector(
    (state: RootState) => state.cart.isEdit
  );

  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
  );
  const basketId2 = useSelector(
    (state: RootState) => state.cart.basketId
  );
  const cafeId2 = useSelector(
    (state: RootState) => state.cart.cafeId
  );
  const addToCartHandler = () => {
    if(isEdit) 
      dispatch(updateCartData(props.uniqueId, 1, currentAddedModifiers, basketId2));
    if(!isEdit)
      dispatch(addCartData(productId, basketId2, cafeId2, 1));
  };

  useEffect(async () => {
    dispatch(fetchProductDetail(productId));
    const app = initializeApp(firebaseConfig);
    const firebaseDB = getDatabase(app);
    const query = ref(firebaseDB, TRANSLATION_PATH);
    var firebaseData = null;
    await onValue(query, (snapshot) => {
      firebaseData = snapshot.val();
      
      setProductDisclaimer(firebaseData.product_disclaimer);
    });
  }, [productId, dispatch]);

  return (
    <Fragment>
      <div >
        <ProductImage product={props.product} />
        <div className={classes["product-details"]}>
          <div className={classes["product-description"]}>
            <ProductDescription product={props.product} />
          </div>

          {optionGroups && optionGroups.length ? (
            <OptionGroups 
              optionGroups={optionGroups} 
              product={props.product} 
              isEdit = {props.isEdit ?? false}
              uniqueId = {props.uniqueId}/>
          ) : null}

          <p className={classes["product-disclaimer"]}>{productDisclaimer}</p>

          <button
            className={classes["nutrition-main"]}
            onClick={() => props?.onShowNutritionInfo()}
          >
            <div className={classes["nutrition-text"]}>NUTRITION INFO</div>
          </button>
          <AddToCart
              addToCart={addToCartHandler}
              productId={props.product.id}
              isEdit = {props.isEdit ?? false}
              uniqueId = {props.uniqueId}
            />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDetails;
