import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './CustomizationModal.module.css';
import SlideCustomize from './SlideCustomize';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
  const [selected, setSelected] = useState(true);

  const [focusableElements, setFocusableElements] = useState([]);
  const ref: any = useRef()
  const activeElement: any = document.activeElement
  let activeIndex = -1

  const handleTab = evt => {
    let total = focusableElements.length

    if (!evt.shiftKey) {
      // If activeIndex + 1 larger than array length focus first element otherwise focus next element
      activeIndex + 1 === total
        ? activeIndex = 0
        : activeIndex += 1

      focusableElements[activeIndex].focus()

      return evt.preventDefault()
    }

    if (evt.shiftKey) {
      // if activeIndex - 1 less than 0 focus last element otherwise focus previous element
      activeIndex - 1 < 0
        ? activeIndex = total - 1
        : activeIndex -= 1

      focusableElements[activeIndex].focus()

      return evt.preventDefault()
    }
  }

  const handleEscape = evt => {
    evt.preventDefault();
    if (evt.key === 'Escape' && ref.current && ref.current.contains(evt.target)) {
      setSelected(false)
      props.onSetSelected();
    }
    return evt.preventDefault()
  }

  // map of keyboard listeners
  const keyListenersMap = new Map([
    [27, handleEscape],
    [9, handleTab],
  ])

  const handleKeydown = evt => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode)

    // call the listener if it exists
    return listener && listener(evt)
  }

  useEffect(() => {
    if (ref.current && props.customizeOptions && props.customizeOptions.length) {
      // Select all focusable elements within ref
      setFocusableElements(prevState => ref.current.querySelectorAll('a, button, textarea, input, select, section'))
    }
  }, [ref, props.customizeOptions])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      // Detach listener when component unmounts
      document.removeEventListener('keydown', handleKeydown)
      // Return focus to the previously focused element
      activeElement.focus()
    }
  }, [focusableElements])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (selected && ref.current && !ref.current.contains(e.target)) {
        setSelected(false)
        props.onSetSelected();
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [selected])
  return (
    <div className={classes['customization-div']} ref={ref} tabIndex={0}>
      <button onClick={props.closeButtonHandler} className={classes["close-button-customization"]} >+</button>
      <div className={classes['customize-heading-div']}>
        <span className={classes['option-icons']}><i className={classes['bi-shuffle']}></i></span>
        <div className={classes['customize-heading']}>Customize</div>
      </div>
      {
        props.customizeOptions.length ? props.customizeOptions.map(custom => {
          return <SlideCustomize closeButtonHandler={props.closeButtonHandler} setCustomized_selection = {props.setCustomized_selection} key={custom.id} option={custom} onCustomization={props.onCustomization} selectedCustomization={props.selectedCustomization} />
        }) : null
      }

    </div>
  );
};

const CustomizationModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById('backdrop-modal')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay closeButtonHandler={props.closeButtonHandler} setCustomized_selection = {props.setCustomized_selection} customizeOptions={props.customizeOptions} onCustomization={props.onCustomization} selectedCustomization={props.selectedCustomization} onSetSelected={props.onSetSelected} />,
        document.getElementById('overlay-modal')
      )}
    </React.Fragment>
  );
};

export default CustomizationModal;