.item {
    border-radius: 5px;
    box-shadow: 0 0 3px gray;
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 12.8rem;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    overflow: hidden;
}
.selected-item {
    box-shadow: 0 0 3px gray;
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 12.8rem;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    overflow: hidden;
    border: 4px solid #FFBA00;
    border-radius: 10px;
}
.item-container {
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 12.8rem;
    padding: 5px;
    justify-content: flex-start;
    overflow: hidden;
}
.name {
    font-size: 14px; 
    color: #222222;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    margin-top: 9px;
    text-align: center;
}
.calories {
    font-size: 15px; 
    color: #5a5a5a;
    text-align: center;
}
.bi-plus-circle-fill,
.bi-dash-circle-fill,
.bi-plus-circle-fill::before {
    content: "\F4F9";
    font-size: 20px;
    color: #CF0082;
}
.bi-dash-circle-fill:before {
    content: "\F2E5";
    font-size: 20px;
    color: #CF0082;
}
.quantity-div {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-evenly;
}
.cost {
    font-size: 12px; 
    color: #222222;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    padding-top: 5px;
}
@media(max-width: 768px) {
    .item {
        margin-right: 10px;
        width: 125px;
    }
    .selected-item {
        width: 125px;
    }
    .item-container {
        width: 125px;
    }
    .name {
        font-size: 12px
    }
}