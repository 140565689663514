"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.del = exports.get = exports.put = exports.post = exports.setEmailId = exports.clearEmailId = exports.clearAccessTokens = exports.setAccessToken = exports.initClient = exports.createAxios = void 0;
var axios_1 = require("axios");
var humps_1 = require("humps");
var _ = require("lodash");
var Helpers_1 = require("../api/Helpers");
var Constants_1 = require("../Constants");
var logger_1 = require("./logger");
/**
 * @param {AxiosInstance} axiosInstance - Axios Instance
 * @return {void}
 * Interceptors to convert snake_case to camelCase in response and vice versa in request
 */
function applyCamelCaseInterceptors(axiosInstance) {
    // Axios middleware to convert all api responses to camelCase
    axiosInstance.interceptors.response.use(function (response) {
        if (response.data &&
            response.headers['content-type'] === Constants_1.MIME_TYPE.APPLICATION_JSON) {
            response.data = humps_1.camelizeKeys(response.data);
        }
        return response;
    });
    // Axios middleware to convert all api requests to snake_case
    axiosInstance.interceptors.request.use(function (config) {
        var newConfig = __assign({}, config);
        newConfig.url = "" + config.url;
        if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
            return newConfig;
        }
        if (config.params) {
            newConfig.params = humps_1.decamelizeKeys(config.params);
        }
        if (config.data) {
            newConfig.data = humps_1.decamelizeKeys(config.data);
        }
        return newConfig;
    });
}
var OLO_AUTHERIZATION_HEADER = 'olo-authorization';
var PUNCHH_AUTHERIZATION_HEADER = 'punchh-authorization';
/**
 * @param {AxiosRequestConfig} config - Axios Configuration
 * @return {AxiosInstance}
 * Extend axios to add interceptors and error handling
 */
function createAxios(config) {
    var api = axios_1.default.create(config);
    applyCamelCaseInterceptors(api);
    return api;
}
exports.createAxios = createAxios;
var apiClient = null;
var initClient = function (baseURL, deviceId, clientType, userAgent, apiAuthKey) {
    apiClient = createAxios({
        baseURL: baseURL,
    });
    apiClient.defaults.headers.common['client-type'] = clientType;
    apiClient.defaults.headers.common['device-id'] = deviceId;
    apiClient.defaults.headers.common['user-agent'] = userAgent;
    apiClient.defaults.headers.common['x-api-key'] = apiAuthKey;
};
exports.initClient = initClient;
var setAccessToken = function (accessToken, oloToken) {
    if (apiClient == null)
        throw new Error('API Client Not Initialized');
    apiClient.defaults.headers.common['authorization'] = "Bearer " + oloToken;
    apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER] = "Bearer " + oloToken;
    apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER] = "Bearer " + accessToken;
};
exports.setAccessToken = setAccessToken;
var clearAccessTokens = function () {
    apiClient === null || apiClient === void 0 ? true : delete apiClient.defaults.headers.common['authorization'];
    apiClient === null || apiClient === void 0 ? true : delete apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER];
    apiClient === null || apiClient === void 0 ? true : delete apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER];
};
exports.clearAccessTokens = clearAccessTokens;
var clearEmailId = function () {
    apiClient === null || apiClient === void 0 ? true : delete apiClient.defaults.headers.common['email-id'];
};
exports.clearEmailId = clearEmailId;
var setEmailId = function (emailId) {
    if (apiClient == null)
        throw new Error('setEmailId: API Client Not Initialized');
    apiClient.defaults.headers.common['email-id'] = "" + emailId;
};
exports.setEmailId = setEmailId;
var post = function (path, data, headers, params, transformRequest) {
    var _a;
    console.log('path', path);
    if (apiClient == null)
        return Promise.reject(new Error('Post API Client Not Initialized'));
    var _b = (_a = data === null || data === void 0 ? void 0 : data.doValidation()) !== null && _a !== void 0 ? _a : [undefined, {}], errors = _b[0], validatedData = _b[1];
    if (errors != null)
        throw new Error(errors
            .failures()
            .map(function (f) { return f.message; })
            .join(', '));
    transformHeader(path);
    return apiClient
        .post(path, validatedData, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        transformRequest: transformRequest,
    })
        .then(Helpers_1.processResponse)
        .catch(function (err) {
        logger_1.default.error("Error POST " + path + ": " + JSON.stringify(err));
        throw Helpers_1.processError(err);
    });
};
exports.post = post;
var transformHeader = function (path) {
    if (apiClient == null)
        return 'api client not initialized';
    if (path.startsWith('/cafe') ||
        path.startsWith('cafe') ||
        path.startsWith('/cart') ||
        path.startsWith('cart') ||
        path.startsWith('/menu') ||
        path.startsWith('menu')) {
        apiClient.defaults.headers.common['authorization'] =
            apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER];
    }
    else if (path.startsWith('/payment') ||
        path.startsWith('payment') ||
        path.startsWith('/cms') ||
        path.startsWith('cms') ||
        path.startsWith('/order') ||
        path.startsWith('order') ||
        (path.indexOf('favouriteproduct') == -1 && path.indexOf('favourite') != -1)) {
        apiClient.defaults.headers.common['authorization'] =
            (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER]) +
                ', ' +
                (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER]);
    }
    else {
        apiClient.defaults.headers.common['authorization'] =
            apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER];
    }
    if (apiClient.defaults.headers.common['authorization'] == undefined ||
        _.isEmpty(apiClient.defaults.headers.common['authorization']) ||
        apiClient.defaults.headers.common['authorization'] == 'undefined, undefined') {
        delete apiClient.defaults.headers.common['authorization'];
    }
};
var put = function (path, data, headers, transformRequest) {
    var _a;
    if (apiClient == null)
        return Promise.reject(new Error('PUT API Client Not Initialized'));
    var _b = (_a = data === null || data === void 0 ? void 0 : data.doValidation()) !== null && _a !== void 0 ? _a : [undefined, {}], errors = _b[0], validatedData = _b[1];
    if (errors != null)
        throw new Error(errors
            .failures()
            .map(function (f) { return f.message; })
            .join(', '));
    transformHeader(path);
    return apiClient
        .put(path, validatedData, {
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        transformRequest: transformRequest,
    })
        .then(Helpers_1.processResponse)
        .catch(function (err) {
        logger_1.default.error("Error PUT " + path + ": " + err.message);
        throw Helpers_1.processError(err);
    });
};
exports.put = put;
var get = function (path, params, headers, noAuth, transformRequest, emptyData) {
    if (transformRequest === void 0) { transformRequest = function (data, headers) {
        return data;
    }; }
    if (apiClient == null) {
        return Promise.reject(new Error('GET API Client Not Initialized'));
    }
    if (path.includes('auth/user' || '?getFav=true')) {
        apiClient.defaults.headers.common['authorization'] =
            (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER]) +
                ', ' +
                (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER]);
    }
    else {
        transformHeader(path);
    }
    return apiClient
        .get(path, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        transformRequest: transformRequest,
        data: (emptyData !== null && emptyData !== void 0 ? emptyData : false) ? {} : null,
    })
        .then(Helpers_1.processResponse)
        .catch(function (err) {
        logger_1.default.error("Error GET " + path + ": " + err.message);
        throw Helpers_1.processError(err);
    });
};
exports.get = get;
var del = function (path, params, headers, noAuth, data) {
    if (apiClient == null) {
        return Promise.reject(new Error('DELETE API Client Not Initialized'));
    }
    if (path.includes('auth/user')) {
        apiClient.defaults.headers.common['authorization'] =
            (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[PUNCHH_AUTHERIZATION_HEADER]) +
                ', ' +
                (apiClient === null || apiClient === void 0 ? void 0 : apiClient.defaults.headers.common[OLO_AUTHERIZATION_HEADER]);
    }
    else {
        transformHeader(path);
    }
    return apiClient
        .delete(path, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        data: data ? data : null,
    })
        .then(Helpers_1.processResponse)
        .catch(function (err) {
        logger_1.default.error("Error DELETE " + path + ": " + err.message);
        throw Helpers_1.processError(err);
    });
};
exports.del = del;
