.menu-item-card {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin-top: 20px;
}
.menu-item-main-div {
    height: 140px;
    background: transparent;
    border-radius: 10px;
    width: inherit;
}
.menu-item-image-div {
    height: 100%;
    cursor: pointer;
}
.menu-item-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}
.menu-item-circle {
    width: 22px;
    height: 22px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0 0 8px;
    position: absolute;
}
.menu-item-circle-span {
    font-style: normal;
    font-weight: 600;
    font-size: 9.26316px;
    line-height: 100%;
    color: #222222;
}
.menu-item-name {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #222222;
    word-break: break-word;
    margin-top: 9px;
    margin-bottom: 0;
    line-height: 16px;
    width: 100%;
}
.menu-item-price {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #222222;
    margin-top: 8px;
}
@media(max-width: 960px) {
    .menu-item-card {
        width: 208px;
        max-width: 210px;
    }
}
@media(max-width: 470px) {
    .menu-item-card {
        width: 154px;
        max-width: 210px;
    }
    .menu-item-main-div {
        height: 110px;
    }
}
@media(max-width: 375px) {
    .menu-item-card {
        width: 145px;
        max-width: 210px;
    }
    .menu-item-main-div {
        height: 110px;
    }
}
.added {
    border: 2px solid #FFBA00;
    border-radius: 10px;
}
.product-added {
    position: relative;
    height: 30px;
    margin-top: -29px;
    background: #FFBA00;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.added-text {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #111111;
}
.bi-check-circle:before {
    content: "\F26B";
}