import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import classes from "./ProductImage.module.css";

const ProductImage = (props) => {
  let imageStyle = {};

  let imageUrl = "";
  const imagePath = useSelector((state: RootState) => state.catelog.imagePath);

  if (props?.product?.images && props?.product?.images?.length) {
    for (let prodImage of props?.product?.images) {
      if (prodImage?.groupname == "mobile-app-large") {
        imageUrl = imagePath + prodImage?.filename;
        break;
      }
    }
  }

  useEffect(() => {
    let backgroundColor = "#F2F3F5";
    if (imageUrl) {
      if (!!document.getElementById("product-image")) {
        if (props?.product?.metadata && props?.product?.metadata?.length) {
          for (let element of props?.product?.metadata) {
            if (element?.key == "detailpage-color") {
              backgroundColor = element?.images;
            }
          }
        }
        document.getElementById("product-image").style.backgroundColor =
          backgroundColor;
      }
    }
  }, []);

  return (
    <div>
      {imageUrl ? (
        <img
          id="product-image"
          className={
            props.product.category == "smoothies"
              ? classes["card-smoothies-container"]
              : classes["card-container"]
          }
          src={imageUrl}
        />
      ) : (
        <div className={classes["card"]} style={imageStyle}></div>
      )}
    </div>
  );
};

export default ProductImage;
