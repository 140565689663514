.card-container {
  width: 100%;
  height: 380px;
  border-radius: 20px 20px 0px 0px;
  object-fit: fill;

  object-position: 0 2em;
}

.card-smoothies-container {
  width: 100%;
  height: 380px;
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;

  object-position: top;
  padding-top: 5.8em;
}

@media (max-width: 630px) {
  .card-smoothies-container {
    padding-top: 5.5em;
  }

  .card-container {
    height: 300px;
  }
}
@media (max-width: 470px) {
  .card-smoothies-container {
    padding-top: 5.5em;
    height: 300px;
    width: 500px;
    margin-left: -3.5em;
  }

  .card-container {
    height: 320px;
  }
}
