import React from 'react';
import ReactDOM from 'react-dom';
import Card from '../UI/Card';
import classes from './DropDownModal.module.css';
import CloseButton from 'react-bootstrap/CloseButton'
import DropDownOptions from './DropDownOptions';

const Backdrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            <CloseButton className={classes['close-button']} onClick={props.onClose} />
            <DropDownOptions options={props.options} onOptionsClick={props.onOptionsClick} modalHeading={props.modalHeading} />
        </Card>
    );
};

const DropDownModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop />,
                document.getElementById('backdrop-modal')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay onClose={props.onClose} onNameSave={props.onNameSave} options={props.options}
                    onOptionsClick={props.onOptionsClick} modalHeading={props.modalHeading} />,
                document.getElementById('overlay-modal')
            )}
        </React.Fragment>
    );
};

export default DropDownModal;