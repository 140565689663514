.cart-main {
    background: #82CF00;
    box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
    position: fixed;
    bottom: 0.25%;
    margin: 0;
    margin-bottom: 10px;
    z-index: 300;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    cursor: pointer;
}
@media (max-width: 470px) {
    .cart-main {
        background: #82CF00;
        box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 30px;
        padding-left: 30px;
        position: fixed;
        bottom: 0.25%;
        margin: 0;
        margin-bottom: 10px;
        z-index: 300;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        max-width: 600px;
        cursor: pointer;
    }
}
.cart-text {
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #111111;
    margin-top: 18px;
    margin-bottom: 18px;
}