"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = exports.Categories = exports.Option = exports.Optiongroup = exports.ProductModifier = exports.Menu = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * Menu Model
 */
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for menu class
     * @return {ObjectSchema}
     */
    Menu.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Menu;
}(BaseModel_1.BaseModel));
exports.Menu = Menu;
/**
 * Categories  Model
 */
var Categories = /** @class */ (function (_super) {
    __extends(Categories, _super);
    function Categories() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for categories class
     * @return {ObjectSchema}
     */
    Categories.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Categories;
}(BaseModel_1.BaseModel));
exports.Categories = Categories;
/**
 * Product  Model
 */
var Product = /** @class */ (function (_super) {
    __extends(Product, _super);
    function Product() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Products class
     * @return {ObjectSchema}
     */
    Product.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Product;
}(BaseModel_1.BaseModel));
exports.Product = Product;
/**
 * Metadata Model
 */
var Metadata = /** @class */ (function (_super) {
    __extends(Metadata, _super);
    function Metadata() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Metadata class
     * @return {ObjectSchema}
     */
    Metadata.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Metadata;
}(BaseModel_1.BaseModel));
/**
 * AvailabilityProduct Model
 */
var AvailabilityProduct = /** @class */ (function (_super) {
    __extends(AvailabilityProduct, _super);
    function AvailabilityProduct() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for AvailabilityProduct class
     * @return {ObjectSchema}
     */
    AvailabilityProduct.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return AvailabilityProduct;
}(BaseModel_1.BaseModel));
/**
 * Product Modifiers Model
 */
var ProductModifier = /** @class */ (function (_super) {
    __extends(ProductModifier, _super);
    function ProductModifier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for ProductModifier class
     * @return {ObjectSchema}
     */
    ProductModifier.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return ProductModifier;
}(BaseModel_1.BaseModel));
exports.ProductModifier = ProductModifier;
/**
 * OptionsGroup Model
 */
var Optiongroup = /** @class */ (function (_super) {
    __extends(Optiongroup, _super);
    function Optiongroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Optiongroup class
     * @return {ObjectSchema}
     */
    Optiongroup.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Optiongroup;
}(BaseModel_1.BaseModel));
exports.Optiongroup = Optiongroup;
/**
 * Availability Model
 */
var Availability = /** @class */ (function (_super) {
    __extends(Availability, _super);
    function Availability() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Availability class
     * @return {ObjectSchema}
     */
    Availability.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Availability;
}(BaseModel_1.BaseModel));
/**
 * Metadatum Model
 */
var Metadatum = /** @class */ (function (_super) {
    __extends(Metadatum, _super);
    function Metadatum() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Metadatum class
     * @return {ObjectSchema}
     */
    Metadatum.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Metadatum;
}(BaseModel_1.BaseModel));
/**
 * Option Model
 */
var Option = /** @class */ (function (_super) {
    __extends(Option, _super);
    function Option() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Option class
     * @return {ObjectSchema}
     */
    Option.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Option;
}(BaseModel_1.BaseModel));
exports.Option = Option;
