
const ShoppingBag = () => {
    return(
        <svg style={{position:'absolute'}} xmlns="http://www.w3.org/2000/svg" width="16pt" height="19.2pt" viewBox="0 0 15 18" version="1.1">
          <g id="surface1">
            <path style={{stroke: "none", fillRule: "evenodd", fill: "rgb(6.666667%,6.666667%,6.666667%)", fillOpacity: "1"}} d="M 7.5 0 C 5.90625 0 4.617188 1.257812 4.617188 2.8125 L 4.617188 4.5 L 1.632812 4.5 C 1.328125 4.5 1.074219 4.734375 1.058594 5.03125 L 0.324219 17.40625 C 0.304688 17.726562 0.566406 18 0.898438 18 L 14.101562 18 C 14.433594 18 14.695312 17.726562 14.675781 17.40625 L 13.941406 5.03125 C 13.925781 4.734375 13.671875 4.5 13.367188 4.5 L 10.382812 4.5 L 10.382812 2.8125 C 10.382812 1.257812 9.09375 0 7.5 0 Z M 9.230469 5.625 L 9.230469 6.75 C 9.230469 7.0625 9.488281 7.3125 9.808594 7.3125 C 10.125 7.3125 10.382812 7.0625 10.382812 6.75 L 10.382812 5.625 L 12.820312 5.625 L 13.488281 16.875 L 1.511719 16.875 L 2.179688 5.625 L 4.617188 5.625 L 4.617188 6.75 C 4.617188 7.0625 4.875 7.3125 5.191406 7.3125 C 5.511719 7.3125 5.769531 7.0625 5.769531 6.75 L 5.769531 5.625 Z M 9.230469 4.5 L 9.230469 2.8125 C 9.230469 1.878906 8.457031 1.125 7.5 1.125 C 6.542969 1.125 5.769531 1.878906 5.769531 2.8125 L 5.769531 4.5 Z M 9.230469 4.5 "/>
          </g>
        </svg>
    )
}

export default ShoppingBag