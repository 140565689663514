import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import classes from './CartInvitation.module.css';

const CartInvitation = (props) => {
  const content: any = useSelector((state: RootState) => state.buttercms.content);
  return (
    <div className={classes['cart-main']}>
      <h2 className={classes['cart-heading']}>{content.cartHeader}</h2>
      <div>
        <h3 className={classes['cart-invited']}>{content.cartTitle}</h3>
        <p className={classes['cart-join']}>{content.cartWelcomeMsg1}</p>
      </div>
      <div>
        <hr className={classes['cart-hr']} />
      </div>
    </div>


  );
};

export default CartInvitation;