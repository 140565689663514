import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import CloseButton from "react-bootstrap/CloseButton";
import { useSelector } from "react-redux";

import Card from "../UI/Card";
import { RootState } from "../../store";
import NutritionDetails from "./NutritionDetails";
import classes from "./ProductDetailModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} style={{ backgroundColor: 'transparent' }} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
  const loader: any = useSelector((state: RootState) => state.loader.loader);

  const fetchProductData = useMemo(() => {
    const productData = props?.product;
    let indicators = [];
    if (productData.metadata && productData.metadata.length) {
      for (let element of props.product.metadata) {
        if (element.key == "indicator") {
          if (element.images) {
            let data = JSON.parse(element.images);
            if (data && data.length) {
              indicators = data;
            }
          }
        }
      }
    }
    return { indicators };
  }, [props]);

  useEffect(() => {
    let selectedParam = document.getElementById("overlay-modal");
    selectedParam.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Card className={classes.modal}>
      <div id="backdrop-modal"></div>
      <div
        id="overlay-modal"
        className={`${classes["overlay-modal"]} ${
          loader && loader.loaderVisible && classes["show-loader"]
        }`}
      ></div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <button
          className={classes["close-button"]}
          onClick={() => props?.onCloseNutritionInfoModal()}
        >+</button>
        <div className={classes["nutritionInfoContainer"]}>
          {Object.keys(props?.nutritionInfo)?.length > 0 ? (
            <NutritionDetails
              nutritionDetails={props?.nutritionInfo}
              indicatorData={fetchProductData?.indicators}
            />
          ) : (
            <div className={classes["nutrients-data-error-container"]}>
              <h3>Sorry!</h3>
              <span>
                The nutrient details for this product is currently unavailable.
              </span>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

const NutritionInfoModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}

      {ReactDOM.createPortal(
        <ModalOverlay
          onCloseNutritionInfoModal={() =>
            props?.onCloseNutritionDetailsModal()
          }
          product={props.product}
          onClose={props.onModalClose}
          nutritionInfo={props?.nutrientsData}
        />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default NutritionInfoModal;
