.card {
    min-width: 189px;
    max-width: 189px;
    border-radius: 10px;
}
.ingredient-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    margin-top: 15px;
}
.ingredient-image {
    width: 106px;
    height: 97px;
    mix-blend-mode: multiply;
    align-self: center;
}
.typeslide-name-layout{
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
    flex-direction: column;
}
.ingredient-option-main {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.ingredient-option {
    height: 100%;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #111111;
    margin: 0px 4px;
    flex:1;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.bi-shuffle,
.bi-trash,
.bi-plus {
    width: 10px;
    color: #111111;
    margin-right: 4px;
}
.bi-shuffle::before {
    content: '\F544';
}
.bi-trash::before {
    content: '\F5DE';
} 
.bi-plus::before {
    content: '\F64D';
}
.option-icons {
    margin-right: 4px;
}
.customization-div {
    width: 189px;
    background: #FFFFFF;
    box-shadow: -3.375px 3.375px 16.875px rgb(0 0 0 / 10%);
    border-radius: 10px;
    max-height: 285px;
    overflow-y: auto;
    position: absolute;
    z-index: 990;
}
.not-selected-div {
    min-height: 166px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.custom-selected {
    color: #82CF00;
}
.textcard-second-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
}
.option-icons-img{
    height: 12px;
    width: 12px;
}
.typeSlide-ifNewName{
    text-align: center;
    text-decoration: line-through;
}
.typeSlide-NewName{
    text-align: center;
}

@media(max-width: 960px) {
    .card {
        min-width: inherit;
        max-width: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 0;
    }
    .not-selected-div {
        min-height: 70px;
        max-height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }
    .ingredient-image {
        order: 1;
        width: 65px;
        height: auto;
        margin-left: 17px;
    }
    .ingredient-name {
        order: 2;
        margin-top: 0;
        margin-left: 20px;
        flex-grow: 1;
        text-align: left;
        font-size: 13px;
    }
    .textcard-second-name{
        text-align: left;
    }
    .ingredient-option-main {
        order: 3;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    .option-name {
        display: none;
    }
    .ingredient-option {
        font-size: 20px;
    }
    .customization-div {
        display: flex;
        flex-direction: column;
        align-self: center;
    }
    .option-icons-img{
        height: 24px;
        width: 24px;
    }
    .typeslide-name-layout{
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        flex-direction: row;
    }
    .typeSlide-ifNewName{
        text-align: start;
        text-decoration: line-through;
    }
    .typeSlide-NewName{
        text-align: end;
        margin-left: 5px;
    }
  }