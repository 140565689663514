import { useEffect, useState } from 'react';
import classes from './Categories.module.css';
import { Link } from "react-scroll";
const Categories = (props) => {
    const finalCategories = props.finalCategories;
    let [isScrolling, setIsScrolling] = useState(false);
    let selectedListClass = classes['category-div'] + ' ' + classes['category-selected'];
    let categoriesContent = '';
    let element = 100;
    var menuCategories = document.getElementById("menuCategories");
    const [offSetTop, setOffSetTop] = useState(0);
    const [flag, setFlag] = useState(false);

    if (finalCategories.length > 0) {
        categoriesContent = finalCategories.map((expense, index) => {
            if (document.getElementById(expense.category)){ 
                element = document.getElementById(expense.category).offsetTop; 
            }
            window.onscroll = function (e) {
                if(menuCategories.offsetTop !== 0){
                    setIsScrolling(false);
                }else{
                    setIsScrolling(true);
                }
            } 
            return ( expense.category == "featured" && isScrolling || (expense.category == "featured")?
                <Link key={index} activeClass={selectedListClass} spy to={expense.category} offset={-550 } onClickCapture={() =>{
                        props.onMainCategoryChangeHandler(expense.category);
                        setOffSetTop(0);
                        setFlag(true);
                    }
                    }>
                    <div  >
                        <button className={classes['categories-list']}>{expense.title}</button>
                    </div>
                </Link> 
                 : 
                 <div key={index}>
                    {
                        window.innerWidth <= 960 ? 
                        <Link activeClass={selectedListClass} spy to={expense.category} offset={-300} onClickCapture={() =>{
                            props.onMainCategoryChangeHandler(expense.category);
                            setOffSetTop(0);
                            setFlag(true);
                        }
                        }>
                            <div  >
                                <button className={classes['categories-list']}>{expense.title}</button>
                            </div>
                        </Link> 
                        : 
                        <Link activeClass={selectedListClass} spy to={expense.category} offset={-400} onClickCapture={() =>{
                            props.onMainCategoryChangeHandler(expense.category);
                            setOffSetTop(0);
                            setFlag(true);
                        }
                        }>
                            <div key={index} >
                                <button className={classes['categories-list']}>{expense.title}</button>
                            </div>
                        </Link> 
                    }
                 </div>
            )
        }
        )
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setOffSetTop(position);
        
    };
    const returnClassName = () =>{
        if(offSetTop < 270){
            return "FEATURED"
        }
        if(offSetTop > 270){
            if(offSetTop < 500){
                return "COMBOS"
            }
            if(offSetTop < 1510){
                return "SMOOTHIES"
            }
            if(offSetTop < 2775){
                return "ENTREES"
            }
            if(offSetTop < 3046){
                return "BREAKFAST"
            }
            if(offSetTop < 3300){
                return "KIDS"
            }
            if(offSetTop < 3790){
                return "SWEETS"
            }
        }
        else return '0'
    }

    const scrollable = document.getElementById("categories-main");

    window.onclick = function() {
        scrollable.scroll({
        left: 0,
        behavior: 'smooth'
    })
    setFlag(false);
    }


    useEffect(() =>{
        if(!flag){
            window.addEventListener('scroll', handleScroll, { passive: true });

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    },[flag])

    useEffect(()=>{
        var menuCategories = document.getElementById("menuCategories");
        if (typeof window !== "undefined") {
            var scrollOffsetData = menuCategories.offsetTop;
            window.addEventListener("scroll", () => {
              if (window.pageYOffset > scrollOffsetData) {
                menuCategories.classList.add(classes['sticky']);
              }else{
                menuCategories.classList.remove(classes['sticky']);
              }
            });
        }
    },[]);
    
    return (
        <div className={classes['menu-parent']}  id="menuCategories">
            <div id='categories-main' className={`${classes['categories-main']} ${classes[`${returnClassName()}`]}`}>
                {categoriesContent}
            </div>
        </div>

    );
};

export default Categories;
