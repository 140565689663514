import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cartActions } from "../../store/cart-slice";
import DropDownModal from "./DropDownModal";
import classes from "./OptionsDropdown.module.css";

const OptionsDropdown = (props) => {
  const optionGroup = props.optionGroup;
  const mandatory = optionGroup.mandatory;
  let minselects = optionGroup.minselects;
  const [selectedOptions, setSelectedOptions]: any = useState({});
  const [modalEnabled, setModalEnabled] = useState(false);
  const dispatch = useDispatch();

  const productInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );
  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
  );
  const isEdit = useSelector(
    (state: RootState) => state.cart.isEdit
  );

  if (!minselects) {
    minselects = 0;
    if (mandatory) {
      minselects = 1;
    }
  }
  useEffect(() => {
    let errorMessage = "";
    if (minselects) {
      if (!selectedOptions && minselects) {
        errorMessage = `Please make a selection for "${optionGroup.description}"`;
      } else {
        errorMessage = "";
      }
        dispatch(
          cartActions.minSelectsError({
            minSelectErrors: {
              description: optionGroup.description,
              errorMessage: errorMessage,
            },
          })
        );
    }
  }, [selectedOptions]);

  const onModalDisable = (event) => {
    setModalEnabled(false);
  };
  const onModalEnable = (event) => {
    setModalEnabled(true);
  };
  const onOptionsClick = (param) => (event) => {
    setSelectedOptions((prevState: any) => {
      if (Object.keys(prevState).length === 0) {
        dispatch(cartActions.addModifiers({ modifiers: param.id }));
        dispatch(cartActions.addItemCost({ cost: param.cost }));
      } else {
        dispatch(cartActions.removeItemCost({ cost: prevState.cost }));
        dispatch(cartActions.removeModifiers({ modifiers: prevState.id }));
        dispatch(cartActions.addModifiers({ modifiers: param.id }));
        dispatch(cartActions.addItemCost({ cost: param.cost }));
      }
      onModalDisable(event);
      return param;
    });
  };
  useEffect(()=>{
    if(productInCart.length && isEdit){
      productInCart.forEach((product) => {   
          product.choices.forEach((choice) => {
            optionGroup.options.forEach((option) =>{
              if(option.id === choice.optionid){
                setSelectedOptions(option);
                dispatch(cartActions.addModifiers({ modifiers: option.id }));
                dispatch(cartActions.addItemCost({ cost: option.cost }));
              }
            })   
          });
      });
    }
  }, [productInCart.length])

  const selected = () => {
    currentAddedModifiers.forEach((curr) => {
      if(curr === selectedOptions.id)
        return true;
      else return false;
    })
  }
  
  return (
    <div>
      {modalEnabled ? (
        <DropDownModal
          options={optionGroup.options}
          onClose={onModalDisable}
          onOptionsClick={onOptionsClick}
          modalHeading={optionGroup.description}
        />
      ) : null}
      <button className={classes["custom-dropdown"]} onClick={onModalEnable}>
        <p className={classes["custom-dropdown-button"]}>
          {(selectedOptions.hasOwnProperty("name") || selected())
            ? selectedOptions.name
            : optionGroup.description}
        </p>
        <i className="bi-caret-down-fill"></i>
      </button>
    </div>
  );
};

export default OptionsDropdown;
