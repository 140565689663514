"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.delFromOlo = exports.getFromOlo = exports.postToOlo = exports.initOloClient = exports.createOloClient = void 0;
var axios_1 = require("axios");
var Helpers_1 = require("../api/Helpers");
var Constants_1 = require("../Constants");
var logger_1 = require("./logger");
/**
 * @param {AxiosRequestConfig} config - Axios Configuration
 * @return {AxiosInstance}
 * Extend axios to add interceptors and error handling
 */
function createOloClient(config) {
    return axios_1.default.create(config);
}
exports.createOloClient = createOloClient;
var apiClient = null;
var initOloClient = function (baseURL, authorization, deviceId, userAgent) {
    apiClient = createOloClient({
        baseURL: baseURL,
    });
    apiClient.defaults.headers.common['authorization'] = authorization;
    apiClient.defaults.headers.common['device-id'] = deviceId;
    apiClient.defaults.headers.common['user-agent'] = userAgent;
};
exports.initOloClient = initOloClient;
var postToOlo = function (path, data, headers, params) {
    var _a;
    if (apiClient == null)
        return Promise.reject(new Error('Post API Client Not Initialized'));
    var _b = (_a = data === null || data === void 0 ? void 0 : data.doValidation()) !== null && _a !== void 0 ? _a : [undefined, {}], errors = _b[0], validatedData = _b[1];
    if (errors != null)
        throw new Error(errors
            .failures()
            .map(function (f) { return f.message; })
            .join(', '));
    return apiClient
        .post(path, validatedData, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
    })
        .then(Helpers_1.processOloResponse)
        .catch(function (err) {
        logger_1.default.error("Error POST " + path + ": " + JSON.stringify(err));
        throw Helpers_1.processOloError(err);
    });
};
exports.postToOlo = postToOlo;
var getFromOlo = function (path, params, headers, noAuth, transformRequest, emptyData) {
    if (transformRequest === void 0) { transformRequest = function (data, headers) {
        return data;
    }; }
    if (apiClient == null) {
        return Promise.reject(new Error('GET API Client Not Initialized'));
    }
    return apiClient
        .get(path, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        transformRequest: transformRequest,
        data: (emptyData !== null && emptyData !== void 0 ? emptyData : false) ? {} : null,
    })
        .then(Helpers_1.processOloResponse)
        .catch(function (err) {
        logger_1.default.error("Error GET " + path + ": " + err.message);
        throw Helpers_1.processOloError(err);
    });
};
exports.getFromOlo = getFromOlo;
var delFromOlo = function (path, params, headers, noAuth) {
    if (apiClient == null) {
        return Promise.reject(new Error('DELETE API Client Not Initialized'));
    }
    return apiClient
        .delete(path, {
        params: params,
        headers: __assign({ 'Content-Type': Constants_1.MIME_TYPE.APPLICATION_JSON }, headers),
        data: null,
    })
        .then(Helpers_1.processOloResponse)
        .catch(function (err) {
        logger_1.default.error("Error DELETE " + path + ": " + err.message);
        throw Helpers_1.processOloError(err);
    });
};
exports.delFromOlo = delFromOlo;
