import { Fragment } from "react";
import classes from "./TextInput.module.css";

const TextInput = (props) => {
  const inputClass = classes["custom-text"] + " " + props.className;

  return (
    <Fragment>
      <input
        type="search"
        className={inputClass}
        id="textInput"
        placeholder={props.placeholder}
        value={props.textValue}
        onChange={props.onTextChange}
        autoFocus
      ></input>
    </Fragment>
  );
};

export default TextInput;
