import { API } from "tsc-core";
import { AddProductToBasketRequest } from "tsc-core/dist/models/request/CartRequest";
import { cartActions } from "./cart-slice";
import { loaderActions } from "./loader-slice";
import {addToCartEvent} from '../components/helpers/MParticleHelper';
import { firebaseAnalytics } from "../components/helpers/FirebaseAnalytics";

export const addCartData = (productId,basketId2,cafeId2 , quantity, isUpsell = "no") => {

  return async (dispatch: any, getState) => {
    dispatch(
      cartActions.showError({
        errorMessage: "",
      })
    );
    const addData = async () => {
      dispatch(
        loaderActions.setLoader({
          loaderVisible: true,
        })
      );
      const name = localStorage.getItem("name");
      let options = "";
      const queryParams = new URLSearchParams(location.search);
      const basketId = queryParams.get("basket") ?? basketId2;
      const cafeId = queryParams.get("cafe") ?? cafeId2;
      const { modifiers } = getState().cart;
      
      options = modifiers.toString();
      try {
        
        const response: any = await API.CART.addToBasket(
          new AddProductToBasketRequest({
            productid: productId,
            quantity: quantity,
            options: options,
            recipient: name ? name : "",
          }),
          parseInt(cafeId),
          basketId
        );
        let currentProducts = response?.products;
        let curItem= currentProducts?.filter(item=>item?.productId === productId);
        addToCartEvent(curItem[curItem?.length-1], isUpsell);
        return response;
      } catch (error) {
        firebaseAnalytics(error, 'GO_ERR_ADD_TO_BASKET_API')
        return error;
      }
    };
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const basketId = queryParams.get("basket") ?? basketId2;
      
      try {
        const response = await API.CART.getBasket(basketId as any);
        return response;
      } catch (error) {
        firebaseAnalytics(error, 'GO_ERR_GET_BASKET_API')
        return error
      }
    };
    try {
      await addData();
      const cartData: any = await fetchData();
      
      if (cartData && cartData.hasOwnProperty("products")) {
        dispatch(
          cartActions.getProducts({
            productsInCart: cartData.products,
            errorMessage: "",
          })
        );
      } else {
        dispatch(
          cartActions.showError({
            errorMessage: cartData.text,
            productsInCart: [],
          })
        );
      }
      dispatch(
        loaderActions.setLoader({
          loaderVisible: false,
        })
      );
    } catch (error) {
      dispatch(
        cartActions.showError({
          errorMessage: error.text,
          productsInCart: [],
        })
      );
      dispatch(
        loaderActions.setLoader({
          loaderVisible: false,
        })
      );
    }
  };
};
