import React from 'react';
import ReactDOM from 'react-dom';
import Card from '../UI/Card';
import classes from './ErrorModal.module.css';
import CloseButton from 'react-bootstrap/CloseButton'

const Backdrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            <CloseButton className={classes['close-button']} onClick={props.onClose} />
            <div className={classes['error-main']}>
                <h1>Alert!</h1>
                <div className={classes['error-message']}>
                    <p>{props.message}</p>
                </div>
            </div>
        </Card>
    );
};

const ErrorModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop />,
                document.getElementById('backdrop-modal')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay onClose={props.onClose} message={props.message} />,
                document.getElementById('overlay-modal')
            )}
        </React.Fragment>
    );
};

export default ErrorModal;