.custom-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #747474;
    border: none;
    border-bottom: 2px solid #111111;
    width: 100%;
    padding: 0;
    height: 26px;
    margin-top: 20px;
    outline: none;
    background: transparent;
}