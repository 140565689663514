import classes from './Button.module.css';

const TSCButton = (props) => {
  return (
    <button type="button" className={`${classes['custom-button']} ${props.className ? props.className : ''}`}
      onClick={props.onButtonClick} disabled={props.disabled}>{props.children}</button>
  );
};

export default TSCButton;
