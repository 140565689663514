"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusCode = void 0;
/* eslint-disable */
var StatusCode;
(function (StatusCode) {
    StatusCode[StatusCode["MISSING_REQUIRED_PARAMS"] = 1001] = "MISSING_REQUIRED_PARAMS";
    StatusCode[StatusCode["INVALID_SIGNATURE"] = 1017] = "INVALID_SIGNATURE";
    StatusCode[StatusCode["INVALID_LANGUAGE"] = 1020] = "INVALID_LANGUAGE";
    StatusCode[StatusCode["USER_ALREADY_REGISTER"] = 1022] = "USER_ALREADY_REGISTER";
    StatusCode[StatusCode["MANDATORY_FIELDS_ARE_REQUIRED"] = 1025] = "MANDATORY_FIELDS_ARE_REQUIRED";
    StatusCode[StatusCode["SUCCESS"] = 2000] = "SUCCESS";
})(StatusCode || (StatusCode = {}));
exports.StatusCode = StatusCode;
/* eslint-enable */
