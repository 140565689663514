"use strict";
/**
 * @module tsc-core
 * Provide all core modules in one file for easier access
 */
// TODO
// Initialize process.env with values from .env in the enva {react-native, react, node} this package is imported
// import {isNodejs} from './utils';
Object.defineProperty(exports, "__esModule", { value: true });
exports.initOlo = exports.init = exports.EXCEPTION = exports.UTILS = exports.MODEL = exports.CONSTANTS = exports.API = void 0;
// if (isNodejs()) {
//   require('dotenv').config();
// }
var API = require("./api");
exports.API = API;
var CONSTANTS = require("./Constants");
exports.CONSTANTS = CONSTANTS;
var MODEL = require("./models");
exports.MODEL = MODEL;
var UTILS = require("./utils");
exports.UTILS = UTILS;
var EXCEPTION = require("./exceptions");
exports.EXCEPTION = EXCEPTION;
var init = function (params) {
    UTILS.initClient(params.baseURL, params.deviceId, params.clientType, params.userAgent, params.apiAuthKey);
};
exports.init = init;
var initOlo = function (params) {
    UTILS.initOloClient(params.baseURL, params.authorization, params.deviceId, params.userAgent);
};
exports.initOlo = initOlo;
