import { catelogActions } from './catalog-slice';
import { API } from 'tsc-core';
import { loaderActions } from './loader-slice';
import { firebaseAnalytics } from '../components/helpers/FirebaseAnalytics';

export const fetchCartData = (cafe) => {
    return async (dispatch: any) => {
        const fetchData = async () => {
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: true
                })
            );
            let data = {};
            const response = await API.MENU.menu(cafe, '');
            if (response && (response.statusCode == 2000 || response.status == "Success")) {
                data = await response;
            }
            return data;
        };

        try {
            const catelogData: any = await fetchData();
            dispatch(
                catelogActions.getAllCategories({
                    mainCategories: [],
                    categories: catelogData.data.categories || [],
                    imagePath: catelogData.data.imagepath,
                    totalCategories: 0,
                    catelog: {}
                })
            );
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        } catch (error) {
            firebaseAnalytics(error, 'GO_ERR_MENU_API')
            dispatch(
                catelogActions.showError({
                    errorMessage: 'We have a trouble connecting to the Store, Please try back later...'
                })
            );
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        }
    };
};