.custom-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 39px;
    height: 46px;
    border-radius: 40px;
    background: #82CF00;    
    margin: 0px 20px;
    border-color:transparent;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #111111;
}
.custom-button:disabled {
    background: #F2F3F5;
}