import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Categories from "../components/menu/Categories";
import MenuHeading from "../components/menu/MenuHeading";
import MenuList from "../components/menu/MenuList";
import Search from "../components/menu/Search";
import { useLocation } from "react-router-dom";
import { fetchCartData } from "../store/catalog-actions";
import SearchMenuList from "../components/menu/SearchMenuList";
import { RootState } from "../store";
import ProductDetailModal from "../components/productDetail/ProductDetailModal";
import Wrapper from "../components/helpers/Wrapper";
import { productDetailActions } from "../store/product-details-slice";
import { cartActions } from "../store/cart-slice";
import Logo from "../components/UI/Logo";
import Cart from "../components/cart/Cart";
import classes from "./Menu.module.css";
import { buttercmsData } from "../store/buttercms.actions";
import FinalSubmit from "../components/cart/FinalSubmit";
import NutritionInfoModal from "../components/productDetail/NutritionInfoModal";
import ShoppingBag from "../components/UI/ShoppingBag";
import { API } from "tsc-core";
import mParticle from '@mparticle/web-sdk';
import { checkoutEvent } from "../components/helpers/MParticleHelper";
import ExpirationMessage from './ExpirationMessage'
import { useViewport } from "../hooks/UseViewport";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { firebaseConfig, TRANSLATION_PATH } from "../constants/constants";
import { trace } from "firebase/performance";
import { firebaseAnalytics } from "../components/helpers/FirebaseAnalytics";
import { getAnalytics, logEvent } from "firebase/analytics";

const Menu = () => {
  const ref = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [basketId, setBasketId] = useState(queryParams.get("basket"));
  const basketIdGO = queryParams.get("basketid")
  const [cafe, setCafe] = useState(queryParams.get("cafe"));
  const groupOrderId = queryParams.get('grouporderid')
  const dispatch = useDispatch();
  const [addedProduct, setAddedProduct]: any = useState("");
  const mainCategories = useSelector(
    (state: RootState) => state.catelog.mainCategories
  );
  const errorMessage = useSelector(
    (state: RootState) => state.catelog.errorMessage
  );
  const catelog = useSelector((state: RootState) => state.catelog.catelog);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedProduct, setSelectedProduct]: any = useState("");
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(false);
  const [deadline, setDeadline]= useState('');
  const [date, setDate] = useState(new Date())
  const [errorFlag, setErrorFlag] = useState(false)
  const { width } = useViewport();
  const [counter, setCounter] = useState(0);
  const productsInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );
  const upsellItems = useSelector(
    (state: RootState) => state.cart.upsellItems
  );
  const content: any = useSelector(
    (state: RootState) => state.buttercms.content
  );
  const loader: any = useSelector((state: RootState) => state.loader.loader);
  const performance = window.performance;
  const [shoppingBagQuantity, setShoppingBagQuantity] = useState(0);

  const fetchGroupOrder = async () =>{
    try{
      if(groupOrderId){
        const response = API.ORDERS.groupOrderFetch(groupOrderId, basketIdGO);
        setCafe((await response).data.basket.vendorid.toString());
        setBasketId((await response).data.basket.id);
        setDeadline((await response).data.deadline);
        dispatch(cartActions.setBasketId((await response).data.basket.id));
        dispatch(cartActions.setCafeId((await response).data.basket.vendorid.toString()));
      }
    }catch(error){
      firebaseAnalytics(error, 'GO_ERR_ORDERS_API')
      if(error.statusCode === 1073){
        setExpired(true)
        setModalEnabled(true)
        setErrorFlag(true);
      }
      else return error
    }
  }

  useEffect(() =>{
    const app = initializeApp(firebaseConfig);
    const perf = getPerformance(app);
    fetchGroupOrder();
    performance.mark("measurementStart");
  },[])

  useEffect(()=>{
    setTimeout(() => setCounter(Math.round((counter + 0.1) * 1000) / 1000), 100);
  },[counter])

  useEffect(()=>{
    const time = deadline.slice(9, 14)
    const year = deadline.slice(0,4)
    const month = deadline.slice(4,6)
    const day = deadline.slice(6,8)
    const dateForState = new Date(parseInt(year),(parseInt(month)-1),parseInt(day),parseInt(time.slice(0,2)), parseInt(time.slice(3,5)))
    setDate(dateForState)
  },[deadline])

  const [isAddCartModalVisible, setAddCartModalVisible] = useState(false);
  const [isNutritionModalVisible, setNutritionModalVisible] = useState(false);
  const [nutritionInfoList, setNutritionInfoList] = useState({});
  const [finalCategories, setFinalCategories] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [expired, setExpired] = useState(false)

  const onCloseAddCartPopup = () => {
    setAddCartModalVisible(false);
  };

  const onShowNutritionPopup = () => {
    setNutritionModalVisible(true);
  };

  const onCloseNutritionPopup = () => {
    setNutritionModalVisible(false);
  };

  const onModalDisable = (event) => {
    setModalEnabled(false);
  };

  const onModalEnable = (event) => {
    setModalEnabled(true);
  };
  
  const getDay = (number) =>{
    const array = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return array[number]
  }

  const fetchCategories = async () => {
    try {
      const res = await API.CMS.getPage('menu?rawdatarequired=true');
      const result = res.contents[0].menulist.menucategory;
      setCmsData(result)
    } catch (error) {
      firebaseAnalytics(error, 'GO_ERR_CATEGORIES_API')
    }
  }
  
  useEffect(() => {
    fetchCategories();
    passTwoArray()
  }, [mainCategories]);

  function passTwoArray(){ 
    const result = mainCategories.map(val =>{
      const findElement = cmsData.find(cmsDataItem => cmsDataItem.action === val);
      return findElement ? {title:findElement.title, category:val} : ''
    });
    let result2 = [];
    result.forEach((res) => {
      if(Object.keys(res).length !== 0) {
        result2.push(res)
      }
    })
    setFinalCategories(result2);
  }

  const fetchNutritionData = async (currentProduct) => {
    if (Object.keys(currentProduct)?.length > 0) {
      try {
        const response = await API.CMS.getPage(
          currentProduct?.chainproductid + "?rawdatarequired=true"
        );
        setNutritionInfoList(response);
      } catch (error) {
        setNutritionInfoList({});
        firebaseAnalytics(error, 'GO_ERR_NUTRITION_API')
      }
    }
  };

  const handleScroll = () => {
    ref.current.scrollIntoView();
  };

  useEffect(() => {
    const localBasketId = localStorage.getItem("basketId");
    if (localBasketId != basketId && localBasketId != basketIdGO) {
      localStorage.setItem("basketId", basketId);
      localStorage.setItem("productsInCart", JSON.stringify([]));
      localStorage.setItem("name", "");

      dispatch(
        cartActions.getProducts({
          productsInCart: [],
          errorMessage: "",
        })
      );
    }else {
      const products = JSON.parse(localStorage.getItem("productsInCart"));
      dispatch(
        cartActions.getProducts({
          productsInCart: products,
          errorMessage: "",
        })
      );
    }
  }, [basketId])

  useEffect(() => {
    if(cafe !=null) 
      dispatch(fetchCartData(cafe)); 
  }, [cafe]);

  useEffect(() => {
    dispatch(buttercmsData());
  }, [dispatch]);

  useEffect(() => {
    if (!searchText && !selectedCategory && finalCategories.length) {
      setSelectedCategory(finalCategories[0].category);
    }
  }, [selectedCategory, finalCategories.length, searchText]);

  useEffect(() => {
    if (
      selectedProduct &&
      Object.keys(selectedProduct)?.length > 0 &&
      selectedProduct?.chainproductid
    ) {
      fetchNutritionData(selectedProduct);
    }
  }, [selectedProduct]);

  const mainCategoryChangeHandler = (param) => {
    setSearchText("");
    setSelectedCategory(param);
  };
  
  const onSearchTextChangeHandler = (event) => {
    if (selectedCategory) {
      setSelectedCategory("");
    }
    setSearchText(event.target.value);
  };

  const productSelectionChangeHandler = (param) => (event) => {
    setAddCartModalVisible(true);
    setSelectedProduct(param)
    setAddedProduct("");
  };

  const onModalCloseHandler = (event) => {
    setSelectedProduct(null);
    dispatch(
      productDetailActions.getAllOptions({
        optionGroups: [],
      })
    );
    dispatch(cartActions.resetCost({ cost: 0 }));
    dispatch(cartActions.resetMinSelectErrors({ minSelectErrors: 0 }));
    dispatch(cartActions.resetModifiers({}));
    dispatch(
      cartActions.showError({
        errorMessage: "",
      })
    );
  };

  const finalSubmitHandler = (totalCost) => {
    let IsUpsellArray = [];
    if(upsellItems.length > 0) {
      productsInCart.forEach((product, index) =>{
        upsellItems.forEach(upsellItem =>{
          if(product.productId !== upsellItem && IsUpsellArray[index] !== "yes"){
            IsUpsellArray[index] = 'no'
          }
          if(product.productId === upsellItem){
            IsUpsellArray[index] = 'yes'
            return
          }
        })
      })
    }
    else {
      productsInCart.forEach((product, index) => {
        IsUpsellArray[index] = 'no'
      })
    }
    checkoutEvent(totalCost, IsUpsellArray)
    setFinalSubmit((prevState) => {
      return true;
    });
    const app = initializeApp(firebaseConfig);
    const perf = getPerformance(app);
    performance.mark("measurementStop");
    performance.measure("Session", "measurementStart", "measurementStop");
  };

  useEffect(() => {
    if (productsInCart && productsInCart.length) {
      if (selectedProduct) {
        setAddedProduct((prevState) => selectedProduct.id);
        onModalCloseHandler(null);
      }
    }
  }, [productsInCart]);

  useEffect(() =>{
    const thisDate = new Date();
    if(date < thisDate){
      setExpired(true)
      setModalEnabled(true)
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      logEvent(analytics, "GO_ERR_ORDER_EXPIRED", {
        description: "GO Order has expired" ?? "NA",
        groupOrderId: groupOrderId
      });
    }
    else{
      setExpired(false);
      setModalEnabled(true)
    }
  },[date])

  useEffect(() => {
    let tempQuantity = 0;
    productsInCart.forEach((product) => {
      tempQuantity += product.quantity
    })
    setShoppingBagQuantity(tempQuantity) 
  }, [productsInCart])

  useEffect(()=>{
    document.onvisibilitychange = function() {
      if (document.visibilityState === 'hidden') {
        performance.mark("measurementStop");
        
      }else{
        performance.mark("measurementStart");
      }
    };
  }, [document.visibilityState])

  const classesCol = `col-lg-8 col-md-8 ${classes["final-submit"]}`;  

  return (
    <div>
      <div id="backdrop-modall"></div>
      <div id="overlay-modall"></div>
    <Wrapper>
      <main className={!finalSubmit ? classes.main : ""}>
        {errorMessage ? (
          <div className={classes["error-div"]}>
            <Logo image={content.logo} />
            <p className={classes["error-message"]}>{errorMessage}</p>
          </div>
        ) : (
          <Fragment>
            {mainCategories && mainCategories.length ? (
              <div className="row">
                <div
                  className={!finalSubmit ? "col-lg-8 col-md-8" : classesCol}
                >
                  {!finalSubmit ? (
                    <div>
                      <Logo image={content.logo} />
                      <MenuHeading />
                      {selectedProduct && isAddCartModalVisible ? (
                        <ProductDetailModal
                          onCloseAddCartModal={onCloseAddCartPopup}
                          onShowNutritionModal={onShowNutritionPopup}
                          product={selectedProduct}
                          onModalClose={onModalCloseHandler}
                        />
                      ) : null}
                      {isNutritionModalVisible ? (
                        <NutritionInfoModal
                          product={selectedProduct}
                          onCloseNutritionDetailsModal={onCloseNutritionPopup}
                          onModalClose={onModalCloseHandler}
                          nutrientsData={nutritionInfoList}
                        />
                      ) : null}
                      <Search
                        onSearchChangeHandler={onSearchTextChangeHandler}
                        searchTextValue={searchText}
                      />
                      <Categories
                        finalCategories={finalCategories}
                        selectedCategory={selectedCategory}
                        onMainCategoryChangeHandler={mainCategoryChangeHandler}
                      />
                      {searchText ? (
                        <>
                          <SearchMenuList
                            catelog={catelog}
                            searchText={searchText}
                            onProductSelect={productSelectionChangeHandler}
                            addedProduct={addedProduct}
                          />
                          <div className="shoppingBag"
                            onClick={handleScroll}>
                            <ShoppingBag/>
                            <span style={{fontSize: "12px", fontWeight: "bold", marginTop: "8px"}}> {shoppingBagQuantity} </span>
                          </div>
                        </>

                      ) : (
                        <>
                          <div className="shoppingBag"
                            onClick={productsInCart.length !==0 ? handleScroll : null}>
                            <ShoppingBag/>
                            <span style={{fontSize: "12px", fontWeight: "bold", marginTop: "8px"}}> {shoppingBagQuantity} </span>
                          </div>
                          {Object.values(mainCategories).map((value, index) => {
                            return (
                              <div id={value} key={index}>
                                <MenuList
                                  selectedCategory={value}
                                  catelog={catelog}
                                  onProductSelect={
                                    productSelectionChangeHandler
                                  }
                                  addedProduct={addedProduct}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  ) : (
                    <FinalSubmit />
                  )}
                </div>
                {/* <div className="col-lg-4 col-md-4"> */}
                <div className={"col-lg-4 col-md-4 " + classes["cart-div"]} ref={ref}>
                  <Cart finalSubmit={finalSubmit} finalSubmitHandler={finalSubmitHandler} />
                </div>
                {!finalSubmit && width > 768 ?
                  <div style={{height:'400px'}}></div> : null}
              </div>
            ) : null}
          </Fragment>
        )}
      </main>
    </Wrapper>
    {modalEnabled && ((mainCategories && mainCategories.length) || errorFlag) && !loader.loaderVisible && !queryParams.get("basket") && !queryParams.get("cafe") ? (
        <ExpirationMessage
          onClose={onModalDisable}
          day = {getDay(date.getDay())}
          time = {date.toLocaleString('en-US', { hour: 'numeric',minute:'numeric', hour12: true })}
          expired={expired}
          onOutsideClick = {onModalDisable}
          errorFlag={errorFlag}
        />
      ) : null}
    </div>
  );
};

export default Menu;
