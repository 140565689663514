import { Fragment, useMemo } from "react";
import classes from "./ProductDescription.module.css";
import ProductDetailIndicator from "./ProductDetailIndicator";

const NutritionDetails = (props) => {
  const getNutritionDetails = useMemo(() => { 
    const pdtName =
      props?.nutritionDetails?.contents[0]?.nutritiondetails?.productname || "";
    const nutrientsList =
      props?.nutritionDetails?.contents[0]?.nutritiondetails?.itemswithvalue ||
      [];
    const indicatorList = props?.indicatorData?.length
      ? props?.indicatorData
      : [];
    const nutrientsMoreinfo = {
      name:
        props?.nutritionDetails?.contents[0]?.nutritiondetails?.cta[0]?.title ||
        "",
      action:
        props?.nutritionDetails?.contents[0]?.nutritiondetails?.cta[0]
          ?.action || "",
    };
    const nutrientsAllergen_notice = {
      name: props?.nutritionDetails?.contents[0]?.nutritiondetails?.title || "",
      action:props?.nutritionDetails?.contents[0]?.nutritiondetails?.description || "",
    };
    const allergen_title = props?.nutritionDetails?.contents[0]?.nutritiondetails?.allergenTitle || "";
    const disclaimer = props?.nutritionDetails?.contents[0]?.nutritiondetails?.disclaimer || "";

    return {
      pdtName,
      nutrientsList,
      indicatorList,
      nutrientsMoreinfo,
      nutrientsAllergen_notice,
      allergen_title,
      disclaimer
    };
  }, [props]);

  const getParagraph = () => {    
    let paragraph = getNutritionDetails?.nutrientsAllergen_notice?.action;
    let paragraphList;
    if(!!paragraph){
        paragraphList = paragraph.split('\n\n')
    }
    return paragraphList;
  }

  const getList = () => {
    let paragraph = getNutritionDetails?.disclaimer;
    let paragraphList;
    if(!!paragraph){
        paragraphList = paragraph.split('\n');
    }
   return paragraphList;
  }

  return (
    <Fragment>
      <div>
        <div className={classes["nutrition-info-modal-header"]}>
          <h2 className={classes["product-name"]}>
            {getNutritionDetails?.pdtName}
          </h2>
          <div className={classes["indicator-div-nutrition"]}>
            {getNutritionDetails?.indicatorList &&
            getNutritionDetails?.indicatorList.length
              ? getNutritionDetails?.indicatorList.map((indicator) => {
                  return (
                    <ProductDetailIndicator
                      key={indicator.key}
                      indicator={indicator}
                    />
                  );
                })
              : null}
          </div>
          <h4 className={classes["product-weight"]}>NUTRITION INFO</h4>
        </div>
        <div className={classes["nutrition-info-modal-container"]}>
          {getNutritionDetails?.nutrientsList?.length > 0
            ? getNutritionDetails?.nutrientsList?.map((item, index) => (
                <NutrientDataListItem
                  key={index}
                  nutrientTitle={item?.item}
                  nutrientValue={item?.value}
                />
              ))
            : null}
        </div>
        {getNutritionDetails?.nutrientsMoreinfo?.name !== "" ? (
          <div className={classes["nutrition-main-more"]}>
            <a
              href={getNutritionDetails?.nutrientsMoreinfo?.action}
              target="_blank"
              className={classes["nutrition-anchor"]}
            >
              <div className={classes["nutrition-text"]}>
                {getNutritionDetails?.nutrientsMoreinfo?.name}
              </div>
            </a>
          </div>
        ) : null}
        {
          getNutritionDetails?.allergen_title ? 
          <p className={classes["moreInfo-text"]}>{getNutritionDetails?.allergen_title}</p> 
          : null
        }
        {getNutritionDetails?.nutrientsAllergen_notice?.name !== "" ? (
          <div className={classes["nutrition-allergeon"]}>
            <h4 className={classes["nutrition-allergeon-heading"]}>
              {getNutritionDetails?.nutrientsAllergen_notice?.name}
            </h4>
            {
              getList()?.length > 0 ? getList()?.map((ele, i)=>{
               return <Fragment key={i}><p >{ele}</p></Fragment>
              }): null
            }
            <br/>
            {
              getParagraph()?.length > 0 ? getParagraph()?.map((ele, i)=>{
               return <Fragment key={i}><p >{ele}</p> <br/></Fragment>
              }): null
            }
            
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

const NutrientDataListItem = (props) => {
  return (
    <div className={classes["nutrition-list"]}>
      <span className={classes["nutrient-item-text"]}>
        {props?.nutrientTitle}
      </span>
      <span className={classes["nutrient-item-value"]}>
        {props?.nutrientValue}
      </span>
    </div>
  );
};

export default NutritionDetails;
