"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = exports.GetUserAddress = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * GetUserAddress Model
 */
var GetUserAddress = /** @class */ (function (_super) {
    __extends(GetUserAddress, _super);
    function GetUserAddress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for GetUserAddress class
     * @return {ObjectSchema}
     */
    GetUserAddress.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return GetUserAddress;
}(BaseModel_1.BaseModel));
exports.GetUserAddress = GetUserAddress;
/**
 * UserAddress Model
 */
var Address = /** @class */ (function (_super) {
    __extends(Address, _super);
    function Address() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for UserAddress class
     * @return {ObjectSchema}
     */
    Address.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Address;
}(BaseModel_1.BaseModel));
exports.Address = Address;
