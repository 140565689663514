.main-div {
    display: flex;
    flex-direction: column;
}
.drop-down-heading {
    align-self: center;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #111111;
    margin: 20px 40px 0 40px;
}
.drop-down-hr {
    margin: 15px 0 0 0;
    border: 1px solid #E6E7EB;
}
.drop-down-name {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    color: #111111;
    margin-top: 15px;
    cursor: pointer;
}
.drop-down-div {
    margin-right: 40px;
    margin-left: 40px;
}
.drop-down-main {
    width: 100%;
    text-align: center;
}
.drop-down-calories {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #5C5C5C;
    margin-top: 4px;
}
@media(max-width: 470px) {
    .drop-down-div {
        margin-right: 20px;
        margin-left: 20px;
    }
    .drop-down-heading {
        margin: 20px 20px 0 40px;
    }
}