import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart-slice';
import CustomizationModal from './CustomizationModal';
import classes from './TypeSlide.module.css';
let deleteicon = require("../../assets/delete.png");
import { faShuffle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootState } from '../../store';

const TypeSlide = (props) => {
  const option = props.option;
  const [selected, setSelected] = useState(false);
  const [customizeOptions, setCustomizeOptions] = useState([]);
  const [removeOption, setRemoveOption]: any = useState({});
  const [removeEnabled, setRemoveEnabled] = useState(false);
  const [customizeEnabled, setCustomizeEnabled] = useState(false);
  const [customizedSelection, setCustomizedSelection] = useState('');
  const [customized_selection, setCustomized_selection] = useState(null);
  const [secondaryText, setSecondaryText] = useState('');
  const dispatch = useDispatch();
  let cardStyle = { backgroundColor: '#E5F2C2' };
  let image = require('../../assets/noimage.png');
  const [flag, setFlag] = useState(false)
  const productInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );
  const isEdit = useSelector(
    (state: RootState) => state.cart.isEdit
  );
  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
  );

  useEffect(() => {
    if (option.modifiers && option.modifiers[0] && option.modifiers[0].options && option.modifiers[0].options.length) {
      setRemoveOption(prevState => {
        return option.modifiers[0].options[0];
      });
    }
  }, []);

  useEffect(() => {
    if (option.metadata && option.metadata.length) {
      for (let element of option.metadata) {
        if (element.key == 'text') {
          if (element.value) {
            setSecondaryText(element.value);
          }
        }
      }
    }
  }, [option]);

  useEffect(() => {
    if (props.resetClicked) {
      if (removeEnabled) {
        onRemoveHandler('');
      }
      onCustomizeSelection('');
      setCustomizeOptions([])
      setRemoveEnabled(false)
      setCustomizeEnabled(false)
      setCustomized_selection(null)
      setCustomizedSelection('')
      setFlag(false)
    }
  }, [props.resetClicked]);

  if (option && option.metadata && option.metadata.length) {
    for (let metaDt of option.metadata) {
      if (metaDt.key == 'image') {
        image = metaDt.value;
      } else if (metaDt.key == 'colorcode') {
        cardStyle.backgroundColor = metaDt.value;
      }
    }
  }
  const onCustomizeClickHandler = event => {
    setSelected((prevState) => {
      if (prevState) {
        setCustomizeOptions((prevCustomizations) => {
          return []
        })
        return !prevState
      } else {
        setCustomizeOptions((prevCustomizations) => {
          if (option.modifiers && option.modifiers.length) {
            if (option.modifiers[0] && option.modifiers[0].options && option.modifiers[0].options.length) {
              let data = option.modifiers[0].options;
              return data.slice(1);
            } else {
              return [];
            }
          } else {
            return [];
          }
        })
        return !prevState;
      }
    })
  }
  useEffect(() =>{
    if(isEdit && !flag){
      let preSelectedOption = currentAddedModifiers.some((selectedOption) => selectedOption === option.id);
      setRemoveEnabled(preSelectedOption)
    }
      
  },[])

  useEffect(() =>{
    if(props.customizedSelections.length && props.isEdit && !flag){
      let preSelectedOption = props.customizedSelections.some((selectedOption) => selectedOption === option.id);
      setRemoveEnabled(preSelectedOption)
    }
  },[props.customizedSelections])
  
  const onCustomizeSelection = param => {

    setCustomizedSelection((prevState) => {
      setTimeout(() => {
        props.removeCustomization(prevState);
        props.addCustomization(param);
      }, 0);
      if (param) {
        if (prevState) {
          dispatch(
            cartActions.removeGroupModifiers({ child: prevState, parent: option.id })
          );
          dispatch(
            cartActions.addGroupModifiers({ child: param, parent: option.id })
          );
        } else {
          dispatch(
            cartActions.addGroupModifiers({ child: param, parent: option.id })
          );
        }
      } else {
        if (prevState) {
          dispatch(
            cartActions.removeGroupModifiers({ child: prevState, parent: option.id })

          );
        }
      }
      return param;
    })
  }
  const onRemoveHandler = event => {
    setFlag(!flag)
    if (removeEnabled) {
      setCustomizeEnabled(false)
      setRemoveEnabled(false)
      setTimeout(() => {
        props.removeCustomization(removeOption.id);
      }, 0);
      dispatch(
        cartActions.removeGroupModifiers({ child: removeOption.id, parent: option.id })
      );
    } else {
      setCustomizeEnabled(true)
      setRemoveEnabled(true)
      setTimeout(() => {
        props.addCustomization(removeOption.id);
      }, 0);
      dispatch(
        cartActions.addGroupModifiers({ child: removeOption.id, parent: option.id })
      );
    }
    
  }
  const setSelectedHandler = event => {
    setSelected(false);
    setRemoveEnabled(false);
  }

  useEffect(() => {
    if (isEdit) {
      productInCart.forEach((product) => {
        product.choices.forEach((choice) => {
          if (option?.modifiers?.length > 0)
            option?.modifiers[0]?.options?.forEach((option2, index) => {
              if (option2.id === choice.optionid) {
                if (option2.name !== "Just Remove") {
                  setCustomizedSelection(option2.id);
                  setCustomized_selection(option2);
                  if(option2.name.includes('Sub ')) {
                    setCustomizeEnabled(false)
                  }
                }
                else setCustomizeEnabled(true)
              }
            });
        });
      });
    }
  }, [option]);

  useEffect(() => {
    if(!props.resetClicked) {
      if(removeEnabled || customizedSelection) {
        props.setResetButton(true)
      }
    }
  }, [props.customizedSelections, removeEnabled, customizedSelection])

  const closeButtonHandler = () => {
    setSelected(false)
  }

  return (
    <Fragment>
      {selected ? <CustomizationModal closeButtonHandler={closeButtonHandler} customizeOptions={customizeOptions} onCustomization={onCustomizeSelection} selectedCustomization={customizedSelection}
        onSetSelected={setSelectedHandler} setCustomized_selection={setCustomized_selection} /> : null}
      <section style={cardStyle} className={classes['card']} tabIndex={0} aria-label={option.name}>
        <div className={classes['not-selected-div']}>
          <div className={classes['ingredient-name']}>
            {
              ((removeEnabled && !customizedSelection)) ?  //
                <div>
                  {
                    option.name.includes('No ') ?
                      <div> {option.name} </div>
                      : <div> {option.name} </div>
                  }
                </div>
                :
                <div>
                  {typeof customizedSelection === 'string' ?
                    <div>
                      {
                        option.name.includes('No ') ?
                          <div> {option.name.split('No ')[1]} </div>
                          : <div> {option.name} </div>
                      }
                    </div>
                    :
                    <div className={classes['typeslide-name-layout']}>
                      <div>
                        {
                          option.name.includes('No ') ?
                            <div className={classes['typeSlide-ifNewName']}>{option.name.split('No ')[1]} </div>
                            : <div className={classes['typeSlide-ifNewName']}> {option.name} </div>
                        }
                      </div>
                      <div className={classes['typeSlide-NewName']}>
                        {customized_selection.name}
                      </div>
                    </div>
                  }
                </div>
            }
            <div className={classes['textcard-second-name']}>{secondaryText}</div>
          </div>
          <img className={classes['ingredient-image']} src={image} alt={option.name} />
          <div className={classes['ingredient-option-main']}>
            {!customizeEnabled && option.hasOwnProperty('modifiers') && option.modifiers && option.modifiers.length && option.modifiers[0] && option.modifiers[0].options && option.modifiers[0].options.length ?
              <button className={classes['ingredient-option']} onClick={onCustomizeClickHandler}>
                <span className={classes['option-icons']}>
                  {
                    customizedSelection ? <FontAwesomeIcon icon={faShuffle} className={classes['custom-selected']} />
                    : <FontAwesomeIcon icon={faShuffle}/>
                  }
                </span>
                <span className={classes['option-name']}>Customize</span>
              </button> : null
              }

            {!removeEnabled && !customizedSelection ?
              <button className={classes['ingredient-option']} onClick={onRemoveHandler}>
                <span className={classes['option-icons']}>
                  <img src={deleteicon} alt="Remove" className={classes['option-icons-img']}/>
                </span>
                <span className={classes['option-name']}>Remove</span></button> : null
                }
            
            {removeEnabled && !customizedSelection ?
              <button className={classes['ingredient-option']} onClick={onRemoveHandler}>
                <span className={classes['option-icons']}><i className={classes['bi-plus']}></i></span>
                <span className={classes['option-name']}>Add</span></button> : null
                }
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TypeSlide;