"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseRedeemable = exports.BankingRule = exports.ArrivalInformationPrompt = exports.GamingLevel = exports.Game = exports.Redeemable = exports.MembershipLevel = exports.Pickup = exports.StoreTime = exports.LocationGroup = exports.Location = exports.GiftCardDesign = exports.CurrentPasswordPolicy = exports.MetaData = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * MetaData model
 */
var MetaData = /** @class */ (function (_super) {
    __extends(MetaData, _super);
    function MetaData() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for MetaData class
     * @return {ObjectSchema}
     */
    MetaData.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return MetaData;
}(BaseModel_1.BaseModel));
exports.MetaData = MetaData;
/**
 * CurrentPasswordPolicy
 */
var CurrentPasswordPolicy = /** @class */ (function (_super) {
    __extends(CurrentPasswordPolicy, _super);
    function CurrentPasswordPolicy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for CurrentPasswordPolicy class
     * @return {ObjectSchema}
     */
    CurrentPasswordPolicy.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CurrentPasswordPolicy;
}(BaseModel_1.BaseModel));
exports.CurrentPasswordPolicy = CurrentPasswordPolicy;
/**
 * GiftCardDesign
 */
var GiftCardDesign = /** @class */ (function (_super) {
    __extends(GiftCardDesign, _super);
    function GiftCardDesign() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for GiftCardDesign class
     * @return {ObjectSchema}
     */
    GiftCardDesign.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return GiftCardDesign;
}(BaseModel_1.BaseModel));
exports.GiftCardDesign = GiftCardDesign;
/**
 * Location
 */
var Location = /** @class */ (function (_super) {
    __extends(Location, _super);
    function Location() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Location class
     * @return {ObjectSchema}
     */
    Location.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Location;
}(BaseModel_1.BaseModel));
exports.Location = Location;
/**
 * LocationGroup
 */
var LocationGroup = /** @class */ (function (_super) {
    __extends(LocationGroup, _super);
    function LocationGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for LocationGroup class
     * @return {ObjectSchema}
     */
    LocationGroup.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return LocationGroup;
}(BaseModel_1.BaseModel));
exports.LocationGroup = LocationGroup;
/**
 * StoreTime
 */
var StoreTime = /** @class */ (function (_super) {
    __extends(StoreTime, _super);
    function StoreTime() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for StoreTime class
     * @return {ObjectSchema}
     */
    StoreTime.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return StoreTime;
}(BaseModel_1.BaseModel));
exports.StoreTime = StoreTime;
/**
 * Pickup
 */
var Pickup = /** @class */ (function (_super) {
    __extends(Pickup, _super);
    function Pickup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Pickup class
     * @return {ObjectSchema}
     */
    Pickup.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Pickup;
}(BaseModel_1.BaseModel));
exports.Pickup = Pickup;
/**
 * MembershipLevel
 */
var MembershipLevel = /** @class */ (function (_super) {
    __extends(MembershipLevel, _super);
    function MembershipLevel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for MembershipLevel class
     * @return {ObjectSchema}
     */
    MembershipLevel.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return MembershipLevel;
}(BaseModel_1.BaseModel));
exports.MembershipLevel = MembershipLevel;
/**
 * Redeemable
 */
var Redeemable = /** @class */ (function (_super) {
    __extends(Redeemable, _super);
    function Redeemable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Redeemable class
     * @return {ObjectSchema}
     */
    Redeemable.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Redeemable;
}(BaseModel_1.BaseModel));
exports.Redeemable = Redeemable;
/**
 * Game
 */
var Game = /** @class */ (function (_super) {
    __extends(Game, _super);
    function Game() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for Game class
     * @return {ObjectSchema}
     */
    Game.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Game;
}(BaseModel_1.BaseModel));
exports.Game = Game;
/**
 * GamingLevel
 */
var GamingLevel = /** @class */ (function (_super) {
    __extends(GamingLevel, _super);
    function GamingLevel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for GamingLevel class
     * @return {ObjectSchema}
     */
    GamingLevel.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return GamingLevel;
}(BaseModel_1.BaseModel));
exports.GamingLevel = GamingLevel;
/**
 * ArrivalInformationPrompt
 */
var ArrivalInformationPrompt = /** @class */ (function (_super) {
    __extends(ArrivalInformationPrompt, _super);
    function ArrivalInformationPrompt() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ArrivalInformationPrompt;
}(BaseModel_1.BaseModel));
exports.ArrivalInformationPrompt = ArrivalInformationPrompt;
/**
 * BankingRule
 */
var BankingRule = /** @class */ (function (_super) {
    __extends(BankingRule, _super);
    function BankingRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for BankingRule class
     * @return {ObjectSchema}
     */
    BankingRule.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return BankingRule;
}(BaseModel_1.BaseModel));
exports.BankingRule = BankingRule;
/**
 * BaseRedeemable
 */
var BaseRedeemable = /** @class */ (function (_super) {
    __extends(BaseRedeemable, _super);
    function BaseRedeemable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validation schema for BaseRedeemable class
     * @return {ObjectSchema}
     */
    BaseRedeemable.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return BaseRedeemable;
}(BaseModel_1.BaseModel));
exports.BaseRedeemable = BaseRedeemable;
