.recommendation-row-container {
    margin-top: 15px;
}
.title {
    font-weight: 700;
    margin-bottom: 10px;
}
.items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
@media(max-width: 768px) {
    .items {
        margin-right: 0px;
        flex-wrap: nowrap;
    }
}