.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal::-webkit-scrollbar {
  display: none;
}
.modal {
  position: fixed;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  margin: 0 auto;
  max-width: 640px;
  min-height: 90vh;
  height: 100%;
  overflow-y: scroll;
}

.header {
  background: #4f005f;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  position: absolute;
  padding: 0 26.36px;
  width: 20px;
  height: auto;
  box-sizing: border-box;
  font-size: 48px;
  display: inline-block;
  transform: rotate(45deg);
  margin-top: -18px;
  color: black;
}
@media (max-width: 470px) {
  .modal {
    top: 1vh;
  }
}
.overlay-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.show-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
}

.nutritionInfoContainer {
  margin-top: 5em;
}

.modal_dietary {
  position: relative;
  top: 10vh;
  width: 97%;
  z-index: 100;
  overflow: hidden;
  margin: 0 auto;
  max-width: 640px;
  min-height: 51vh;
  background-color: white;
  border-radius: 20px;
}
.dietary-indicator-popup {
    margin: 40px 30px 80px 30px;
    text-align: center;
}
@media (max-width: 470px) {
    .dietary-indicator-popup {
        margin: 10px 10px 40px 10px;
        text-align: center;
    }
}
.nutrition-main {
    background: #a6e627;
    box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
    border-radius: 50px;
    width: 90%;
    margin: 0;
    max-width: 600px;
    cursor: pointer;
}
.nutrition-text {
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #111111;
    margin-top: 18px;
    margin-bottom: 18px;
    text-align: center;
}

.nutrients-data-error-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10em auto;
}

.nutrients-data-error-container span{
  text-align: center;
}

@media (max-width: 390px) {
  .nutrients-data-error-container{
    margin: 10em 1em;
  }
}

