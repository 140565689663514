"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitOrder = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * SubmitOrder
 */
var SubmitOrder = /** @class */ (function (_super) {
    __extends(SubmitOrder, _super);
    /**
     * Constructor for SubmitOrder
     * @param {SubmitOrder} obj
     */
    function SubmitOrder(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for SubmitOrder
     * @return {ObjectSchema}
     */
    SubmitOrder.prototype.schema = function () {
        return validations_1.object({
            billingmethod: validations_1.string(),
            cardnumber: validations_1.optional(validations_1.string()),
            expiryyear: validations_1.optional(validations_1.string()),
            expirymonth: validations_1.optional(validations_1.string()),
            cvv: validations_1.optional(validations_1.number()),
            usertype: validations_1.string(),
            firstname: validations_1.optional(validations_1.string()),
            lastname: validations_1.optional(validations_1.string()),
            emailaddress: validations_1.optional(validations_1.string()),
            zip: validations_1.optional(validations_1.number()),
            billingaccountid: validations_1.optional(validations_1.string()),
            saveonfile: validations_1.optional(validations_1.boolean()),
            authtoken: validations_1.optional(validations_1.string()),
        });
    };
    return SubmitOrder;
}(BaseModel_1.BaseModel));
exports.SubmitOrder = SubmitOrder;
