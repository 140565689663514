import classes from './MenuListHeading.module.css';

const MenuListHeading = (props) => {
  return (
    <div className={classes['list-heading-main']}>
      <h3 className={classes['list-heading-name']}>{props.menuHeading}</h3>
      <p className={classes['list-heading-item-count']}>{props.count}</p>
    </div>
  );
};

export default MenuListHeading;