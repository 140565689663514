.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
}

.modal {
  top: 26vh;
  width: 80%;
  z-index: 100;
  overflow: hidden;
  margin: 0 auto;
  position: fixed;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 475px;
}

.close-button {
  position: absolute;
  padding: 26.36px;
  width: 18px;
  height: auto;
  box-sizing: border-box;
}

.modal_dietary {
  top: 13vh;
  width: 80%;
  z-index: 301;
  overflow: hidden;
  margin-right: 2%;
  position: fixed;
  margin-right: 2%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 475px;
}

.dietary-indicator-popup {
  margin: 40px 30px 30px 30px;
  text-align: center;
}

.dietary-popup-heading {
  font-weight: bold;
  font-size: 22px;
  margin: 0.5em 0em;
}

@media (max-width: 470px) {
  .dietary-indicator-popup {
    margin: 40px 10px 40px 10px;
    text-align: center;
  }
}
.dietary-close-button {
  background: #a6e627;
  box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
  border-radius: 50px;
  width: 90%;
  margin: 0;
  max-width: 600px;
  cursor: pointer;
  margin-top: 1em;
}
.dietary-close-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
}
