import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cost: 0,
    modifiers: [],
    minSelectErrors: [],
    productsInCart: [],
    errorMessage: "",
    parent: "",
    child: "",
    isEdit: false,
    basketId: "",
    cafeId: "",
    upsellItems: [],
  },

  reducers: {
    setUpsellItems(state, action) {
      state.upsellItems.push(action.payload);
    },

    removeUpsellItems(state, action) {
      state.upsellItems = state.upsellItems.filter(
        (item) => item != action.payload
      );
    },
    setIsEdit(state, action) {
      state.isEdit = action.payload.isEdit;
    },
    setBasketId(state, action) {
      state.basketId = action.payload;
    },
    setCafeId(state, action) {
      state.cafeId = action.payload;
    },
    resetCost(state, action) {
      state.cost = action.payload.cost;
    },
    addItemCost(state, action) {
      state.cost += action.payload.cost;
    },
    removeItemCost(state, action) {
      state.cost -= action.payload.cost;
    },
    addModifiers(state, action) {
      if (!state.modifiers.includes(action.payload.modifiers))
        state.modifiers.push(action.payload.modifiers);
    },
    removeModifiers(state, action) {
      state.modifiers = state.modifiers.filter(
        (item) => item != action.payload.modifiers
      );
    },
    addGroupModifiers(state, action) {
      if (action.payload.child) {
        state.modifiers.push(action.payload.child);
      }
      if (action.payload.parent) {
        state.modifiers.push(action.payload.parent);
      }
    },
    removeGroupModifiers(state, action) {
      state.modifiers = state.modifiers.filter(
        (item) => item != action.payload.child
      );
      state.modifiers = state.modifiers.filter(
        (item) => item != action.payload.parent
      );
    },
    resetModifiers(state, action) {
      state.modifiers = [];
    },
    minSelectsError(state, action) {
      // if (
      //   action.payload.minSelectErrors.description === "Choose Wrap" ||
      //   action.payload.minSelectErrors.description === "Choose Flatbread" ||
      //   action.payload.minSelectErrors.description === "Choose Sandwich" ||
      //   action.payload.minSelectErrors.description === "Choose Quesadilla" ||
      //   action.payload.minSelectErrors.description === "Choose Salad" ||
      //   action.payload.minSelectErrors.description === "Choose Breakfast"
      // ) {
      //   if (state.minSelectErrors.length > 0) {
      //     state.minSelectErrors.forEach((min) => {
      //       if (
      //         min.description === "Choose Wrap" ||
      //         min.description === "Choose Flatbread" ||
      //         min.description === "Choose Sandwich" ||
      //         min.description === "Choose Quesadilla" ||
      //         min.description === "Choose Salad" ||
      //         min.description === "Choose Breakfast"
      //       )
      //         min.errorMessage = "";
      //     });
      //   } else state.minSelectErrors.push(action.payload.minSelectErrors);
      // }
      // if (action.payload.minSelectErrors.description === "Choose Smoothie") {
      //   if (
      //     state.minSelectErrors.length > 1 ||
      //     state.minSelectErrors.length > 2
      //   ) {
      //     state.minSelectErrors.forEach((min) => {
      //       if (min.description === "Choose Smoothie") min.errorMessage = "";
      //     });
      //   } else state.minSelectErrors.push(action.payload.minSelectErrors);
      // }
      // if (action.payload.minSelectErrors.description === "Choose Your Side") {
      //   if (state.minSelectErrors[0]?.errorMessage === "") {
      //     if (action.payload.minSelectErrors.errorMessage !== "") {
      //       if (state.minSelectErrors.length < 4)
      //         state.minSelectErrors.push(action.payload.minSelectErrors);
      //     } else {
      //       state.minSelectErrors.forEach((min) => {
      //         if (min.description === "Choose Your Side")
      //           min.errorMessage = action.payload.minSelectErrors.errorMessage;
      //       });
      //     }
      //   } else {
      //     if (action.payload.minSelectErrors.errorMessage !== "") {
      //       state.minSelectErrors.push(action.payload.minSelectErrors);
      //     } else {
      //       state.minSelectErrors.forEach((min) => {
      //         if (min.description === "Choose Your Side")
      //           min.errorMessage = action.payload.minSelectErrors.errorMessage;
      //       });
      //     }
      //   }
      // }
      // if (
      //   action.payload.minSelectErrors.description === "Pick your side sauce" ||
      //   action.payload.minSelectErrors.description === "Choice of Protein" ||
      //   action.payload.minSelectErrors.description === "Choose Peanut Butter or Strawberries" ||
      //   action.payload.minSelectErrors.description === "White or Dark Chocolate" ||
      //   action.payload.minSelectErrors.description === "Choose Side" ||
      //   action.payload.minSelectErrors.description === "Choose Your Chip Flavor"
      // ) {
      //   if (action.payload.minSelectErrors.errorMessage !== "") {
      //     state.minSelectErrors.push(action.payload.minSelectErrors);
      //   } else {
      //     state.minSelectErrors.forEach((min) => {
      //       if (
      //         min.description === "Pick your side sauce" ||
      //         min.description === "Choice of Protein" ||
      //         min.description === "Choose Peanut Butter or Strawberries" ||
      //         min.description === "White or Dark Chocolate" ||
      //         min.description === "Choose Side" ||
      //         min.description === "Choose Your Chip Flavor"
      //       )
      //         min.errorMessage = action.payload.minSelectErrors.errorMessage;
      //     });
      //   }
      // }
    },
    resetMinSelectErrors(state, action) {
      state.minSelectErrors = [];
    },
    getProducts(state, action) {
      state.productsInCart = [];
      if (
        action.payload.productsInCart &&
        action.payload.productsInCart.length
      ) {
        for (let product of action.payload.productsInCart) {
          if (product.recipient == localStorage.getItem("name")) {
            state.productsInCart.push(product);
          }
        }
      }
      localStorage.setItem(
        "productsInCart",
        JSON.stringify(state.productsInCart)
      );
    },
    showError(state, action) {
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;