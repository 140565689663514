"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.QrcodeRedeembale = exports.QrcodeRewards = exports.RewardsApplyPromo = exports.ApplyRewardsRequest = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * Data required to make apply rewards request
 */
var ApplyRewardsRequest = /** @class */ (function (_super) {
    __extends(ApplyRewardsRequest, _super);
    /**
     * Constructor for ApplyRewardsRequest
     * @param {ApplyRewardsRequest} obj
     */
    function ApplyRewardsRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for ApplyRewardsRequest
     * @return {ObjectSchema}
     */
    ApplyRewardsRequest.prototype.schema = function () {
        return validations_1.object({
            membershipid: validations_1.number(),
            references: validations_1.array(validations_1.string()),
        });
    };
    return ApplyRewardsRequest;
}(BaseModel_1.BaseModel));
exports.ApplyRewardsRequest = ApplyRewardsRequest;
/**
 * Data required to make rewards apply promo request
 */
var RewardsApplyPromo = /** @class */ (function (_super) {
    __extends(RewardsApplyPromo, _super);
    /**
     * Constructor for RewardsApplyPromo
     * @param {RewardsApplyPromo} obj
     */
    function RewardsApplyPromo(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for RewardsApplyPromo
     * @return {ObjectSchema}
     */
    RewardsApplyPromo.prototype.schema = function () {
        return validations_1.object({
            code: validations_1.string(),
        });
    };
    return RewardsApplyPromo;
}(BaseModel_1.BaseModel));
exports.RewardsApplyPromo = RewardsApplyPromo;
/**
 * Data required to qrcode using rewardId
 */
var QrcodeRewards = /** @class */ (function (_super) {
    __extends(QrcodeRewards, _super);
    /**
     * Constructor for QrcodeRewards
     * @param {QrcodeRewards} obj
     */
    function QrcodeRewards(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for QrcodeRewards
     * @return {ObjectSchema}
     */
    QrcodeRewards.prototype.schema = function () {
        return validations_1.object({
            rewardId: validations_1.string(),
            gpsAccuracy: validations_1.number(),
            locationId: validations_1.string(),
            latitude: validations_1.number(),
            longitude: validations_1.number(),
        });
    };
    return QrcodeRewards;
}(BaseModel_1.BaseModel));
exports.QrcodeRewards = QrcodeRewards;
/**
 * Data required to qrcode using redeemableId
 */
var QrcodeRedeembale = /** @class */ (function (_super) {
    __extends(QrcodeRedeembale, _super);
    /**
     * Constructor for QrcodeRedeembale
     * @param {QrcodeRedeembale} obj
     */
    function QrcodeRedeembale(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for QrcodeRedeembale
     * @return {ObjectSchema}
     */
    QrcodeRedeembale.prototype.schema = function () {
        return validations_1.object({
            redeemableId: validations_1.string(),
            gpsAccuracy: validations_1.number(),
            locationId: validations_1.string(),
            latitude: validations_1.number(),
            longitude: validations_1.number(),
        });
    };
    return QrcodeRedeembale;
}(BaseModel_1.BaseModel));
exports.QrcodeRedeembale = QrcodeRedeembale;
