.list-heading-main {
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #222222;
}
.list-heading-name {
    flex: 1;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #222222;
    word-break: break-word;
    padding-right: 30px;
}
.list-heading-item-count {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #222222;
}
@media(max-width: 960px) {
    .list-heading-item-count {
      padding-right: 20px;
    }
}