.bannerTitleStyle {
    color: #fff;
    font-weight: bold;
    margin-right: 3px;
    margin-left: 3px;
}
.container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: "#cf0082";
    bottom: 7px;
    left: 5px;
    border-radius: 5px;
    padding: 2px;
    justify-content: center;
    align-items: center;
}