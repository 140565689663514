.custom-dropdown {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-shadow: -4px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
}
.custom-dropdown-button {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #111111;
}
.bi-caret-down-fill::before {
    content: '\F229';
}