.menu-item-circle {
    width: 35px;
    height: 35px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
}
.menu-item-circle-span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #111111;
}