"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpCode = void 0;
/* eslint-disable */
var HttpCode;
(function (HttpCode) {
    HttpCode[HttpCode["NOT_MODIFIED"] = 204] = "NOT_MODIFIED";
    HttpCode[HttpCode["SUCCESS"] = 200] = "SUCCESS";
})(HttpCode || (HttpCode = {}));
exports.HttpCode = HttpCode;
/* eslint-enable */
