import mParticle from '@mparticle/web-sdk';

    export const addToCartEvent = (p, isUpsell) => {
        let userName = localStorage.getItem("name");
        let mParticleConfig = {
          isDevelopmentMode: process.env.REACT_APP_MPARTICLE_IS_DEVELOPMENTMODE,
        };
        mParticle.init(process.env.REACT_APP_MPARTICLE_KEY, mParticleConfig)

        let resProductCustomization = p?.choices?.map(i => i.name).join(', ');
        let resIdChoices = p?.choices?.map(i => i.id).join(', ');
        let resPriceChoice = p?.choices.reduce((a, current) => a + current.cost, 0);
        const attributes: mParticle.SDKEventAttrs = {
          id: resIdChoices,
          productCustomization:resProductCustomization,
          isUpsell: isUpsell,
          totalModifierPrice: (resPriceChoice != 0) ? (Number.isInteger(resPriceChoice) ? resPriceChoice : resPriceChoice.toFixed(2)) : "0",
        };
        let product = mParticle.eCommerce.createProduct(
            p.name,
            p.productId.toString(),
            p.totalcost === 0 ? 0 : p.totalcost,
            p.quantityincrement,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            attributes,
          );
        let customFlags = {
          'customerName': userName,
          "environment" : process.env.REACT_APP_MPARTICLE_ENVIRONMENT,
        }

        mParticle.eCommerce.logProductAction(
          mParticle.ProductActionType.AddToCart, 
          product, 
          customFlags,
          );
    };

    export const checkoutEvent = (totalCost, IsUpsellArray) => {
      let userName = localStorage.getItem("name");
      let cartProducts = JSON.parse(localStorage.getItem("productsInCart"));
      let mParticleConfig = {
        isDevelopmentMode: process.env.REACT_APP_MPARTICLE_IS_DEVELOPMENTMODE,
      };
      let customFlags = {
        'customerName': userName,
        "environment" : process.env.REACT_APP_MPARTICLE_ENVIRONMENT,
      }
      mParticle.init(process.env.REACT_APP_MPARTICLE_KEY, mParticleConfig)
      
      let cartProductsFinal:any = [];
      cartProducts?.map((product, index) => {
        const attributes: mParticle.SDKEventAttrs = {
          isUpsell: IsUpsellArray[index],
        };
        const products = mParticle.eCommerce.createProduct(
              product.name,
              product.id.toString(),
              product.totalcost ? product.totalcost / product.quantity : 0,
              product.quantity,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              attributes,
          )
          cartProductsFinal.push(products)
      })
      let customAttributes = {
        revenue : totalCost.toFixed(2),  
      };
  
      mParticle.eCommerce.logProductAction(
        mParticle.ProductActionType.Checkout, 
        cartProductsFinal, 
        customAttributes,
        customFlags,
        );
    }
