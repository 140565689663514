import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../constants/constants";

export const firebaseAnalytics = (error, eventName) => {
    const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      logEvent(analytics, eventName, {
        description: error.statusText.length > 0 ?? "Na",
        statusCode: error.statusCode ?? "Na"
      });
}