import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import classes from './Cart.module.css';
import CartInvitation from './CartInvitation';
import Orders from './Orders';

const Cart = (props) => {
  const productsInCart = useSelector((state: RootState) => state.cart.productsInCart);

  return (
    <Fragment>
      {productsInCart && productsInCart.length ? <Orders products={productsInCart} finalSubmit={props.finalSubmit} finalSubmitHandler={props.finalSubmitHandler} /> : <CartInvitation />}
    </Fragment>

  );
};

export default Cart;