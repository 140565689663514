"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDetail = exports.TokenRequest = exports.ApplePayRequest = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * ApplePayRequest model
 */
var ApplePayRequest = /** @class */ (function (_super) {
    __extends(ApplePayRequest, _super);
    function ApplePayRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for ApplePayRequest class
     * @return {ObjectSchema}
     */
    ApplePayRequest.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return ApplePayRequest;
}(BaseModel_1.BaseModel));
exports.ApplePayRequest = ApplePayRequest;
/**
 * TokenRequest model
 */
var TokenRequest = /** @class */ (function (_super) {
    __extends(TokenRequest, _super);
    function TokenRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for TokenRequest class
     * @return {ObjectSchema}
     */
    TokenRequest.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return TokenRequest;
}(BaseModel_1.BaseModel));
exports.TokenRequest = TokenRequest;
/**
 * UserDetail Model
 */
var UserDetail = /** @class */ (function (_super) {
    __extends(UserDetail, _super);
    function UserDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for UserDetail class
     * @return {ObjectSchema}
     */
    UserDetail.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return UserDetail;
}(BaseModel_1.BaseModel));
exports.UserDetail = UserDetail;
