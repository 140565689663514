.menu-hr {
    width: 947px;
    height: 0px;
    border: 1px solid #E6E7EB;
    margin: 20px 0 0 0;
    opacity: 1;
    width: 100%;
}
.menu-list-main {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    flex-wrap: wrap;
}
.menu-card-block {
    margin-top:40px;
}
.menu-list-main:focus {
    outline: none;
}

@media (max-width: 960px) {
    .menu-list-main {
        flex-wrap: nowrap;
        overflow: auto;

    }
    .menu-list-parent {
        overflow-x: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .menu-list-parent::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .menu-list-main::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }
    .menu-card-block {
        margin-top: 20px;
    }
}
