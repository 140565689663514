.btn-card {
    min-width: 129px;
    max-width: 129px;
    height: 225px;
    background: #FFFFFF;
    box-shadow: -4px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border: 4px solid transparent;
}
.imagecard-image {
    width: 106px;
    height: 97px;
    align-self: center;
    margin-bottom: 10px;
}
.imagecard-name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    word-break: break-word;
}
.imagecard-name-extras{
    line-height: 16px;
    text-align: center;
    color: #CF0082;
    word-break: break-word;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
}
.imagecard-second-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    word-break: break-word;
}
.imagecard-calories {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #5C5C5C;
    margin-top: 4px;
}
.imagecard-cost {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    margin-top: 6px;
    margin-bottom: 19px;
}
.bi-dash-circle-fill:before {
    color: #000;
    font-size: 30px;
    box-shadow: -4px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 20px;
}
.bi-dash-circle-fill:before {
    content: "\F26B";
    color: #fff;
    background: #FFBA00;
}
.selected-card {
    border: 4px solid #FFBA00;
}
.span-main {
    height: 26px;
    align-self: end;
    margin-top: -14px;
    margin-right: -14px;
}
.btn-card-expand {
    height: 50px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: revert;
    cursor: pointer;
    align-items: center;
    border: 4px solid transparent;
    align-items: center;
    padding: 20px 0;
    justify-content: space-between;
}
.extras-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hr-layout{
    width: 100%;
    display: flex;
    justify-content: center;
}
.hrLine{
    width: 98%;
    height: 2px;
    background-color: #e4e2e2;
}
.imagecard-Icons {
    font-size: 27px;    
}
.imagecard-Icons-extras {
    font-size: 27px;
    color: #CF0082;    
}
.imagecard-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 19px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.view-all-btn{
    min-width: 129px;
    max-width: 129px;
    height: 225px;
    background: #FFFFFF;
    box-shadow: -4px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border: 4px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #CF0082;
}