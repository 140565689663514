import classes from './OptionsTextCard.module.css';
import OptionGroupHeading from "./OptionGroupHeading";
import TypeTextCard from './TypeTextCard';
import { Fragment, useEffect, useState } from 'react';
import OptionGroups from './OptionGroups';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart-slice';
import ErrorModal from '../UI/ErrorModal';
import { RootState } from '../../store';

const OptionsTextCard = (props) => {
    const optionGroup = props.optionGroup;
    const mandatory = optionGroup.mandatory;
    let minselects = optionGroup.minselects;
    let maxselects = optionGroup.maxselects;
    const [modifiers, setModifiers] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [modalEnabled, setModalEnabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const productInCart = useSelector(
        (state: RootState) => state.cart.productsInCart
      );
  
    if (!minselects) {
        minselects = 0;
        if (mandatory) {
            minselects = 1;
        }
    }

    useEffect(() => {
        
        let errorMessage = '';
        if (minselects) {
            if (selectedOptions.length < minselects) {
                errorMessage = `Please make a selection for "${optionGroup.description}"`;
            } else {
                errorMessage = '';
            }
            dispatch(
                cartActions.minSelectsError({
                    minSelectErrors: {
                        description: optionGroup.description,
                        errorMessage: errorMessage
                    }
                })
            );
        }
    }, [selectedOptions]);

    const updateOptionsSelected = (option, selected) => {
        setSelectedOptions((prevState) => {
            if (selected) {
                if (prevState.length == 0 || !maxselects || prevState.length <= maxselects - 1) {
                    return [...prevState, option]
                } else {
                    return prevState
                }
            } else {
                return prevState.filter(ele => {
                    return ele.id != option.id;
                })
            }
        })
    }
    useEffect(() => {
        if (selectedOptions && selectedOptions.length) {
            for (let i = selectedOptions.length - 1; i >= 0; i--) {
                if (selectedOptions[i].modifiers && selectedOptions[i].modifiers.length) {
                    setModifiers((prevState) => {
                        return [<OptionGroups key={selectedOptions[i].id} optionGroups={selectedOptions[i].modifiers} uniqueId={props.uniqueId} />]
                    })
                    break;
                }
            }
        } else {
            setModifiers((prevState) => {
                return []
            })
        }
    }, [selectedOptions]);

    const showError = () => {
        setErrorMessage(`Choose upto ${maxselects + 1} options for "${optionGroup.description}"`);
    }
    const onErrorModalClose = () => {
        setModalEnabled(false);
        setErrorMessage('');
    }

    useEffect(()=>{
        if(productInCart.length && props.isEdit){
          productInCart.forEach((product) => {   
            if(product.id === props.uniqueId)
              product.choices.forEach((choice) => {
                optionGroup.options.forEach((option) =>{
                  if(option.id === choice.optionid) {
                    setSelectedOptions(prev => [...prev, option]);
                    dispatch(cartActions.resetCost({cost: product.totalcost}))
                  }
                })   
              });
          });
        }
      }, [productInCart.length])
    
    return (
        <div tabIndex={0} aria-label={optionGroup.description}>
            {errorMessage && modalEnabled ? <ErrorModal onClose={onErrorModalClose} message={errorMessage} /> : null}
            <div className={classes['heading-main']}>
                <OptionGroupHeading heading={optionGroup.description} />
                <OptionGroupHeading heading={optionGroup.options.length} />
            </div>
            <div className={classes['textcard-parent']}>
                <div className={classes['textcard-main']}>
                    {optionGroup.options && optionGroup.options.length ?
                        optionGroup.options.map(option =>
                            <TypeTextCard option={option} key={option.id} cardClick={updateOptionsSelected} minselects={minselects}
                                maxselects={maxselects} selectedOptions={selectedOptions} showError={showError} />) : null
                    }
                </div>
            </div>
            {modifiers && modifiers.length ? <div>{modifiers}</div> : null}
        </div>

    );
};

export default OptionsTextCard;