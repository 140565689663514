.categories-main {
    border: 2px solid #4DCFE8;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 16px;
    align-items: center;
}
.categories-list {
    position: relative;
    height: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #111111;
    padding: 5px 30px;
    width: 140px;
}
.category-selected {
    background: #4DCFE8;
    border-radius: 8px;
    display: flex;
}
.category-div {
    cursor: pointer;
    height: 50px;
    width: 140px;
}
.sticky {
    position: fixed;
    top: 0;
    background-color: white;
  }
@media(max-width: 960px) {
    .categories-main {
        flex-wrap: nowrap;
        border:none;
    }
    .menu-parent {
        overflow-x: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }

    .COMBOS{
        transform-origin: 0px;
        transform:translateX(-50px);
        transition: transform 330ms linear;
    }
    .SMOOTHIES{
        transform-origin: 0px;
        transform:translateX(-200px);
        transition: transform 330ms linear;
    }
    .ENTREES{
        transform-origin: 0px;
        transform:translateX(-350px);
        transition: transform 330ms linear;
    }
    .BREAKFAST{
        transform-origin: 0px;
        transform:translateX(-500px);
        transition: transform 330ms linear;
    }
    .KIDS{
        transform-origin: 0px;
        transform:translateX(-600px);
        transition: transform 330ms linear;
    }
    .SWEETS{
        transform-origin: 0px;
        transform:translateX(-750px);
        transition: transform 330ms linear;
    }
    
    .menu-parent::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .categories-list {
        width: 100px;
        padding: 0px;
        height: 50px;
    }
    .category-selected {
        background: #4dcfe8;
        border-radius: 8px;
        padding: 4px 0;
        height: 50px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media(min-width: 962px){
    .sticky {
        width: 62.899997%;
    }
}
@media (max-width: 960px){
    .sticky {
        width: 62.899997%;
    }
}
@media (max-width: 767px){
    .sticky {
        width: 100%;
    }
}