.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0);
  }
  
  .modal {
    top: 26vh;
    width: 95%;
    z-index: 100;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    position: fixed;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 475px;
    justify-content: center;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,8);
  }

  .modal_error {
    top: 26vh;
    width: 95%;
    z-index: 100;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    position: fixed;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 640px;
    justify-content: center;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,8);
  }

  .link {
    color: transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
  
  .close-button {
    padding: 23px;
    margin-bottom:-30px;
    width: 1px;
    height: auto;
    box-sizing: border-box;
  }
  .text {
    word-wrap: break-word;
    padding: 30px;
    font-size: 25px;
    text-align: center;
    padding-top: 5px;
  }
  .textExpired{
    word-wrap: break-word;
    padding: 30px;
    font-size: 25px;
    text-align: center;
  }
  .button {
    font-size: 18px;
  }