"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Choices = exports.Products = exports.CustomFields = exports.Taxes = exports.OrderDetail = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * OrderDetail Model
 */
var OrderDetail = /** @class */ (function (_super) {
    __extends(OrderDetail, _super);
    function OrderDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Data class
     * @return {ObjectSchema}
     */
    OrderDetail.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return OrderDetail;
}(BaseModel_1.BaseModel));
exports.OrderDetail = OrderDetail;
/**
 * Taxes  Model
 */
var Taxes = /** @class */ (function (_super) {
    __extends(Taxes, _super);
    function Taxes() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Taxes class
     * @return {ObjectSchema}
     */
    Taxes.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Taxes;
}(BaseModel_1.BaseModel));
exports.Taxes = Taxes;
/**
 * CustomFields  Model
 */
var CustomFields = /** @class */ (function (_super) {
    __extends(CustomFields, _super);
    function CustomFields() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for CustomFields class
     * @return {ObjectSchema}
     */
    CustomFields.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CustomFields;
}(BaseModel_1.BaseModel));
exports.CustomFields = CustomFields;
/**
 * Products  Model
 */
var Products = /** @class */ (function (_super) {
    __extends(Products, _super);
    function Products() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Products class
     * @return {ObjectSchema}
     */
    Products.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Products;
}(BaseModel_1.BaseModel));
exports.Products = Products;
/**
 * Choices  Model
 */
var Choices = /** @class */ (function (_super) {
    __extends(Choices, _super);
    function Choices() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Choices class
     * @return {ObjectSchema}
     */
    Choices.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Choices;
}(BaseModel_1.BaseModel));
exports.Choices = Choices;
