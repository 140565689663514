import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cartActions } from "../../store/cart-slice";
import HighLightBanner from "../productDetail/HighlightBanner";
import classes from "./MenuItem.module.css";

const MenuItem = (props) => {
  const product = props.product;
  let indicator = null;
  let image = require("../../assets/noimage.png");
  const imagePath = useSelector((state: RootState) => state.catelog.imagePath);
  let circleColor = "#73EBF2";
  let imageUrl = "";
  const dispatch = useDispatch()

  if (product.images && product.images.length) {
    let groupName = "mobile-webapp-menu";
    for (let prodImage of product.images) {
      if (window.innerWidth <= 960) {
        groupName = "mobile-app";
      }
      if (prodImage.groupname == groupName) {
        imageUrl = imagePath + prodImage.filename;
        break;
      }
    }
  }
  if (props.product.metadata && props.product.metadata.length) {
    for (let element of props.product.metadata) {
      if (element.key == "indicator") {
        if (element.images) {
          let data = JSON.parse(element.images);
          if (data && data.length) {
            indicator = data[0];
            circleColor = indicator.color;
          }
        }
      }
    }
  }
  let circleStyle = { background: circleColor };

  return (
    <li className={classes["menu-item-card"]} key={product.id}>
      <div
        onClick={() => dispatch(cartActions.setIsEdit({ isEdit: false }))}
        className={
          props?.isAdded
            ? classes["menu-item-main-div"] + " " + classes["added"]
            : classes["menu-item-main-div"]
        }
      >
        <div
          className={classes["menu-item-image-div"]}
          onClick={props.onItemSelect(props.product)}
        >
          <img
            className={classes["menu-item-image"]}
            alt={product.name}
            src={imageUrl ? imageUrl : image}
          />
          <HighLightBanner data={props.product?.metadata} from={"menu"} />
        </div>
        {props?.isAdded ? (
          <div className={classes["product-added"]}>
            <i className={classes["bi-check-circle"]}></i>
            <p className={classes["added-text"]}>Added</p>
          </div>
        ) : null}
      </div>
      <button
        className={classes["menu-item-name"]}
        onClick={props.onItemSelect(props.product)}
      >
        {product.name
          .toLowerCase()
          .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
      </button>
      {product.cost ? (
        <p className={classes["menu-item-price"]}>${product.cost.toFixed(2)}</p>
      ) : null}
    </li>
  );
};

export default MenuItem;
