"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tax = exports.Address = exports.Choice = exports.Product = exports.Basket = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * Basket Model
 */
var Basket = /** @class */ (function (_super) {
    __extends(Basket, _super);
    function Basket() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Basket class
     * @return {ObjectSchema}
     */
    Basket.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Basket;
}(BaseModel_1.BaseModel));
exports.Basket = Basket;
/**
 * Product Model
 */
var Product = /** @class */ (function (_super) {
    __extends(Product, _super);
    function Product() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Product class
     * @return {ObjectSchema}
     */
    Product.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Product;
}(BaseModel_1.BaseModel));
exports.Product = Product;
/**
 * Choice Model
 */
var Choice = /** @class */ (function (_super) {
    __extends(Choice, _super);
    function Choice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Choice class
     * @return {ObjectSchema}
     */
    Choice.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Choice;
}(BaseModel_1.BaseModel));
exports.Choice = Choice;
/**
 * Address Model
 */
var Address = /** @class */ (function (_super) {
    __extends(Address, _super);
    function Address() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Address class
     * @return {ObjectSchema}
     */
    Address.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Address;
}(BaseModel_1.BaseModel));
exports.Address = Address;
/**
 * Tax Model
 */
var Tax = /** @class */ (function (_super) {
    __extends(Tax, _super);
    function Tax() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Tax class
     * @return {ObjectSchema}
     */
    Tax.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Tax;
}(BaseModel_1.BaseModel));
exports.Tax = Tax;
