import RecommendationRow from "./RecommendationRow";
import classes from "./RecommendationBody.module.css";
import { useLocation } from "react-router-dom";
import { API } from "tsc-core";
import { useEffect, useState } from "react";
import { useViewport } from "../../../hooks/UseViewport";
import TSCButton from "../../UI/Button";
import { initializeApp } from "firebase/app";
import { firebaseConfig, TRANSLATION_PATH } from "../../../constants/constants";
import { getDatabase, onValue, ref } from "firebase/database";

const RecommendationBody = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const basketId = queryParams.get("basket");
  const basketIdGO = queryParams.get("basketid")
  const { width } = useViewport();
  const breakpoint = 768;
  const [upsellData, setUpsellData] = useState({
    groups: [],
  });
  const [youMightAlsoEnjoy, setYouMightAlsoEnjoy] = useState('');
  const [continueText, setContinueText] = useState('');
  const [goToCheckout, setGoTOCheckout] = useState('');

  const getRecommendation = async () => {
    let response = await API.CART.getUpsell(basketId ?? basketIdGO as any);
    setUpsellData({
      groups: response?.groups as any,
    });
  };

  useEffect(() => {
    getRecommendation();
  }, []);

  useEffect(() => {
    const fetchFromFirebase = async() =>{
      const app = initializeApp(firebaseConfig);   
      const firebaseDB = getDatabase(app);
      const query = ref(firebaseDB, TRANSLATION_PATH);
      var firebaseData = null;
      await onValue(query, (snapshot) => {
          firebaseData = snapshot.val(); 
          setYouMightAlsoEnjoy(firebaseData.Group_Order_Upsell_Heading)
          setContinueText(firebaseData.Group_Order_Upsell_CTA)
          setGoTOCheckout(firebaseData.Group_Order_Upsell_No_CTA)
      });
    }
    fetchFromFirebase().catch(console.error);
  }, []);

  return (
		<div>
			<div className={classes["recommendation-modal-body"]}>
				<h6 className={classes["title"]}>{youMightAlsoEnjoy}</h6>
				<div className={classes["item-container"]}>
					{upsellData.groups.length > 0 &&
						upsellData.groups.map((group) => (
							<RecommendationRow
								key={group.title}
								items={group.items}
							/>
						))}
				</div>
			</div>
			<div className={classes["buttons"]}>
				<TSCButton onButtonClick={props.onClose}>{continueText}</TSCButton>
				<button className={classes["checkout-button"]} onClick={props.submitClickedHandler}>{goToCheckout}</button>
			</div>
		</div>
  );
};

export default RecommendationBody;
