import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Card from "../UI/Card";
import classes from "./ProductDetailModal.module.css";

import ProductDetails from "./ProductDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cartActions } from "../../store/cart-slice";
import { productDetailActions } from "../../store/product-details-slice";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
  const dispatch = useDispatch();
  const loader: any = useSelector((state: RootState) => state.loader.loader);

  useEffect(() => {
    let selectedParam = document.getElementById("overlay-modal");
    selectedParam.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onCloseAddCartPopup = () => {
    props?.onCloseAddCartDetailsModal();
    dispatch(productDetailActions.getAllOptions({optionGroups: [],}));
    dispatch(cartActions.resetCost({ cost: 0 }));
    dispatch(cartActions.resetMinSelectErrors({ minSelectErrors: 0 }));
    dispatch(cartActions.resetModifiers({}));
    dispatch(cartActions.showError({errorMessage: "",}));
  };
  
  let concernedElement = null;
  document.addEventListener("mousedown", (event) => {
    concernedElement = document.querySelector(".backdrop_fe60eb");
    if(concernedElement != null){
      if (concernedElement.contains(event.target)) {
        concernedElement = null;
        props?.onCloseAddCartDetailsModal();
      }
    }
	});

  return (
    <Card className={classes.modal}>
      <div id="backdrop-modal"></div>
      <div
        id="overlay-modal"
        className={`${classes["overlay-modal"]} ${
          loader && loader.loaderVisible && classes["show-loader"]
        }`}
      ></div>
      <button className={classes["close-button"]} 
      onClick={() => onCloseAddCartPopup()}>+</button>
      <ProductDetails
        product={props.product}
        onShowNutritionInfo={() => props?.onShowNutritionInfoModal()}
        isEdit = {props.isEdit ?? false}
        uniqueId = {props.uniqueId}
      />
    </Card>
  );
};

const ProductDetailModal = (props) => {
  useEffect( () => {
    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
  }, []);

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onCloseAddCartDetailsModal={() => props?.onCloseAddCartModal()}
          onShowNutritionInfoModal={() => props?.onShowNutritionModal()}
          product={props.product}
          onClose={props.onModalClose}
          nutritionData={props?.nutrientsData}
          isEdit = {props.isEdit ?? false}
          uniqueId = {props.uniqueId}
        />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default ProductDetailModal;
