.bi-plus-circle-fill::before,
.bi-check-circle-fill:before {
    color: #000;
    font-size: 18px;
    box-shadow: -4px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 20px;
    vertical-align: 0;
}
.bi-plus-circle-fill,
.bi-check-circle-fill {
    align-self: center;
    margin-left: 37.41px;
    margin-right: 13.5px;
    cursor: pointer;
}
.bi-plus-circle-fill::before {
    content: "\F4F9";
}
.bi-check-circle-fill:before {
    content: "\F26E";
}
.bi-check-circle-fill {
    background: #82CF00;
    width: 18px;
    height: 18px;
    border-radius: 20px;
}
.customize-name {
    font-style: normal;
    font-weight: bold;
    font-size: 10.125px;
    line-height: 12px;
    color: #111111;
    margin: 3.375px 0px;
    word-break: break-word;
    cursor: pointer;
}
.customize-calories {
    font-style: normal;
    font-weight: 500;
    font-size: 10.125px;
    line-height: 13px;
    color: #111111;
    word-break: break-word;
    cursor: pointer;
}
.customize-main {
    display: flex;
    flex-direction: row;
    min-height: 53.76px;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 0.84375px solid #E6E7EB;
    padding: 12.54px 0;
    width: 100%;
}
.customize-details {
    display: flex;
    flex-direction: column;
}
.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
    vertical-align: 0;
}