.slide-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 32px;
}
.reset-ingredients {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
    border: 2px solid #000;
    text-align: center;
    align-self: center;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #111111;
    cursor: pointer;
}
@media (max-width: 960px) {
    .slide-main {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}