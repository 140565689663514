.search-heading {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #1D232E;
}
.search-main {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
.search-div {
    display: flex;
    margin-bottom: 58px;
}
.search-text{
    max-width: 300px;
}
.bi-search {
    display: none;
}
.cart-go-ahead {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1D232E;
    margin-top: 20px;
}
@media(max-width: 960px) {
    .search-main {
        margin-top: 20px;
    }
    .search-div {
        margin-bottom: 30px;
    }
}
@media(max-width: 470px) {
    .search-main {
        margin-top: 0px;
    }
    .search-heading {
        display: none;
    }
    .search-text {
        max-width: 100%;
        margin-right: 40px;
    }
    .search-btn {
        display: none;
    }
}