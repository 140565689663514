import { API } from 'tsc-core';
import { firebaseAnalytics } from '../components/helpers/FirebaseAnalytics';
import { butterCmsActions } from './buttercms-slice';

export const buttercmsData = () => {
    return async (dispatch: any) => {
        const fetchData = async () => {
            let data = {};
            const response = await API.CMS.getPage('group-order-web' + '?rawdatarequired=true');
            if (response) {
                data = await response;
            }
            return data;
        };

        try {
            const buttercmsData: any = await fetchData();
            dispatch(
                butterCmsActions.setContent({
                    content: buttercmsData.contents || []
                })
            );
        } catch (error) {
            // dispatch(
            //     butterCmsActions.showError({
            //         errorMessage: 'We have a trouble connecting to the Store, Please try back later'
            //     })
            // );
            firebaseAnalytics(error, 'BUTTER_CMS_API')
        }
    };
};