import React from 'react';
import ReactDOM from 'react-dom';
import Card from '../../UI/Card';
import classes from './RecommendationModal.module.css';
import CloseButton from 'react-bootstrap/CloseButton'
import RecommendationModalBody from './RecommendationModalBody';


const Backdrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            <CloseButton className={classes['close-button']} onClick={props.onClose} />
            <RecommendationModalBody submitClickedHandler={props.submitClickedHandler} onClose={props.onClose}/>
        </Card>
    );
};

const RecommendationModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop />,
                document.getElementById('backdrop-modal')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay submitClickedHandler={props.submitClickedHandler} onClose={props.closeUpsellModal} onNameSave={props.onNameSave} />,
                document.getElementById('overlay-modal')
            )}
        </React.Fragment>
    );
};

export default RecommendationModal;