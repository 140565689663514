import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart-slice';
import classes from './SlideCustomize.module.css';

const SlideCustomize = (props) => {
  const option = props.option;
  const dispatch = useDispatch();
  const onSelectionChangeHandler = (param, option) => event => {
    props.setCustomized_selection(option)
    if (props.selectedCustomization == option.id) {
      props.onCustomization('');
      dispatch(
        cartActions.removeItemCost({ cost: option.cost })
      );
    } else {
      props.onCustomization(param)
      dispatch(
        cartActions.addItemCost({ cost: option.cost })
      );
    }
  }

  return (
    <button className={classes['customize-main']} tabIndex={0} aria-label={option.name} onClickCapture={onSelectionChangeHandler(option.id, option)} onClick={props.closeButtonHandler}>
      <i className={props.selectedCustomization == option.id ? classes['bi-check-circle-fill'] : classes['bi-plus-circle-fill']}></i>
      <div className={classes['customize-details']}>
        <div className={classes['customize-name']}>
          {option.name.toLowerCase().includes('sub') ?
            <div>
              {option.name.split('Sub')[1]}
            </div>
            :
            <div>
              {option.name}
            </div>
          }
        </div>
        {option.cost ? <div className={classes['customize-calories']}>+ ${option.cost.toFixed(2)}</div> : null}
      </div>
    </button>
  );
};

export default SlideCustomize;