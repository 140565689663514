import { useEffect, useRef, useState } from "react";
import TSCButton from "../UI/Button";
import TextInput from "../UI/TextInput";
import classes from "./CartName.module.css";
import mParticle from '@mparticle/web-sdk';

const CartName = (props) => {
  const [name, setName] = useState("");
  const nameRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (nameRef?.current?.children?.length) {
        nameRef.current.children[0].focus();
      }
    }, 100);
  }, []);

  const onNameChangeHandler = (event) => {
    setName((prevState) => {
      return event.target.value;
    });
  };

  const saveName = () => {
    localStorage.setItem("name", name.replace(/\s/g,''));
    let mParticleConfig = {
      isDevelopmentMode: true,
    };
    props.onNameSave();
  };

  return (
    <div className={classes["name-main"]} ref={nameRef} tabIndex={0}>
      <TextInput
        className={classes["name-text"]}
        onTextChange={onNameChangeHandler}
        value={name}
        placeholder="Your Name*"
      />
      <TSCButton
        className={classes["save-btn"]}
        disabled={!name}
        onButtonClick={saveName}
      >
        Save Name
      </TSCButton>
    </div>
  );
};

export default CartName;
