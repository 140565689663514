import { useEffect, useRef } from 'react';
import classes from './DropDownOptions.module.css';

const DropDownOptions = (props) => {
    const dropdownRef = useRef(null);
    useEffect(() => {
        dropdownRef.current.focus();
    }, [])
    
    return (
        <div className={classes['main-div']} ref={dropdownRef} tabIndex={0}>
            <h1 className={classes['drop-down-heading']}>{props.modalHeading}</h1>
            <div className={classes['drop-down-div']}>
                {
                    props.options.map(option => {
                        return <button className={classes['drop-down-main']} key={option.id} onClick={props.onOptionsClick(option)}>
                            <p className={classes['drop-down-name']}>{option.name}</p>
                            {option.basecalories ? <p className={classes['drop-down-calories']}>{option.basecalories} Calories</p> : null}
                            <p className={classes['drop-down-calories']}>+ ${parseFloat(option.cost).toFixed(2)}</p>
                            <hr className={classes['drop-down-hr']} />
                        </button>
                    })
                }
            </div>
        </div>

    );
};

export default DropDownOptions;