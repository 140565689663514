import classes from './HighLightBanner.module.css';

const HighLightBanner = (props) => {
  const { data, from } = props;
  const highlighters = data?.filter((el) => el.key === "highlights");
  const highlighterData = highlighters?.length > 0 ? JSON.parse(highlighters[0].images) : null;
  const isFromProduct = from === "product" ? true : false;
  const iconSize = isFromProduct ? 15 : 13;
  const titleFontSize = isFromProduct ? '12px' : '10px';

  if (highlighterData) {
    const { colorcode = "", bannertitle = "", iconimage = "" } = highlighterData;
 
    return (
      <div style={{ marginTop: "-23px", marginBottom: "10px", marginLeft: "5px"}}>
        <div style={{ backgroundColor: colorcode, padding: isFromProduct ? "4px" : "2px" }} className={classes['container']}>
          {iconimage !== "" && <img src={iconimage} alt="icon" style={{ height: `${iconSize}px`, width: `${iconSize}px` }} />}
          <p className={classes['bannerTitleStyle']} style={{fontSize:titleFontSize}}>{bannertitle}</p>
        </div>
      </div>
    );
  }

  return null;
}

export default HighLightBanner;
