"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestRequest = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * GuestPromo
 */
var GuestRequest = /** @class */ (function (_super) {
    __extends(GuestRequest, _super);
    /**
     * Constructor for Guest
     * @param {Guest} obj
     */
    function GuestRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for Guest
     * @return {ObjectSchema}
     */
    GuestRequest.prototype.schema = function () {
        return validations_1.object({
            email: validations_1.string(),
            firstName: validations_1.string(),
            lastName: validations_1.string(),
            phone: validations_1.string(),
            addressLine1: validations_1.optional(validations_1.string()),
            state: validations_1.optional(validations_1.string()),
            birthday: validations_1.optional(validations_1.string()),
            programAnniversary: validations_1.optional(validations_1.string()),
            marketingEmailSubscription: validations_1.boolean(),
            activeRegistration: validations_1.optional(validations_1.boolean()),
            sendComplianceSms: validations_1.boolean(),
            title: validations_1.optional(validations_1.string()),
            gender: validations_1.optional(validations_1.string()),
        });
    };
    return GuestRequest;
}(BaseModel_1.BaseModel));
exports.GuestRequest = GuestRequest;
