"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavOrderBasketRequest = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * Data required to make apply fav order basket
 */
var FavOrderBasketRequest = /** @class */ (function (_super) {
    __extends(FavOrderBasketRequest, _super);
    /**
     * Constructor for FavOrderBasketRequest
     * @param {FavOrderBasketRequest} obj
     */
    function FavOrderBasketRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for FavOrderBasketRequest
     * @return {ObjectSchema}
     */
    FavOrderBasketRequest.prototype.schema = function () {
        return validations_1.object({
            faveid: validations_1.number(),
            ignoreunavailableproducts: validations_1.boolean(),
            transfervendorid: validations_1.optional(validations_1.number()),
        });
    };
    return FavOrderBasketRequest;
}(BaseModel_1.BaseModel));
exports.FavOrderBasketRequest = FavOrderBasketRequest;
