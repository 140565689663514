import React, { useEffect, useState } from 'react';
import Card from '../components/UI/Card';
import classes from './ExpirationMessage.module.css';
import CloseButton from 'react-bootstrap/CloseButton'
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, TRANSLATION_PATH } from '../constants/constants';
import { getDatabase, onValue, ref } from 'firebase/database';
import { loaderActions } from '../store/loader-slice';
import TSCButton from '../components/UI/Button';

const Backdrop = (props) => {
    const dispatch = useDispatch()
    const [backgroundWhenError, setBackgroundWhenError] = useState('')
    const getBackground = async () => {
        const app = initializeApp(firebaseConfig);
        const firebaseDB = getDatabase(app);
        const query = ref(firebaseDB, process.env.REACT_FIREBASE_PATH);
        let firebaseData = null;
        dispatch(
          loaderActions.setLoader({
              loaderVisible: true
          })
      );
        await onValue(query, (snapshot) => {
          firebaseData = snapshot.val();
          
          setBackgroundWhenError(firebaseData.web_expiration_background)
          dispatch(
            loaderActions.setLoader({
                loaderVisible: false
            })
        );
        });
    }
    useEffect(() => {
        getBackground();
        return () => {
            setBackgroundWhenError(null); 
        };
      }, []);
        
    return( 
        <>
            { props.errorFlag ?
                <div className={classes.backdrop} style={{ backgroundImage: `url(${backgroundWhenError})`, backgroundRepeat:'no-repeat', backgroundSize: 'cover'}} /> :
                <div className={classes.backdrop} onClick={props.onClick} />
            }
        </> 
    )
};

const ModalOverlay = (props) => {
    return (
        <Card className={props.errorFlag ? classes.modal_error : classes.modal}>
            {
                !props.expired ?
                <CloseButton className={classes['close-button']} onClick={props.onClose} /> :
                null
            }
            <p className={props.expired ? classes.textExpired : classes.text}>{props.text}</p>
            {props.errorFlag ? (
                <a className={classes.link} href='https://order.tropicalsmoothiecafe.com/'>
                    <TSCButton className={classes['button']}>{props.buttonText}</TSCButton>
                </a>
            ) : null}
        </Card>
    );
};

const ExpirationMessage = (props) => {
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [buttonText, setButtonText] = useState('')
    const getText = async () => {
        const app = initializeApp(firebaseConfig);   
        const firebaseDB = getDatabase(app);
        const query = ref(firebaseDB, TRANSLATION_PATH);
        let firebaseData = null;
        dispatch(
            loaderActions.setLoader({
                loaderVisible: true
            })
        );
        await onValue(query, (snapshot) => {
            firebaseData = snapshot.val();
            if(props.expired){
                if(props.errorFlag) {
                    setMessage(firebaseData.group_order_order_already_submitted_message_body)
                    setButtonText(firebaseData.group_order_order_already_submitted_button_text)
                }
                else 
                    setMessage(firebaseData.group_order_participant_basket_expired)
            }else{
                let temp = firebaseData.group_order_web_deadline_message_title_text;
                temp = temp.replaceAll("@day", props.day);
                temp = temp.replaceAll("@time", props.time)
                setMessage(temp)
            }
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        });
    }
    useEffect(() => {
        getText()
        return () => {
            setMessage(null);
            setButtonText(null)
        };
      }, []);
    return (
            <div >
                {
                    props.expired ? <Backdrop errorFlag={props.errorFlag} /> :
                    <Backdrop onClick={props.onOutsideClick}/>
                }
                
                <ModalOverlay buttonText={buttonText} errorFlag={props.errorFlag} onClose={props.onClose} text={message} expired={props.expired}/>
            </div>

    );
};

export default ExpirationMessage;