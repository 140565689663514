import { API } from 'tsc-core';
import { firebaseAnalytics } from '../components/helpers/FirebaseAnalytics';
import { cartActions } from './cart-slice';
import { loaderActions } from './loader-slice';
import { uiActions } from './ui-slice';

export const removeCartData = (productId, basketId2) => {

    return async (dispatch: any, getState) => {
        dispatch(
            cartActions.showError({
                errorMessage: ''
            })
        );
        const addData = async () => {
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: true
                })
            );
            const queryParams = new URLSearchParams(location.search);
            const basketId = queryParams.get('basket') ?? basketId2;
            try {
                const response: any = await API.CART.removeProductFromBasket(
                    basketId,
                    parseInt(productId)
                );
                return response;
            } catch (error) {
                firebaseAnalytics(error, 'GO_ERR_REMOVE_PRODUCT_FROM_BASKET_API')
                return error;
            }
        };

        try {
            const cartData: any = await addData();
            if (cartData && cartData.hasOwnProperty('products')) {
                dispatch(
                    cartActions.getProducts({
                        productsInCart: cartData.products,
                        errorMessage: ''
                    })
                );
                dispatch(
                    uiActions.showNotification({
                        status: 'success',
                        title: 'Success!',
                        message: 'Product Removed Successfully!',
                    })
                );
            } else {
                dispatch(
                    uiActions.showNotification({
                        status: 'error',
                        title: 'Error!',
                        message: cartData.text,
                    })
                );
            }
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: 'error',
                    title: 'Error!',
                    message: error.text,
                })
            );
            dispatch(
                loaderActions.setLoader({
                    loaderVisible: false
                })
            );
        }
    };
};