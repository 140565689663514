import { useState } from "react";
import classes from "./ProductDetailIndicator.module.css";
import DietaryIndicatorModal from "./DietaryIndicatorModal";

const ProductDetailIndicator = (props) => {
  const indicator = props.indicator;
  const [modalEnabled, setModalEnabled] = useState(false);
  let circleStyle = { background: indicator.color };

  const onModalDisable = (event) => {
    setModalEnabled(false);
  };

  return (
    <>
      {modalEnabled ? (
        <DietaryIndicatorModal
          indicatorKey={indicator?.key}
          onClose={onModalDisable}
        />
      ) : null}
      <button
        onClick={() => {
          setModalEnabled(true);
        }}
      >
        <div className={classes["menu-item-circle"]} style={circleStyle}>
          <span className={classes["menu-item-circle-span"]}>
            {indicator.key}
          </span>
        </div>
      </button>
    </>
  );
};

export default ProductDetailIndicator;
