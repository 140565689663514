import { Fragment } from "react";
import MenuListHeading from "./MenuListHeading";
import classes from "./MenuList.module.css";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const MenuList = (props) => {
  const catelog = props.catelog;
  const selectedCategory = props.selectedCategory;
  const productsInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );

  let catelogToShow = [];

  if (selectedCategory) {
    catelogToShow = catelog.hasOwnProperty(selectedCategory)
      ? catelog[selectedCategory]
      : {};
  }

  const isProductAddedInCart = (currPdtId) => {
    return productsInCart?.some((item) => item?.productId === currPdtId);
  };

  return (
    <Fragment>
      {catelogToShow.map((catelogItem) => {
        return (
          <section
            className={classes["menu-card-block"]}
            key={catelogItem.id}
            tabIndex={0}
            aria-label={catelogItem.name}
          >
            <MenuListHeading
              menuHeading={catelogItem.name}
              count={catelogItem.products.length}
            />
            <hr className={classes["menu-hr"]}></hr>
            <div className={classes["menu-list-parent"]}>
              <ul
                className={classes["menu-list-main"]}
                aria-label={selectedCategory}
                tabIndex={0}
              >
                {catelogItem.products.map((item) => {
                  if (item.category === "smoothies") {
                    if (item.name !== "SMOOTHIE AND FOOD")
                      return (
                        <MenuItem
                          key={item.id}
                          product={item}
                          onItemSelect={props.onProductSelect}
                          addedProduct={props.addedProduct}
                          isAdded={isProductAddedInCart(item?.id)}
                        />
                      );
                  } else {
                    return (
                      <MenuItem
                        key={item.id}
                        product={item}
                        onItemSelect={props.onProductSelect}
                        addedProduct={props.addedProduct}
                        isAdded={isProductAddedInCart(item?.id)}
                      />
                    );
                  }
                })}
              </ul>
            </div>
          </section>
        );
      })}
    </Fragment>
  );
};

export default MenuList;
