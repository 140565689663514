import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: { loader: { loaderVisible: false } },
  reducers: {
    setLoader(state, action) {
      state.loader = { loaderVisible: action.payload.loaderVisible }
    }
  },
});

export const loaderActions = loaderSlice.actions;

export default loaderSlice;