"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiException = exports.ServerException = exports.ValidationException = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../models/BaseModel");
/**
 * Class for validation exception
 */
var ValidationException = /** @class */ (function () {
    function ValidationException() {
    }
    return ValidationException;
}());
exports.ValidationException = ValidationException;
/**
 * Class for server exception
 */
var ServerException = /** @class */ (function () {
    /**
     * Constructor for ServerException
     * @param {HttpCode} httpCode
     */
    function ServerException(httpCode) {
        this.httpCode = httpCode;
    }
    return ServerException;
}());
exports.ServerException = ServerException;
/**
 * Class for api exception
 */
var ApiException = /** @class */ (function () {
    // eslint-disable-next-line valid-jsdoc
    /**
     * Constructor for InvalidDataExeption
     * @param {string} text
     * @param {StatusCode} statusCode
     * @param {string} statusText
     * @param {Map<string, {code: string; message: string}>} errors - key is the field that has error, values is the error code and message
     * @param {InvalidData} invalidData
     */
    function ApiException(statusCode, statusText, text, errors, invalidData) {
        this.errors = errors;
        this.statusCode = statusCode;
        this.text = text;
        this.statusText = statusText;
        this.invalidData = invalidData;
    }
    return ApiException;
}());
exports.ApiException = ApiException;
/**
 * Invalid data Model
 */
var InvalidData = /** @class */ (function (_super) {
    __extends(InvalidData, _super);
    function InvalidData() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Invalid class
     * @return {ObjectSchema}
     */
    InvalidData.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return InvalidData;
}(BaseModel_1.BaseModel));
