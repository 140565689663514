.cart-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #111111;
}
.cart-heading-final {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 20px;
}
.team-name {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 40px;
  margin-bottom: 15px;
}
.team-name-final {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cart-main {
  margin-left: 30px;
  padding-right: 1em;
  display: flex;
  height: 100%;
  /* max-height: calc(100vh - 30px); */
  flex-direction: column;
  justify-content: space-between;
}
.cart-hr {
  border: 1px solid #e6e7eb;
  margin-bottom: 30px;
  margin-top: 0;
}
.total-div {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 40px;
}
.total-label {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  color: #111111;
}
.total-cost {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  text-align: right;
  color: #111111;
}
.final-submit-btn {
  width: 100%;
}
.final-div {
  margin-top: -27px;
  display: flex;
  margin-bottom: 40px;
}
.products-main {
  height: auto;
  max-height: 19vh;
  overflow-y: auto;
}
.products-main-medium {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
}
.products-main-final {
  height: auto;
  max-height: 71vh;
  overflow-y: auto;
}
@media (min-width: 769px) {
}
@media (max-width: 960px) {
  .cart-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .cart-heading {
    margin-top: 80px;
  }
}
@media (max-width: 768px) {
  .products-main {
    height: auto;
    max-height: 45vh;
    overflow-y: auto;
  }
  .cart-main {
    justify-content: inherit;
  }
  .cart-heading {
    margin-top: 80px;
  }
}
@media (max-width: 393px) {
  .cart-heading {
    margin-top: 120px;
  }
}
