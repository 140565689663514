import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cartActions } from "../../store/cart-slice";
import classes from "./TypeImageCard.module.css";

const TypeImageCard = (props) => {
  const { option, type, isSelectedSmoothie, selectedSmoothieOption, optionGroupData, cardClick, updateSeletedSmoothieProducts, selectedOptions } = props;
  const [secondaryText, setSecondaryText] = useState("");
  let cardStyle = { backgroundColor: "#E5F2C2" };
  let image = require("../../assets/noimage.png");
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [isNutrientSelected, setNutrientSelected] = useState(false);
  const [selectedExtraItems, setSelectedExtraItems] = useState([]);
  let [shouldCSSApply, setShouldCSSApply] = useState(false);
  
  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
  );
  const optionGroupsList = useSelector(
    (state: RootState) => state.productDetail.optionGroups
  );

  const isSingleSelectionReqd = () => {
    
    if (optionGroupData?.description === "Choose Your Side" || optionGroupData?.description === "Choice of Protein" || optionGroupData?.description === "Choose Peanut Butter or Strawberries") {
      return true;
    }
    const pdtCountList = optionGroupsList?.filter((item) => {
      return item?.description?.includes("Choose") ? item : null;
    });
    return pdtCountList?.length > 0
  };
  
  let isAvailable = (option) => {
    let res = currentAddedModifiers?.some((item) => {
      return item === option.id;
    });
    return res
  };
  useEffect(()=>{
    if(currentAddedModifiers.length > 0 && props.isEdit){
      isSingleSelectionReqd();
      isAvailable(option);
      setSelectedExtraItems(currentAddedModifiers)
    }
  },[currentAddedModifiers])
  if (option && option.metadata && option.metadata.length) {
    for (let metaDt of option.metadata) {
      if (metaDt.key == "image") {
        image = metaDt.value;
      } else if (metaDt.key == "colorcode") {
        cardStyle.backgroundColor = metaDt.value;
      }
    }
  }
  const fetchCategoryTitle = (option) => {
    let isChooseYourSideItem;
    if (!!option?.children && !!option?.cost) {
      return "Product";
    } else {
      for (let i = 0; i < optionGroupsList?.length; i++) {
        if(optionGroupsList[i]?.description === "Choose Your Side" || optionGroupsList[i]?.description === "Choice of Protein" 
        || optionGroupsList[i]?.description === "Choose Peanut Butter or Strawberries") {
          isChooseYourSideItem = optionGroupsList[i]?.options?.some((item) => {
            return item?.id === option?.id;
          });
        }
      }
      let isSideItem;
      if (!isChooseYourSideItem) {
        for (let i = 0; i < optionGroupsList?.length; i++) {
          for (let k = 0; k < optionGroupsList[i]?.options?.length; k++) {
            for (let m = 0; m < optionGroupsList[i]?.options[k]?.modifiers?.length; m++) {
              
              if(optionGroupsList[i]?.options[k]?.modifiers[m]?.description === "Choose Your Side") {
                isChooseYourSideItem = optionGroupsList[i]?.options[k]?.modifiers[m].options?.some((item) => {
                  return item?.id === option?.id;
                });

                if (!!isChooseYourSideItem) {
                  isSideItem = isChooseYourSideItem;
                }
              }
            }
          }
        }
      }
      return !!isChooseYourSideItem || !!isSideItem ? "SideCategory" : "Others";
    }
  };

  const toggleSelection = (groupData, option, id) => {

    const isSingleSelection = isSingleSelectionReqd();

    let categoryName = fetchCategoryTitle(option);
    if (categoryName === "Product") {
      if (groupData.description == "Choose Smoothie") {
        let isProductAvailable_cart = currentAddedModifiers.includes(option.id);
        if (isProductAvailable_cart) {
          cardClick(option,isSingleSelection);
          updateSeletedSmoothieProducts(false, '');
        } else {
          updateSeletedSmoothieProducts(true, groupData.description, option)
        }
      }
      if (currentAddedModifiers?.length > 0) {
        let currentCategoryId = optionGroupsList?.filter((item) =>
          item?.options?.some((v) => v?.id === option?.id) ? item : null
        )[0]?.id;

        let categoryIdListForModifiers = currentAddedModifiers;
        categoryIdListForModifiers = categoryIdListForModifiers.map((mod) => {
          return optionGroupsList?.filter((item) =>
            item?.options?.some((v) => v?.id === mod) ? item : null
          )[0]?.id;
        });

        let currentAddedModifierCategoryIndex =
          categoryIdListForModifiers?.indexOf(currentCategoryId);

        if (currentAddedModifierCategoryIndex != -1) {
          let optionGroupToBeReduced = optionGroupsList?.filter((item) => {
            return (
              item?.id ===
              categoryIdListForModifiers[currentAddedModifierCategoryIndex]
            );
          });

          let costTobeReduced = optionGroupToBeReduced[0]?.options?.filter(
            (item) => {
              return (
                item?.id ===
                currentAddedModifiers[currentAddedModifierCategoryIndex]
              );
            }
          );

          if (costTobeReduced?.length > 0) {
            cardClick(option,isSingleSelection);
              dispatch(
                cartActions.removeItemCost({
                  cost: costTobeReduced[0].cost,
                })
              );
              dispatch(
                cartActions.removeModifiers({
                  modifiers:
                    currentAddedModifiers[currentAddedModifierCategoryIndex],
                })
              );
          }

          let isProductAvailable_cart = currentAddedModifiers.includes(option.id);
          if (!isProductAvailable_cart) {
            cardClick(option,isSingleSelection);
            dispatch(cartActions.addItemCost({ cost: option.cost }));
            dispatch(cartActions.addModifiers({ modifiers: option.id }));
          }
        } else {
          cardClick(option,isSingleSelection);
          dispatch(cartActions.addItemCost({ cost: option.cost }));
          dispatch(cartActions.addModifiers({ modifiers: option.id }));
        }
      } else {
        cardClick(option,isSingleSelection);
        dispatch(cartActions.addItemCost({ cost: option.cost }));
        dispatch(cartActions.addModifiers({ modifiers: option.id }));
      }
    } else if (categoryName === "SideCategory") {
      let isSideItemExist = false;
      let sideCategoryItemData;

      if (currentAddedModifiers?.length > 0) {
        currentAddedModifiers.forEach((eachCartProdId) => {
          optionGroupData?.options.forEach((eachOption) => {
            if (eachOption.id === eachCartProdId) {
              sideCategoryItemData = eachOption;
              isSideItemExist = true;
              return;
            }
          });
        });
        if (!!isSideItemExist && Object.keys(sideCategoryItemData).length > 0) {
          cardClick(option,isSingleSelection);
          dispatch(
            cartActions.removeItemCost({ cost: sideCategoryItemData.cost })
          );
          dispatch(
            cartActions.removeModifiers({
              modifiers: sideCategoryItemData.id,
            })
          );
          let isProductAvailable_cart = currentAddedModifiers.includes(option.id);
          if (!isProductAvailable_cart) {
            cardClick(option,isSingleSelection);
            dispatch(cartActions.addItemCost({ cost: option.cost }));
            dispatch(cartActions.addModifiers({ modifiers: option.id }));
          }
        } else {
          cardClick(option,isSingleSelection);
            dispatch(cartActions.addItemCost({ cost: option.cost }));
            dispatch(cartActions.addModifiers({ modifiers: option.id }));
        }
      } else {
        cardClick(option,isSingleSelection);
        dispatch(cartActions.addItemCost({ cost: option.cost }));
        dispatch(cartActions.addModifiers({ modifiers: option.id }));
      }
    } else if (categoryName === "Others") {
      if (id != null) {
        if (option?.modifiers?.length > 0) {
          let extraItemAdded = option?.modifiers[0]?.options?.filter(
            (item) => item.id === id
          );
          let isExtraItemExist = selectedExtraItems.includes(id);
          if (isExtraItemExist) {
            dispatch(cartActions.removeItemCost({ cost: extraItemAdded[0].cost }));
            dispatch(cartActions.removeModifiers({ modifiers: extraItemAdded[0].id }));
            let filteredItems = selectedExtraItems?.filter(
              (item) => item !== id
            );
            setSelectedExtraItems([...filteredItems]);
          } else {
            dispatch(cartActions.addItemCost({ cost: extraItemAdded[0].cost }));
            dispatch(
              cartActions.addModifiers({ modifiers: extraItemAdded[0].id })
            );
            dispatch(
              cartActions.addModifiers({ modifiers: option?.id })
            );
            setSelectedExtraItems([...selectedExtraItems, id]);
          }
        }
      } else {
        if (currentAddedModifiers?.length > 0) {
          let extraExist = currentAddedModifiers?.some(
            (item) => item === option?.id
          );
          if (extraExist) {
            cardClick(option, isSingleSelection);
            dispatch(cartActions.removeItemCost({ cost: option.cost }));
            dispatch(cartActions.removeModifiers({ modifiers: option.id }));
          } else {
            cardClick(option, isSingleSelection);
            dispatch(cartActions.addItemCost({ cost: option.cost }));
            dispatch(cartActions.addModifiers({ modifiers: option.id }));
          }
        } else {
          cardClick(option, isSingleSelection);
          dispatch(cartActions.addItemCost({ cost: option.cost }));
          dispatch(cartActions.addModifiers({ modifiers: option.id }));
        }
      }
    }
  };

  useEffect(() => {
    if (option.metadata && option.metadata.length) {
      for (let element of option.metadata) {
        if (element.key == "text") {
          if (element.value) {
            setSecondaryText(element.value);
          }
        }
      }
    }
  }, [option]);

  const onExpandAccordianHandler = () => {
    setNutrientSelected(!isNutrientSelected);
  };

  useEffect(() => {
    setSelectedExtraItems(currentAddedModifiers)
  }, [currentAddedModifiers])

  const isExtraItemExisiting = (currId) => {
    let isItemExist = currentAddedModifiers.includes(currId);
    if (isItemExist) {
      return true;
    } else {
      return false;
    }
  };

  const handleViewAll = () => {
    let isProductAvailable_cart = currentAddedModifiers.includes(option.id);
    if (isProductAvailable_cart) {
      cardClick(option);
        dispatch(cartActions.removeItemCost({ cost: option.cost, }));
        dispatch(cartActions.removeModifiers({ modifiers: option.id }));
        
      updateSeletedSmoothieProducts(false, '');
    }
  }
  
  const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.id);

  useEffect(()=>{
    if(isSelected) setNutrientSelected(true) 
    else setNutrientSelected(false)
  },[isSelected])
  
  return (
    <>
      <div>
        {type === "expand" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes["extras-layout"]}>
              <button
                className={classes["btn-card-expand"]}
                onClick={() => onExpandAccordianHandler()}
              >
                <div>
                  <div className={classes["imagecard-name-extras"]}>{option.name}</div>
                  <div className={classes["imagecard-second-name"]}>
                    {option.secondaryText}
                  </div>
                </div>
                <span onClick={onExpandAccordianHandler}>
                  {isNutrientSelected ? (
                    <div className={classes["imagecard-Icons-extras"]}>-</div>
                  ) : (
                    <div className={classes["imagecard-Icons-extras"]}>+</div>
                  )}
                </span>
              </button>
            </div>
            {isNutrientSelected ? (
              <div className={classes["imagecard-main"]}>
                {option?.modifiers[0]?.options?.length > 0
                  ? option?.modifiers[0]?.options?.map((value, index) => {
                    return (
                      <button
                        key={value.id}
                        className={
                          isExtraItemExisiting(value.id)
                            ? classes["btn-card"] +
                            " " +
                            classes["selected-card"]
                            : classes["btn-card"]
                        }
                        tabIndex={index}
                        onClick={() => toggleSelection(optionGroupData, option, value.id)}
                      >
                        <span className={classes["span-main"]}>
                          <i
                            className={
                              isExtraItemExisiting(value.id)
                                ? classes["bi-dash-circle-fill"]
                                : ""
                            }
                          ></i>
                        </span>
                        <div style={{ height: "100px" }}>
                          {!!value?.metadata && value?.metadata?.length > 0
                            ? value?.metadata?.map((item, i) => {
                              return item?.key === "image" ? (
                                <img
                                  src={item?.value}
                                  alt={value?.name}
                                  className={classes["imagecard-image"]}
                                  key={i}
                                />
                              ) : null;
                            })
                            : null}
                        </div>
                        <div className={classes["imagecard-name"]}>
                          {value.name}
                        </div>
                        {value.basecalories ? (
                          <div className={classes["imagecard-calories"]}>
                            {value.basecalories} Calories
                          </div>
                        ) : null}
                        {value.cost ? (
                          <div className={classes["imagecard-cost"]}>
                            + ${value.cost.toFixed(2)}
                          </div>
                        ) : null}
                      </button>
                    );
                  })
                  : null}
              </div>
            ) : null}
            <div className={classes["hr-layout"]}>
              <div className={classes["hrLine"]}></div>
            </div>
          </div>
        ) : (
          <button
            className={`${classes["btn-card"]} ${(isSingleSelectionReqd() ? isAvailable(option) : isSelected) && classes["selected-card"]}`}
            onClick={() => toggleSelection(optionGroupData, option, null)}
            tabIndex={0}
          >
            <span className={classes["span-main"]}>
              <i
                className={
                  ((isSingleSelectionReqd()) ? isAvailable(option) : isSelected)
                    ? classes["bi-dash-circle-fill"]
                    : ""
                }
              ></i>
            </span>
            <div style={{ height: "100px" }}>
              <img
                className={classes["imagecard-image"]}
                src={image}
                alt={option.name}
              />
            </div>

            <div className={classes["imagecard-name"]}>{option.name}</div>

            <div className={classes["imagecard-second-name"]}>
              {secondaryText}
            </div>
            {option.basecalories ? (
              <div className={classes["imagecard-calories"]}>
                {option.basecalories} Calories
              </div>
            ) : null}
            {option.cost ? (
              <div className={classes["imagecard-cost"]}>
                + ${option.cost.toFixed(2)}
              </div>
            ) : null}
          </button>
        )}
      </div>
      {
        isSelectedSmoothie && selectedSmoothieOption ?
          <button onClick={handleViewAll} className={classes['view-all-btn']}>
            VIEW ALL
          </button>
          : null
      }
    </>
  );
};

export default TypeImageCard;
