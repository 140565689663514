import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import HighLightBanner from "./HighlightBanner";
import classes from "./ProductDescription.module.css";
import ProductDetailIndicator from "./ProductDetailIndicator";

const ProductDescription = (props) => {
  const product = props.product;
  const dispatch = useDispatch();
  let indicators = [];

  useEffect(() => {
    dispatch(cartActions.resetCost({ cost: product.cost }));
  }, []);

  if (product.metadata && product.metadata.length) {
    for (let element of props.product.metadata) {
      if (element.key == "indicator") {
        if (element.images) {
          let data = JSON.parse(element.images);
          if (data && data.length) {
            indicators = data;
          }
        }
      }
    }
  }

  return (
    <Fragment>
      <HighLightBanner 
        data={product?.metadata} 
        from={"product"}
        />
      <div className={classes["detail-row"]}>
        <button className={classes["product-name"]}>{product.name}</button>
        {product.cost ? (
          <h4 className={classes["product-cost"]}>
            ${(typeof product.cost === 'string') ? 
              parseFloat(product.cost.slice(1)) :
              product.cost.toFixed(2)
            }
          </h4>
        ) : null}
      </div>
      {product.basecalories ? (
        <h4 className={classes["product-weight"]}>
          {product.basecalories} calories
        </h4>
      ) : null}
      <p className={classes["product-description"]}>{product.description}</p>
      <div className={classes["indicator-div-nutrition"]}>
        {indicators && indicators.length
          ? indicators.map((indicator) => {
              return (
                <ProductDetailIndicator
                  key={indicator.key}
                  indicator={indicator}
                />
              );
            })
          : null}
      </div>
    </Fragment>
  );
};

export default ProductDescription;
