.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 690px;
  background: #ffffff;
  border-radius: 10px;
  align-self: center;
  margin: 0;
  padding: 0 80px;
}
.hurray {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #1d232e;
  margin: 10px 0px;
}
.submit-h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  color: #111111;
  margin: 10px 0px;
}
.submit-p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #1d232e;
  margin: 20px 0px;
}
.submit-button {
  align-items: center;
  padding: 16px 50px;
  width: 100%;
  height: 46px;
  background: #82cf00;
  border-radius: 40px;
  margin: 0px;
  display: block;
}
.btn-main {
  width: 100%;
}
.app-store {
  width: 100%;
}
.google-play {
  width: 100%;
}
.apk-main {
  margin-top: 45px;
  margin-bottom: 10px;
  display: none;
  padding-right: 0.4em;
}

.cta-container {
  width: 50%;
}
.scan-text {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #1d232e;
  margin: 0px 40px;
}
.scan-div {
  display: flex;
  margin-bottom: 15px;
}
.scan-image {
  height: 70px;
  margin-left: 40px;
  display: flex;
  align-self: center;
}
.sup-text {
  top: -5px !important;
  font-size: 14px !important;
}
@media (max-width: 960px) {
  .card {
    padding: 0px 40px;
  }
}
@media (max-width: 768px) {
  .main {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .scan-text {
    margin: 0px 10px;
    font-size: 20px;
  }
  .card {
    padding: 0 20px;
  }
  .scan-image {
    margin-left: 10px;
  }
  .submit-h2 {
    font-size: 38px;
  }
  .apk-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .scan-div {
    display: none;
  }
}
