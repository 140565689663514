import { createSlice } from '@reduxjs/toolkit';

const catelogSlice = createSlice({
  name: 'catelog',
  initialState: {
    mainCategories: [],
    categories: [],
    imagePath: '',
    totalCategories: 0,
    catelog: {},
    errorMessage: ''
  },
  reducers: {
    getAllCategories(state, action) {
      state.categories = action.payload.categories;
      state.totalCategories = action.payload.categories.length;
      state.imagePath = action.payload.imagePath;
      let catelog: any = {}
      const categories = state.categories;
      categories.forEach((category: { metadata: any[]; id: any; name: any; products: any; }, index: number) => {
        if (category.metadata && category.metadata.length) {
          for (let mainCategory of category.metadata) {
            if (mainCategory.key === 'main-category') {
              if (mainCategory.images && !catelog.hasOwnProperty(mainCategory.images)) {
                catelog[mainCategory.images] = []
              }
              let products = category.products;
              for(let product of products) {
                product.category = mainCategory.images;
              }
              catelog[mainCategory.images].push({
                id: category.id,
                name: category.name,
                products: products
              })
              break;
            }
          }
        }
        if (index == categories.length - 1) {
          state.mainCategories = Object.keys(catelog);
        }
      });
      state.catelog = { ...catelog }
    },
    showError(state, action) {
      state.errorMessage = action.payload.errorMessage;
    }
  },
});

export const catelogActions = catelogSlice.actions;

export default catelogSlice;