import { createSlice } from '@reduxjs/toolkit';

const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState: {
    optionGroups: [],
  },
  reducers: {
    getAllOptions(state, action) {
      state.optionGroups = action.payload.optionGroups
    }
  },
});

export const productDetailActions = productDetailSlice.actions;

export default productDetailSlice;