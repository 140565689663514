.cart-main {
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    height: calc(100vh - 60px);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 0px 0px 0px 30px;
}
.cart-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
}
.cart-invited {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #1D232E;
    flex: none;
    margin: 20px 0px;
}
.cart-join {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.3px;
    color: #1D232E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
}
.cart-hr {
    height: 0px;
    border: 1px solid #E6E7EB;
    margin-bottom: 30px;
    margin-top: 0;
}
.cart-go-ahead {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1D232E;
}
@media(max-width: 768px) {
    .cart-main {
        display: none;
    }
}