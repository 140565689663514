import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { firebaseConfig, PROD_PATH, STAGE_PATH, TRANSLATION_PATH } from "../../constants/constants";
import { loaderActions } from "../../store/loader-slice";
import TSCButton from "../UI/Button";
import classes from "./FinalSubmit.module.css";

const FinalSubmit = () => {
  let googlePlay = require("../../assets/googlePlayHD.png");
  let appStore = require("../../assets/appstoreHD.png");
  let qrCode = require("../../assets/qrCodeNew.jpeg");
  let submit = require("../../assets/submit.png");
  const [backgroundUrl, setBackgroundUrl] = useState('')
  const dispatch = useDispatch();
  const signUpClicked = () => {
    window.open("https://www.tropicalsmoothiecafe.com/rewards/", "_blank"); //to open new page
  };

  useEffect(() => {
    if (!!document.getElementById("final-submit-container")) {
      document
        .getElementById("final-submit-container")
        .scrollIntoView({ behavior: "smooth" });
    }
    AF('banners', 'showBanner', { bannerZIndex: 1000, additionalParams: { p1: "v1", p2: "v2"}});
  }, []);
  useEffect(async () => {
    
    const app = initializeApp(firebaseConfig);
    const firebaseDB = getDatabase(app);
    const query = ref(firebaseDB, process.env.REACT_FIREBASE_PATH);
    let firebaseData = null;
    dispatch(
      loaderActions.setLoader({
          loaderVisible: true
      })
  );
    await onValue(query, (snapshot) => {
      firebaseData = snapshot.val();
      setBackgroundUrl(firebaseData.web_confimration_background)
      dispatch(
        loaderActions.setLoader({
            loaderVisible: false
        })
    );
    });
  }, []);
  return (
    <div style={{ backgroundImage: `url(${backgroundUrl})` }} className={classes["main"]} id="final-submit-container">
      <div className={classes["card"]}>
        <div>
          <p className={classes["hurray"]}>Hurray!</p>
          <h2 className={classes["submit-h2"]} tabIndex={0}>
            Your order has been submitted{" "}
          </h2>
          <p className={classes["submit-p"]}>
            Create your own Tropic Rewards
            <sup className={classes["sup-text"]}>®</sup> account to start
            earning points on your next order!
          </p>
        </div>
        <div className={classes["btn-main"]}>
          <TSCButton
            className={classes["submit-button"]}
            onButtonClick={signUpClicked}
          >
            JOIN TROPIC REWARDS<sup className={classes["sup-text"]}>®</sup>
          </TSCButton>
        </div>
        <div className={classes["scan-div"]}>
          <img className={classes["scan-image"]} src={qrCode} alt="" />
          <p className={classes["scan-text"]}>
            Scan to download our app and order on your phone next time.
          </p>
        </div>
        <div className={classes["apk-main"]}>
          <a
            href="https://apps.apple.com/us/app/tropical-smoothie-cafe/id1470030691"
            target="_blank"
            className={classes["cta-container"]}
          >
            <img className={classes["app-store"]} src={appStore} alt="" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.tropicalsmoothie.tropicalsmoothiecafe.android.app&hl=en_IN&gl=US"
            target="_blank"
            className={classes["cta-container"]}
          >
            <img className={classes["google-play"]} src={googlePlay} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FinalSubmit;
