"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addToBasket = void 0;
var Constants_1 = require("../../Constants");
var Cart_1 = require("../../models/response/Cart");
var utils_1 = require("../../utils");
var url = Constants_1.URL.ADD_TO_BASKET;
/**
 * @param {AddProductToBasketRequest} addProductRequest
 * @param {int} storeId - store id
 * @param {string} basketId - basket id
 * @return {string} - basket id
 * Api to add product to basket by id
 */
function addToBasket(addProductRequest, storeId, basketId) {
    return utils_1.post(url(storeId ? storeId : 0, basketId ? basketId : ''), addProductRequest, undefined, undefined, function (data, headers) {
        return JSON.stringify(data);
    }).then(function (res) {
        return new Cart_1.Basket(res.data);
    });
}
exports.addToBasket = addToBasket;
