"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIME_TYPE = exports.GOOGLE_URL = exports.URL = void 0;
/* eslint-disable */
exports.URL = {
    FORGOT_PASSWORD: '/profile/auth/forgot_password',
    USER: '/profile/auth/user',
    USER_RELATION: '/profile/auth/user_relations',
    LOGIN: '/profile/auth/login',
    LOGOUT: '/profile/auth/logout',
    BARCODE: '/profile/auth/checkin_using_barcode',
    FACEBOOK_CONNECT: '/profile/auth/connect_with_facebook',
    APPLE_REGISTRATION: '/profile/auth/apple_registrations',
    FETCH_USER_BALANCE: '/profile/auth/fetch_user_balance',
    USER_DELIVERY_ADDRESS_REMOVE: function (addressId) {
        return "/order/userdeliveryaddresses/" + addressId;
    },
    USER_VEHICLE_INFO_REMOVE: function () { return "/profile/auth/delete/vehicledetails"; },
    MAKE_DEFAULT_DELIVERY_ADDRESS: function (addressId) {
        return "/order/userdeliveryaddresses/" + addressId + "/default";
    },
    METADATA: '/order/common/meta.json',
    CREATE_BASKET: function (storeid) { return "/order/baskets/" + storeid + "/basket"; },
    GET_BASKET: function (basketId) { return "/order/baskets/" + basketId; },
    ADD_TIP: function (basketId) { return "/order/baskets/" + basketId + "/tip"; },
    LOYALTY_SCHEMES: function (basketId) {
        return "/order/baskets/" + basketId + "/loyaltyschemes";
    },
    REWARDS: function (basketId) { return "/order/baskets/" + basketId + "/rewards"; },
    GETUPSELL: function (basketId) { return "/order/baskets/" + basketId + "/upsell"; },
    REWARD: function (basketId, rewardId) {
        return "/order/baskets/" + basketId + "/rewards/" + rewardId;
    },
    BASKET_PRODUCT: function (basketId, productId) {
        return "/order/baskets/" + basketId + "/products/" + productId;
    },
    ADD_TO_BASKET: function (storeid, basketid) {
        return "/order/baskets/products?basketId=" + basketid + "&storeNumber=" + storeid;
    },
    CAFE: '/cafe',
    CMS_PAGE: function (pageName) { return "/cms/pages/" + pageName; },
    CMS_COLLECTION: function (collectionName) {
        return "/cms/collection/" + collectionName;
    },
    DELETE_HOME_CARD: function (messageId) { return "/cms/messages/" + messageId; },
    FAV_LOCATIONS: function (storeid) {
        return "/profile/user/" + storeid + "/favourite_locations";
    },
    MENU: function (storeid) { return "/menu/restaurants/" + storeid + "/menu"; },
    MENU_PRODUCT_MODIFIERS: function (productId) {
        return "/menu/restaurants/" + productId + "/productModifiers";
    },
    DELIVERY_MODE: function (basketid) {
        return "/order/baskets/" + basketid + "/deliverymode";
    },
    TIME: function (basketid) { return "/order/baskets/" + basketid + "/timewanted"; },
    DELIVERY_ADDRESS: function (basketId) {
        return "/order/baskets/" + basketId + "/dispatchaddress";
    },
    PAYMENT_CARD: '/payment/cards',
    PAYMENT_CARD_ACCOUNT: '/payment/cards/all',
    PAYMENT_MAKE_CARD_DEFAULT: '/payment/makeDefault',
    FAV_ORDERS: 'profile/user/favourite_orders',
    RECENT_ORDERS: 'order/recentorders',
    FAV_BASKETS: 'order/baskets/favourites',
    VALIDATE_BASKET: function (basketId) { return "/order/baskets/" + basketId + "/validate"; },
    TRANSFER_BASKET: function (basketId) { return "/order/baskets/" + basketId + "/transfer"; },
    FAVOURITE_PRODUCT: '/profile/user/favouriteproduct',
    SUBMIT_PAYMENT: function (basketid, vendorId) {
        return "/payment/submit?basketId=" + basketid + "&vendorId=" + vendorId;
    },
    CUSTOMFIELDS: function (basketId) { return "/order/baskets/" + basketId + "/customfields"; },
    APPLYPROMO: function (basketId) { return "/order/baskets/" + basketId + "/coupon"; },
    QRCODE_USING_REWARD_ID: '/profile/auth/redemptions/reward',
    QRCODE_USING_REDEEMABLE_ID: '/profile/auth/redemptions/redeemable',
    REWARDSAPPLYPROMO: 'profile/auth/coupons',
    REMOVEPROMO: function (basketId) { return "/order/baskets/" + basketId + "/coupon"; },
    DONATIONS: function (basketId) { return "/order/baskets/" + basketId + "/donations"; },
    VEHICLES: '/profile/auth/vehicledetails',
    USER_DELIVERY_ADDRESS: '/order/userdeliveryaddresses',
    RECENT_ORDER_BASKET: '/order/baskets/createbasketfromorder',
    FAV_ORDER_BASKET: '/order/baskets/createbasketfromfave',
    ACCOUNT_HISTORY: 'profile/auth/accounthistory',
    ORDER_CONFIRMATION: function (refId) { return "/order/" + refId; },
    CANCEL_ORDER: function (orderId) { return "/order/" + orderId + "/cancel"; },
    GROUP_ORDERS: 'order/grouporders',
    GROUP_ORDERS_FETCH: function (groupOrderId) {
        return "/order/grouporders/" + groupOrderId;
    },
    GUEST: 'profile/common/eclub_guests',
    PAYPAL_TOKEN: 'payment/paypalToken',
    UN_FAVORITE_PRODUCT: function (basketId) {
        return "/profile/user/favourite_orders/" + basketId;
    },
    GET_MERCHANT_DETAIL: function (vendorId) { return "payment/" + vendorId + "/merchants"; },
    SUBMIT_ORDER: function (basketId) { return "/order/baskets/" + basketId + "/submit"; },
    SUBMIT_ORDER_WITHOUT_PAYMENT: function (basketId, vendorId) {
        return "payment/submit?basketId=" + basketId + "&vendorId=" + vendorId;
    },
    RETRIVE_BILLING_ACCOUNTS: function (basketId) {
        return "/payment/billingaccounts?basketId=" + basketId;
    },
    DELETE_BILLING_ACCOUNT: function (authtoken, billingAccId) {
        return "/users/" + authtoken + "/billingaccounts/" + billingAccId;
    },
    HEALTH_CHECK: function (serviceName) { return "/" + serviceName + "/actuator/health"; },
    MESSAGES: '/cms/notification/markasread',
};
exports.GOOGLE_URL = {
    AUTO_COMPLETE_PLACE: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
    SEARCH: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
    DETAILS: 'https://maps.googleapis.com/maps/api/place/details/json',
};
exports.MIME_TYPE = {
    APPLICATION_JSON: 'application/json',
};
/* eslint-enable */
