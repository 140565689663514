import React from 'react';
import ReactDOM from 'react-dom';
import Card from '../UI/Card';
import classes from './NameModal.module.css';
import CloseButton from 'react-bootstrap/CloseButton'
import CartName from './CartName';

const Backdrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            <CloseButton className={classes['close-button']} onClick={props.onClose} />
            <CartName onSave={props.onClose} onNameSave={props.onNameSave} />
        </Card>
    );
};

const NameModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop />,
                document.getElementById('backdrop-modal')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay onClose={props.onClose} onNameSave={props.onNameSave} />,
                document.getElementById('overlay-modal')
            )}
        </React.Fragment>
    );
};

export default NameModal;