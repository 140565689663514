import classes from './Card.module.css';

const Card = (props: { children: any, className: string }) => {
  return (
    <div className={`${classes.card} ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  );
};

export default Card;
