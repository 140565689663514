.product-details {
  margin: 40px 30px 60px 30px;
}
@media (max-width: 470px) {
  .product-details {
    margin: 40px 0px 60px 0px;
  }
  .product-description {
    margin: 0 25px;
  }
}
.product-disclaimer {
  display: none;
}
@media only screen and (min-width: 900px) {
  .product-disclaimer {
    display: block;
    margin: 0 0 15px 1.5em;
  }
}

.nutrition-main {
  border: 2px solid #82cf00;
  background: white;
  box-shadow: -8px 8px 30px rgb(0 0 0 / 10%);
  border-radius: 50px;
  width: 100%;
  margin: 0;
  max-width: 600px;
  cursor: pointer;
  margin-bottom: 20px;
}

@media (max-width: 414px) {
  .nutrition-main {
    width: 95%;
    margin: 1.5em 0.5em 1.5em 0.5em;
  }
}

.nutrition-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
}
