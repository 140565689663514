import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cartActions } from "../../store/cart-slice";
import { productDetailActions } from "../../store/product-details-slice";
import { removeCartData } from "../../store/remove-cart-actions";
import ErrorModal from "../UI/ErrorModal";

import classes from "./AddToCart.module.css";
import NameModal from "./NameModal";
import _ from 'lodash';

const AddToCart = (props) => {
  const dispatch = useDispatch();
  const cost : any = useSelector((state: RootState) => state.cart.cost);
  const [showError, setShowError] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(false);
  const [name, setName] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [isAddCartClicked, setIsAddCartClicked] = useState(false);
  const [errorMessageNew, setErrorMessageNew] = useState('');

  const optionGroupsList = useSelector(
    (state: RootState) => state.productDetail.optionGroups
  );
  const currentAddedModifiers = useSelector(
    (state: RootState) => state.cart.modifiers
  );
  const minSelectErrors = useSelector(
    (state: RootState) => state.cart.minSelectErrors
  );
  const cartErrorMessage = useSelector(
    (state: RootState) => state.cart.errorMessage
  );

  useEffect(() => {
    setName(localStorage.getItem("name"));
  }, []);

  useEffect(()=>{
    if(cartErrorMessage && isAddCartClicked){
      setShowError(true);
      setErrorMessageNew(cartErrorMessage);
    }
  },[cartErrorMessage])

  const onModalEnable = (event) => {
    setModalEnabled(true);
  };
  const onModalDisable = (event) => {
    setModalEnabled(false);
  };
  const onErrorModalClose = (event) => {
    setShowError(false);
    setErrorMessageNew((prevState) => "");
    // setErrorMessage((prevState) => "");
    setIsAddCartClicked(false);
  };
  const onCartClick = (event) => {
    setIsAddCartClicked(true);
    let error = "";
    // if (minSelectErrors.length) {
    //   for (let i = 0; i < minSelectErrors.length; i++) {
    //     if (minSelectErrors[i].errorMessage) {
    //       setErrorMessage((prevState) => minSelectErrors[i].errorMessage);
    //       error = minSelectErrors[i].errorMessage;
    //       break;
    //     }
    //   }
    // }
    if(!checkOptions()){
      return null;
    }
    if (error) {
      setShowError(true);
    } 
    else{
      const localName = localStorage.getItem("name");
      setName(localName);
      if (localName) {
        props.addToCart();
        onModalDisable(event);
      } else {
        onModalEnable(event);
      }
    }
    if(props.isEdit && !error){
      dispatch(
        productDetailActions.getAllOptions({
          optionGroups: [],
        })
      );
      dispatch(cartActions.resetCost({ cost: 0 }));
      dispatch(cartActions.resetMinSelectErrors({ minSelectErrors: 0 }));
      dispatch(cartActions.resetModifiers({}));
      dispatch(
        cartActions.showError({
          errorMessage: "",
        })
      );
    }
  };

  const checkOptions = () => {
    let mandatoryOptionMissing: string | undefined;
    let mandatoryOptionExceeded: boolean | undefined;
    let mandatoryOptionNotSelected: boolean | undefined;
    let minOptions: number = 0;
    let maxOptions: number = 0;
    let testFields: any = [];
    let mandatoryCheck = (optionGroupsList) => optionGroupsList.forEach(og => {
      if(og.name !== "Choose Your Substitution" && og.description !== "Customize Your Smoothie"){
        if (og.mandatory) {
          testFields.push(og.description)
        }
        if (!_.isEmpty(mandatoryOptionMissing)) {
          return null;
        }
        else {
          if (og.mandatory || !_.isEmpty(og.minselects) || !_.isEmpty(og.maxselects)) {
            let selectedOptionsNew = _.intersectionWith(
              og.options?.map((o) => o.id),
              currentAddedModifiers.map((om) => { 
                return parseInt(om);
              }), 
            );
            mandatoryOptionNotSelected = selectedOptionsNew.length < (og.minselects ?? Math.max(1, selectedOptionsNew.length));
            mandatoryOptionExceeded = selectedOptionsNew.length > (og.maxselects ?? Math.min(1, selectedOptionsNew.length));
            if (mandatoryOptionNotSelected || mandatoryOptionExceeded) {
              mandatoryOptionMissing = og.description;
              minOptions = parseInt(og.minselects ?? '1');
              maxOptions = Math.max(minOptions, parseInt(og.maxselects ?? '1'));
              if (mandatoryOptionExceeded) {
                minOptions = 0;
              }
            }
          }
          let optedOptions = currentAddedModifiers.map(om => parseInt(om));
          og.options?.map(o => _.intersection([o.id ?? 0, o.chainoptionid ?? 0], optedOptions).length > 0 ? mandatoryCheck(o.modifiers ?? []) : null)
        }
      }
    });
    
    mandatoryCheck(optionGroupsList ?? []);
    if (!_.isEmpty(mandatoryOptionMissing)) {
      setErrorMessageNew(`Please make a selection for ${mandatoryOptionMissing}`);
    } else {
      return true
    }   
    return false
  }
  
  return (
    <div>
      {showError || errorMessageNew ? (
        <ErrorModal onClose={onErrorModalClose} message={errorMessageNew} />
      ) : null}
      {modalEnabled && !errorMessageNew ? (
        <NameModal onClose={onModalDisable} onNameSave={onCartClick} />
      ) : null}
      {
        props.isEdit ? 
        <button className={classes["cart-main"]} onClick={onCartClick}>
          <div className={classes["cart-text"]}>Update Cart</div>
          <div className={classes["cart-text"]}>${cost?.toFixed(2)}</div>
        </button> :
        <button className={classes["cart-main"]} onClick={onCartClick}>
          <div className={classes["cart-text"]}>Add to Cart</div>
          <div className={classes["cart-text"]}>
            ${(typeof cost === 'string') ? 
              parseFloat(cost.slice(1))
              : cost.toFixed(2)
            }</div>
        </button>
      
      }
    </div>
  );
};

export default AddToCart;
