import classes from "./OptionsImageCard.module.css";
import OptionGroupHeading from "./OptionGroupHeading";
import TypeImageCard from "./TypeImageCard";
import { useEffect, useRef, useState } from "react";
import OptionGroups from "./OptionGroups";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import ErrorModal from "../UI/ErrorModal";
import { RootState } from "../../store";

const OptionsImageCard = (props) => {
  let [optionGroupBackUp, setOptionGroupBackUp] = useState(props.optionGroup);
  let [optionGroup, setOptionGroup] = useState(props.optionGroup); 
  const mandatory = optionGroup.mandatory;
  let minselects = optionGroup.minselects;
  let maxselects = optionGroup.maxselects;
  let expandView = props?.type;
  const [modifiers, setModifiers] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalEnabled, setModalEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [isSelectedSmoothie, setIsSelectedSmoothie] = useState(false);
  const ref = useRef(null);
  const [selectedSmoothieOption, setSelectedSmoothieOption] = useState({
    description: '',
    options: []
  });
  const productInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );

  if (!minselects) {
    minselects = 0;
    if (mandatory) {
      minselects = 1;
    }
  }

  useEffect(() => {
    let errorMessage = "";
    if (minselects) {
      if (selectedOptions.length < minselects) {
        errorMessage = `Please make a selection for "${optionGroup.description}"`;
      } else {
        errorMessage = "";
      }
      dispatch(
        cartActions.minSelectsError({
          minSelectErrors: {
            description: optionGroup.description,
            errorMessage: errorMessage,
          },
        })
      );
    }
  }, [selectedOptions.length]);

  const updateOptionsSelected = (option, isSingleSelection) => {
    ref?.current?.scrollIntoView()
    const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.id);
    if (isSelected) {
      setSelectedOptions(prevState => prevState.filter((ele) => ele.id !== option.id))
    }
    else {
      if ((selectedOptions.length === 0 || !maxselects) && isSingleSelection) {
        setSelectedOptions([option]);
      } else if ( (Boolean(maxselects) && selectedOptions.length <= maxselects - 1) || !isSingleSelection) { 
        setSelectedOptions([...selectedOptions, option]);
      }
    };
  }
  
  useEffect(() => {
    if (selectedOptions && selectedOptions.length > 0) {
      for (let i = selectedOptions.length - 1; i >= 0; i--) {
        if (
          selectedOptions[i].modifiers &&
          selectedOptions[i].modifiers.length
        ) {
          setModifiers((prevState) => {
            return [
              <OptionGroups
                key={selectedOptions[i].id}
                optionGroups={selectedOptions[i].modifiers}
                uniqueId={props.uniqueId}
              />,
            ];
          });
          break;
        }
      }
    } else {
      setModifiers((prevState) => {
        return [];
      });
    }
  }, [selectedOptions.length, selectedOptions[0]?.id]);

  

  const showError = () => {
    setErrorMessage(
      `Choose upto ${maxselects + 1} options for "${optionGroup.description}"`
    );
  };
  const onErrorModalClose = () => {
    setModalEnabled(false);
    setErrorMessage("");
  };
  const updateSeletedSmoothieProducts = (isSelected, description_GroupData, smoothieOpt) => {
    setSelectedSmoothieOption({
      description: description_GroupData,
      options:[smoothieOpt]
    })
    setIsSelectedSmoothie(isSelected);
  }
  
  useEffect(() => {
    if (isSelectedSmoothie) {
      setOptionGroup(selectedSmoothieOption);
    } else {
      setOptionGroup(optionGroupBackUp);
      setSelectedSmoothieOption({
        description: '',
        options: []
      })
    }
  }, [isSelectedSmoothie]);

  useEffect(() => {
    if (productInCart.length > 0 && props.isEdit) {
      productInCart.forEach((product) => {   
        if (product.id === props.uniqueId)
          product.choices.forEach((choice) => {
            dispatch(cartActions.addModifiers({ modifiers: choice.optionid }));
            optionGroup.options.forEach(option =>{
              if(option.id === choice.optionid){
                setSelectedOptions((prev) => [...prev, option])
                dispatch(cartActions.resetCost({cost: product.totalcost}))
                dispatch(cartActions.resetMinSelectErrors({ minSelectErrors: 0 }));
                if(option?.modifiers?.length > 0)
                  option?.modifiers[0].options?.forEach((extra) =>{
                    if(choice.optionid === extra.id){
                      setSelectedOptions((prev) => [...prev, extra])
                      dispatch(cartActions.resetCost({cost: product.totalcost}))
                    }
                  })
              }
            })
          });
      });
    }
  }, [productInCart.length]);

  return (
    <div>
      {expandView === "expand" ? (
        <div tabIndex={0} aria-label={optionGroup.description}>
          {errorMessage && modalEnabled ? (
            <ErrorModal onClose={onErrorModalClose} message={errorMessage} />
          ) : null}
          <div ref={optionGroup.description === "Choose Smoothie" ? ref : null}>
            <OptionGroupHeading heading={optionGroup.description} />  
          </div>
          <div className={classes["imagecard-main-expand"]}>
            {optionGroup.options && optionGroup.options.length
              ? optionGroup.options.map((option) => (  // 2
                <TypeImageCard
                  option={option}
                  key={option.id}
                  cardClick={updateOptionsSelected}
                  minselects={minselects}
                  maxselects={maxselects}
                  selectedOptions={selectedOptions}
                  showError={showError}
                  type={expandView}
                  product={props.product}
                  optionGroupData={optionGroup}
                  updateSeletedSmoothieProducts={updateSeletedSmoothieProducts}
                  isEdit = {props.isEdit ?? false}
                  uniqueId = {props.uniqueId}
                />
              ))
              : null}
          </div>
        </div>
      ) : (
        <div tabIndex={0} aria-label={optionGroup.description}>
          {errorMessage && modalEnabled ? (
            <ErrorModal onClose={onErrorModalClose} message={errorMessage} />
          ) : null}
          <div ref={optionGroup.description === "Choose Smoothie" ? ref : null}>
            <OptionGroupHeading heading={optionGroup.description} />  
          </div>
          <div className={classes["imagecard-parent"]}>
            <div className={classes["imagecard-main"]}>
              {optionGroup.options && optionGroup.options.length
                ? optionGroup.options.map((option) => (
                    <TypeImageCard
                      option={option}
                      key={option.id}
                      cardClick={updateOptionsSelected}
                      minselects={minselects}
                      maxselects={maxselects}
                      selectedOptions={selectedOptions}
                      showError={showError}
                      product={props.product}
                      optionGroupData={optionGroup}
                      updateSeletedSmoothieProducts={updateSeletedSmoothieProducts}
                      isSelectedSmoothie = {isSelectedSmoothie}
                      selectedSmoothieOption = {selectedSmoothieOption}
                      isEdit = {props.isEdit ?? false}
                      uniqueId = {props.uniqueId}
                    />
                ))
                : null}
            </div>
          </div>
          {modifiers && modifiers.length ? <div>{modifiers}</div> : null}
        </div>
      )}
    </div>
  );
};

export default OptionsImageCard;
