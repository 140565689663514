.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4)
  }
  
  .modal {
    top: 26vh;
    width: 80%;
    z-index: 400;
    overflow: hidden;
    margin: 0 auto;
    max-width: 600px;
    height: 150px;
    position: fixed;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
  }

  .close-button {
    position: absolute;
    padding: 26.36px;
    width: 20px;
    height: auto;
    box-sizing: border-box;
    font-size: 22px;
    font-weight: bold;
  }
  .close-button-customization {
    position: absolute;
    color: black;
    transform: rotate(45deg);
    margin-left: 8px;
    margin-top: -12px;
    font-size: 35px;
  }
  .customization-div {
    width: 189px;
    background: #FFFFFF;
    box-shadow: -3.375px 3.375px 16.875px rgb(0 0 0 / 10%);
    border-radius: 10px;
    max-height: 285px;
    overflow-y: auto;
    position: fixed;
    z-index: 990;
    top: 33%;
}
.bi-shuffle {
    width: 10px;
    color: #111111;
    margin-right: 4px;
}
.bi-shuffle::before {
    content: '\F544';
    font-size: 14px;
    font-weight: bold !important;
}
.customize-heading-div {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 10.125px;
    line-height: 12px;
    color: #111111;
    margin: 15px 0;
    gap: 5px;
}
.customize-heading {
  align-self: center;
}
  @media (max-width: 470px) {
    .modal {
      top: 1vh;
    }
  }