"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reward = exports.Balance = exports.Membership = exports.Loyalty = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * Loyalty Class
 */
var Loyalty = /** @class */ (function (_super) {
    __extends(Loyalty, _super);
    function Loyalty() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Loyalty class
     * @return {ObjectSchema}
     */
    Loyalty.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Loyalty;
}(BaseModel_1.BaseModel));
exports.Loyalty = Loyalty;
/**
 * Rewards Class
 */
var Membership = /** @class */ (function (_super) {
    __extends(Membership, _super);
    function Membership() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Membership class
     * @return {ObjectSchema}
     */
    Membership.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Membership;
}(BaseModel_1.BaseModel));
exports.Membership = Membership;
/**
 * Balance Class
 */
var Balance = /** @class */ (function (_super) {
    __extends(Balance, _super);
    function Balance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Rewards class
     * @return {ObjectSchema}
     */
    Balance.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Balance;
}(BaseModel_1.BaseModel));
exports.Balance = Balance;
/**
 * Rewards class
 */
var Reward = /** @class */ (function (_super) {
    __extends(Reward, _super);
    function Reward() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Rewards class
     * @return {ObjectSchema}
     */
    Reward.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Reward;
}(BaseModel_1.BaseModel));
exports.Reward = Reward;
