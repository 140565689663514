"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = exports.PaypalToken = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * GenerateToken Model
 */
var PaypalToken = /** @class */ (function (_super) {
    __extends(PaypalToken, _super);
    function PaypalToken() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for GenerateToken class
     * @return {ObjectSchema}
     */
    PaypalToken.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return PaypalToken;
}(BaseModel_1.BaseModel));
exports.PaypalToken = PaypalToken;
/**
 * Link  Model
 */
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for Link class
     * @return {ObjectSchema}
     */
    Link.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return Link;
}(BaseModel_1.BaseModel));
exports.Link = Link;
