.error-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.error-message {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #1D232E;
    margin: 20px 0px;
}
.cart-div {
    position: fixed;
    right: 0;
    height: 100%;
}
.main {
    padding: 30px 0px 30px 60px;
}
@media(max-width: 960px) {
    .main {
        padding: 30px 20px 30px 20px;
    }
}
@media(max-width: 768px) {
    .cart-div {
        position: initial;
    }
}