import { init, UTILS } from 'tsc-core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/Layout';
import './custom.scss';
import Menu from './pages/Menu';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Notification from './components/UI/Notification';
import { RootState } from './store';
import Loader from './components/UI/Loader';

function App() {
  const notification: any = useSelector((state: RootState) => state.ui.notification);
  const loader: any = useSelector((state: RootState) => state.loader.loader);
  const { REACT_APP_API_ENDPOINT, REACT_APP_AUTH_KEY } = process.env;
  
  init({
    baseURL: REACT_APP_API_ENDPOINT,
    deviceId: "8383",
    clientType: "Android",
    apiAuthKey: REACT_APP_AUTH_KEY,
  });
  UTILS.setAccessToken(
       "FJEPlGu6U5r9sXMShDBq4cTfRJY6c1eg" ?? 'NOTAUTHORIZED',
       "FJEPlGu6U5r9sXMShDBq4cTfRJY6c1eg" ?? 'NOTAUTHORIZED',
        );
  return (
    <Fragment>
      {notification && notification.message && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      {loader && loader.loaderVisible && (
        <Loader
          loaderVisible={loader.loaderVisible}
        />
      )}
      <Layout>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <Menu />
            </Route>
            <Route path='*'>
              <Menu />
            </Route>
          </Switch>
        </BrowserRouter>
      </Layout>
    </Fragment>
  );
}

export default App;
