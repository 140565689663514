"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplyPromo = exports.AddProductToBasketRequest = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * AddProductTOBasketRequest - used by basket api
 */
var AddProductToBasketRequest = /** @class */ (function (_super) {
    __extends(AddProductToBasketRequest, _super);
    /**
     * Constructor for AddProductToBasketRequest
     * @param {AddProductToBasketRequest} obj
     */
    function AddProductToBasketRequest(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for AddProductToBasketRequest
     * @return {ObjectSchema}
     */
    AddProductToBasketRequest.prototype.schema = function () {
        return validations_1.object({
            productid: validations_1.number(),
            quantity: validations_1.number(),
            options: validations_1.string(),
            specialinstructions: validations_1.optional(validations_1.string()),
            recipient: validations_1.optional(validations_1.string()),
            customdata: validations_1.optional(validations_1.string()),
        });
    };
    return AddProductToBasketRequest;
}(BaseModel_1.BaseModel));
exports.AddProductToBasketRequest = AddProductToBasketRequest;
/**
 * ApplyPromo - used by basket api
 */
var ApplyPromo = /** @class */ (function (_super) {
    __extends(ApplyPromo, _super);
    /**
     * Constructor for ApplyPromo
     * @param {ApplyPromo} obj
     */
    function ApplyPromo(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for UserLoginRequest
     * @return {ObjectSchema}
     */
    ApplyPromo.prototype.schema = function () {
        return validations_1.object({
            couponcode: validations_1.string(),
            coupontype: validations_1.optional(validations_1.string()),
            compamount: validations_1.optional(validations_1.string()),
        });
    };
    return ApplyPromo;
}(BaseModel_1.BaseModel));
exports.ApplyPromo = ApplyPromo;
