import classes from './Orders.module.css';
import OrderProducts from './OrderProducts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartData } from '../../store/update-cart-actions';
import { RootState } from '../../store';
import TSCButton from '../UI/Button';
import { getDatabase, onValue, ref } from "firebase/database";
import { firebaseConfig, TRANSLATION_PATH } from "../../constants/constants";
import { initializeApp } from "firebase/app";
import RecommendationBody from './recommendation/RecommendationBody';
import { useViewport } from '../../hooks/UseViewport';
import RecommendationModal from './recommendation/RecommendationModal';
import { API } from 'tsc-core';
import { getPerformance } from 'firebase/performance';

const Orders = (props) => {
  const [totalCost, setTotalCost] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const basketId2 = useSelector(
    (state: RootState) => state.cart.basketId
  ); 
   const [upsellModal, setUpsellModal] = useState(false);
   const [upsellViewed, setUpsellViewed] = useState(false);
  const { width } = useViewport();
  const breakpoint = 620;
  const content: any = useSelector((state: RootState) => state.buttercms.content);
  let individualName = props.products[0].recipient;
  const [addtoOrder, setAddtoOrderText] = useState('');
  const [upsellData, setUpsellData] = useState({
    groups: [],
  });
  const queryParams = new URLSearchParams(location.search);
  const basketId = queryParams.get("basket");
  const basketIdGO = queryParams.get("basketid")

  const showUpsellModal = () =>{
    setUpsellModal(true)
    setUpsellViewed(true)
  }

  const closeUpsellModal = () =>{
    setUpsellModal(false);
  }

  const getRecommendation = async () => {
    let response = await API.CART.getUpsell(basketId ?? basketIdGO as any);
    setUpsellData({
      groups: response?.groups as any,
    });
  };

  useEffect(() => {
    getRecommendation();
  }, [upsellModal]);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < props.products.length; i++) {
      total += props.products[i].totalcost;
      if (i == props.products.length - 1) {
        setTotalCost(total);
      }
    }
  }, [props.products]);
  const dispatch = useDispatch();
  const addQuantity = event => (product) => {
    dispatch(updateCartData(product.id, product.quantity + 1, product.choices, basketId2));
  }
  const removeQuantity = event => (product) => {
    if (product.quantity > 0) {
      dispatch(updateCartData(product.id, product.quantity - 1, product.choices, basketId2));
    }
  }
  const submitClickedHandler = (event) => {
    setSubmitClicked(true);
    setUpsellModal(false)
    localStorage.setItem('basketId', '');
    // localStorage.setItem('productsInCart', JSON.stringify([]));
    localStorage.setItem('name', '');
    props.finalSubmitHandler(totalCost); 
  }
  const productsInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );
  useEffect(() => {
    const fetchFromFirebase = async() =>{
    const app = initializeApp(firebaseConfig);   
    const firebaseDB = getDatabase(app);
    const query = ref(firebaseDB, TRANSLATION_PATH);
    var firebaseData = null;
    await onValue(query, (snapshot) => {
        firebaseData = snapshot.val(); 
        setAddtoOrderText(firebaseData.AddCartGroupOrder)
    });
  }
  fetchFromFirebase().catch(console.error)
  }, []);
  return (
    <div className={classes['cart-main']}>
      <div>
        <div>
          <h2 className={!submitClicked ? classes['cart-heading'] : classes['cart-heading-final']}>Cart</h2>
          <h3 className={!submitClicked ? classes['team-name'] : classes['team-name-final']}>{individualName}</h3>
          <hr className={classes['cart-hr']} />
        </div>
        <div className={upsellData.groups.length === 1 ? classes['products-main-medium'] : 
        !submitClicked && upsellData.groups.length > 0 ? classes['products-main'] : classes['products-main-final']}>
            {props.products.map(product => {
              return <OrderProducts finalSubmit={props.finalSubmit} product={product} key={product.id} addQuantity={addQuantity} removeQuantity={removeQuantity} submitClicked={submitClicked} />
            })}
          </div>
          {productsInCart.length && !submitClicked ? <RecommendationBody/>: null}

      </div>
      <div id='backdrop-modal'></div>
      <div id="overlay-modal"></div>
      <div>
        <div className={classes['total-div']}>
          <p className={classes['total-label']}>Total</p>
          <p className={classes['total-cost']}>${totalCost.toFixed(2)}</p>
        </div>
        <div className={classes['final-div']}>
         {!submitClicked && (width < breakpoint) && upsellData.groups.length > 0 && !upsellViewed ? 
            <TSCButton onButtonClick={showUpsellModal} className={classes['final-submit-btn']}>{addtoOrder}</TSCButton> : 
          !submitClicked ? 
            <TSCButton onButtonClick={submitClickedHandler} className={classes['final-submit-btn']}>{addtoOrder}</TSCButton> :
            null
         }
       </div>
      </div>
      {
        upsellModal && upsellData.groups.length > 0 ? <RecommendationModal submitClickedHandler={submitClickedHandler} closeUpsellModal={closeUpsellModal} /> : null
      }
    </div>
  );
};

export default Orders;