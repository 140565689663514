import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeCartData } from '../../store/remove-cart-actions';
import { updateCartData } from '../../store/update-cart-actions';
import classes from './OrderProducts.module.css';
import ProductDetailModal from '../productDetail/ProductDetailModal';
import { RootState } from '../../store';
import { cartActions } from '../../store/cart-slice';
import NutritionInfoModal from '../productDetail/NutritionInfoModal';
import { API } from 'tsc-core';
import { firebaseAnalytics } from '../helpers/FirebaseAnalytics';

const OrderProducts = (props) => {
  const product = props.product;
  const [showDetails, setShowDetails] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const catelog = useSelector((state: RootState) => state.catelog.categories);
  let productForEdit 
      catelog.forEach(category =>{
      category.products.forEach(item =>{
      if(item.id === product.productId) productForEdit = item
    })
    
  })

  const productsInCart = useSelector(
    (state: RootState) => state.cart.productsInCart
  );

  
  const [isAddCartModalVisible, setAddCartModalVisible] = useState(false);
  const [isNutritionModalVisible, setNutritionModalVisible] = useState(false);

  const [nutritionInfoList, setNutritionInfoList] = useState({});

  const fetchNutritionData = async (currentProduct) => {
    if (Object.keys(currentProduct)?.length > 0) {
      try {
        const response = await API.CMS.getPage(
          currentProduct?.chainproductid + "?rawdatarequired=true"
        );
        setNutritionInfoList(response);
      } catch (error) {
        firebaseAnalytics(error, 'GO_ERR_NUTRITION_DATA_API')
        setNutritionInfoList({});
      }
    }
  };

  const onCloseNutritionPopup = () => {
    setNutritionModalVisible(false);
  };

  const onCloseAddCartPopup = () => {
    setEditCheck(false);
    dispatch(cartActions.setIsEdit({ isEdit: false }));
  };
  const onShowNutritionPopup = () => {
    setNutritionModalVisible(true);
  };
  const onModalCloseHandler = (event) => {
    setEditCheck(false);dispatch
    dispatch(cartActions.setIsEdit({ isEdit: false }));
  };
  const basketId2 = useSelector(
    (state: RootState) => state.cart.basketId
  );
  const dispatch = useDispatch();
  const addQuantity = quantity => event => {
    dispatch(updateCartData(product.id, quantity + 1, product.choices, basketId2));
  }
  const removeQuantity = quantity => event => {
    if (quantity > 1) {
      dispatch(updateCartData(product.id, quantity - 1, product.choices, basketId2));
    }
  }

  const removeProduct = event => {
    dispatch(removeCartData(product.id, basketId2));
  }
  const showDetailsClicked = event => {
    setShowDetails(prevState => !prevState);
  }
  useEffect(() => {
    if (
      productForEdit &&
      Object.keys(productForEdit)?.length > 0 &&
      productForEdit?.chainproductid
    ) {
      fetchNutritionData(productForEdit);
    }
  }, [productForEdit]);

  useEffect(() => {
    setEditCheck(false)
  }, [productsInCart])
  
  return (
    <div className={classes["product-div"]}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <h4 className={classes["product-name"]} role="button" tabIndex={0}>
          {product.name}
        </h4>
        {
          !props.finalSubmit ? (
            <button onClick={() =>{ 
              setEditCheck(true)
              dispatch(cartActions.resetMinSelectErrors({ minSelectErrors: 0 }));
              dispatch(cartActions.setIsEdit({ isEdit: true }));
            }      
          }>
             <span style={{color: '#CF0082'}} className="material-icons" >edit</span>
            </button>
          ) :
          null
        }
      </div>
      {product.choices && product.choices.length ? (
        showDetails ? (
          <div>
            {product.choices.map((choice) => {
              return (
                <p className={classes["product-choice"]} key={choice.id}>
                  {choice.name}
                </p>
              );
            })}
            <p className={classes["show-details"]} onClick={showDetailsClicked}>
              Hide details
            </p>
          </div>
        ) : (
          <p className={classes["show-details"]} onClick={showDetailsClicked}>
            Show details
          </p>
        )
      ) : null}

      <div className={classes["product-modify-div"]}>
        {!props.submitClicked ? (
          <button className={classes["remove-div"]} onClick={removeProduct}>
            <i className={classes["bi-trash"]}></i>
            <p className={classes["remove"]}>Remove</p>
          </button>
        ) : null}
        {!props.submitClicked ? (
          <div className={classes["quantity-div"]}>
            <button onClick={removeQuantity(product.quantity)}>
              <i
                className={
                  product.quantity > 1
                    ? classes["bi-dash-circle-fill"]
                    : classes["bi-dash-circle-fill"] +
                      " " +
                      classes["disable-remove"]
                }
              />
            </button>
            <span tabIndex={0} className={classes["quantity"]}>
              {product.quantity}
            </span>
            <button onClick={addQuantity(product.quantity)}>
              <i className={classes["bi-plus-circle-fill"]} />
            </button>
          </div>
        ) : (
          <div>
            <span className={classes["quantity"]}>{product.quantity}</span>
          </div>
        )}

        <p className={classes["product-totalcost"]}>
          ${product.totalcost.toFixed(2)}
        </p>
      </div>
      <hr className={classes["product-hr"]} />
      {editCheck && (
        <ProductDetailModal
          onCloseAddCartModal={onCloseAddCartPopup}
          onShowNutritionModal={onShowNutritionPopup}
          product={productForEdit}
          onModalClose={onModalCloseHandler}
          isEdit
          uniqueId={product.id}
        />
      )}
      {isNutritionModalVisible ? (
        <NutritionInfoModal
          product={productForEdit}
          onCloseNutritionDetailsModal={onCloseNutritionPopup}
          onModalClose={onModalCloseHandler}
          nutrientsData={nutritionInfoList}
        />
      ) : null}
    </div>
  );
};

export default OrderProducts;