.imagecard-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 19px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.imagecard-main-expand {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 19px;
    margin-top: 20px;
    margin-bottom: 10px;
}
@media (max-width: 960px) {
    .imagecard-parent {
        overflow-x: auto;
        padding-left: 10px;
        margin-left: -10px;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }   
    .imagecard-parent::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .imagecard-main {
        flex-wrap: nowrap;
    }
}