.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
}
.modal {
  top: 15vh;
  width: 85%;
  z-index: 400;
  overflow: hidden;
  min-height: 150px;
  position: fixed;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  border-radius: 8px;
}
.close-button {
  position: absolute;
  padding: 26.36px;
  width: 18px;
  height: auto;
  box-sizing: border-box;
}
