import { useState } from "react";
import RecommendationCard from "./RecommendationCard";
import classes from "./RecommendationRow.module.css";

const RecommendationRow = (props) => {
  const [state, setState] = useState(false)

  
  return (
    <div className={classes["recommendation-row-container"]}>
      <h6 className={classes["title"]}>{props.title}</h6>
      <div className={classes["items"]}>
        {props.items.map((item) => (
          <RecommendationCard setState={setState} item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default RecommendationRow;