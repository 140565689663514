"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CMSComponentFields = exports.CMSComponent = exports.CMSCollection = exports.CMSPage = void 0;
var superstruct_1 = require("superstruct");
var BaseModel_1 = require("../BaseModel");
/**
 * CMSPage model
 */
var CMSPage = /** @class */ (function (_super) {
    __extends(CMSPage, _super);
    function CMSPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for CMSPage class
     * @return {ObjectSchema}
     */
    CMSPage.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CMSPage;
}(BaseModel_1.BaseModel));
exports.CMSPage = CMSPage;
/**
 * CMSCollection model
 */
var CMSCollection = /** @class */ (function (_super) {
    __extends(CMSCollection, _super);
    function CMSCollection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for CMSCollection class
     * @return {ObjectSchema}
     */
    CMSCollection.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CMSCollection;
}(BaseModel_1.BaseModel));
exports.CMSCollection = CMSCollection;
/**
 * CMS Component Model
 */
var CMSComponent = /** @class */ (function (_super) {
    __extends(CMSComponent, _super);
    function CMSComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for CMSComponent class
     * @return {ObjectSchema}
     */
    CMSComponent.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CMSComponent;
}(BaseModel_1.BaseModel));
exports.CMSComponent = CMSComponent;
/**
 * CMS Component Fields Model
 */
var CMSComponentFields = /** @class */ (function (_super) {
    __extends(CMSComponentFields, _super);
    function CMSComponentFields() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Schema for CMSComponent class
     * @return {ObjectSchema}
     */
    CMSComponentFields.prototype.schema = function () {
        return superstruct_1.type({});
    };
    return CMSComponentFields;
}(BaseModel_1.BaseModel));
exports.CMSComponentFields = CMSComponentFields;
