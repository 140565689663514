"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processError = exports.processOloError = exports.processOloResponse = exports.processResponse = void 0;
var _ = require("lodash");
var exceptions_1 = require("../exceptions");
/**
 * Process and throw common errors
 * @param {AxiosResponse} apiResponse
 * @return {AxiosResponse}
 */
var processResponse = function (apiResponse) {
    var errors = new Map(_.flatMap(_.map(apiResponse.data.invalidData, function (invalidItem) {
        if ('errorField' in Object.keys(invalidItem)) {
            return _.map(invalidItem.errorField.split(','), function (field) { return [
                _.trim(field),
                { code: invalidItem.errorCode, message: invalidItem.errorMessage },
            ]; });
        }
        else {
            return [];
        }
    })));
    if (apiResponse.status >= 500) {
        throw new exceptions_1.ServerException(apiResponse.status);
    }
    if (Object.keys(apiResponse.data).indexOf('statusCode') > -1 &&
        apiResponse.data.statusCode != 2000) {
        throw new exceptions_1.ApiException(apiResponse.data.statusCode, apiResponse.data.status, apiResponse.data.message, errors, apiResponse.data.invalidData);
    }
    return apiResponse.data;
};
exports.processResponse = processResponse;
/**
 * Process and throw common errors
 * @param {AxiosResponse} apiResponse
 * @return {AxiosResponse}
 */
var processOloResponse = function (apiResponse) {
    if (apiResponse.status >= 500) {
        throw new exceptions_1.ServerException(apiResponse.status);
    }
    if (apiResponse.status != 200) {
        throw new exceptions_1.ApiException();
    }
    return apiResponse.data;
};
exports.processOloResponse = processOloResponse;
/**
 * Process and throw common errors
 * @param {AxiosError} errorResponse
 * @return {ApiException}
 */
var processOloError = function (errorResponse) {
    var _a;
    var responseMsg = (_a = errorResponse.response) === null || _a === void 0 ? void 0 : _a.data;
    return new exceptions_1.ApiException(responseMsg.code, '', responseMsg.message);
};
exports.processOloError = processOloError;
/**
 * Process and throw common errors
 * @param {AxiosError} errorResponse
 * @return {ApiException}
 */
var processError = function (errorResponse) {
    var _a, _b;
    var responseMsg = (_a = errorResponse.response) === null || _a === void 0 ? void 0 : _a.data;
    var status = ((_b = errorResponse.response) === null || _b === void 0 ? void 0 : _b.status)
        ? errorResponse.response.status
        : responseMsg === null || responseMsg === void 0 ? void 0 : responseMsg.status;
    if (responseMsg || status) {
        return new exceptions_1.ApiException(status, '', responseMsg === null || responseMsg === void 0 ? void 0 : responseMsg.error);
    }
    else {
        throw errorResponse;
    }
};
exports.processError = processError;
