import { uiActions } from "./ui-slice";
import { productDetailActions } from "./product-details-slice";
import { API } from "tsc-core";
import { loaderActions } from "./loader-slice";
import { firebaseAnalytics } from "../components/helpers/FirebaseAnalytics";

export const fetchProductDetail = (productId) => {
  return async (dispatch: any) => {
    const fetchProduct = async () => {
      dispatch(
        loaderActions.setLoader({
          loaderVisible: true,
        })
      );
      const response = await API.MENU.productModifiers(productId);
      return response;
    };

    try {
      const productData: any = await fetchProduct();
      dispatch(
        productDetailActions.getAllOptions({
          optionGroups: productData.optiongroups || [],
        })
      );
      dispatch(
        loaderActions.setLoader({
          loaderVisible: false,
        })
      );
    } catch (error) {
      firebaseAnalytics(error, 'GO_ERR_PRODUCT_MODIFIERS_API')
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Fetching cart data failed!",
        })
      );
      dispatch(
        loaderActions.setLoader({
          loaderVisible: false,
        })
      );
    }
  };
};
