import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { addCartData } from "../../../store/cart-actions";
import { cartActions } from "../../../store/cart-slice";
import { removeCartData } from "../../../store/remove-cart-actions";
import { updateCartData } from "../../../store/update-cart-actions";

import classes from './RecommendationCard.module.css';

const RecommendationCard = (props) => {
  const dispatch = useDispatch()
  const catelog = useSelector((state: RootState) => state.catelog.categories);
  let imageUrl = "";
  const image = require("../../../assets/noimage.png");
  const imagePath = useSelector((state: RootState) => state.catelog.imagePath);
  let upsellProduct;
  const productsInCart = useSelector((state: RootState) => state.cart.productsInCart);
  let isSelected = false;
  const [selected, setSelected] = useState(false);
  const [productQuantity, setProductQuantity] = useState(0);
  const basketId2 = useSelector(
    (state: RootState) => state.cart.basketId
  );
  const cafeId2 = useSelector(
    (state: RootState) => state.cart.cafeId
  );

  useEffect(() => {
    dispatch(cartActions.setUpsellItems(props.item.id))
  }, [props.item])

  const addToCart = () => {
    setSelected(!selected);
    setProductQuantity(1);
    if (!selected) {
      dispatch(addCartData(props.item.id,basketId2,cafeId2, 1, "yes"));

      productsInCart.forEach((product) => {
        if (product.productId === props.item.id) {
          setProductQuantity(product.quantity);
        }
      });
    } else {
      productsInCart.forEach((product) => {
        if (product.productId === props.item.id) {
          dispatch(removeCartData(product.id,basketId2));
        }
      });
    }
  };
  const removeQuantity = quantity => event => {
    if (quantity > 1) {
      productsInCart.forEach(product => {
        if(product.productId === props.item.id) {
          setProductQuantity((prev) => prev - 1);
          dispatch(updateCartData(product.id, quantity - 1, [], basketId2));
        }
      })
    }
  }

  const addQuantity = quantity => event => {
    setProductQuantity((prev) => prev + 1);
    if(productQuantity === 0){
        addToCart();
    }
    productsInCart.forEach(product => {
        if(product.productId === props.item.id) {
        dispatch(updateCartData(product.id, quantity + 1, [], basketId2));
        }
    }) 
    
  }

  catelog.forEach((cat) => {
    cat.products.forEach(product => {
      if(props.item.id === product.id) {
        upsellProduct = product
        if (product.images && product.images.length) {
          let groupName = "mobile-webapp-menu";
          for (let prodImage of product.images) {
            if (window.innerWidth <= 960) {
              groupName = "mobile-app";
            }
            if (prodImage.groupname == groupName) {
              imageUrl = imagePath + prodImage.filename;
              break;
            }
          }
        }
      }
    })
  })
  const clickHandler = () =>{
    addToCart()
  }

  useEffect(() => {
    productsInCart.forEach(product => {
      if(product.productId === props.item.id){
        isSelected = true;
        setProductQuantity(product.quantity)
      }
    })
    if(isSelected && productQuantity > 0) 
      setSelected(true)
    else {
      setProductQuantity(0)
      setSelected(false)
    }
  }, [productsInCart])

  
  return (
    <button className={selected ? classes["selected-item"] : classes["item"]}>
      <div
        className={classes["item-container"]}
        onClick={clickHandler}
      >
        <img width="100%" height="auto" src={imageUrl ? imageUrl : image} />
        <span className={classes["name"]}>
          {props.item.name}
        </span>
        <span className={classes["calories"]}>{props.item.calories ?? ""}</span> 
        <span className={classes["cost"]} >
          {props.item.cost}
        </span>
      </div>
      {selected ? (
        <div className={classes["quantity-div"]}>
          <button onClick={removeQuantity(productQuantity)}>
            <i className={productQuantity > 1
                  ? classes["bi-dash-circle-fill"]
                  : classes["bi-dash-circle-fill"] + " " + classes["disable-remove"]
              }
            />
          </button>
          <span tabIndex={0} className={classes["quantity"]}>
            {productQuantity}
          </span>
          <button onClick={addQuantity(productQuantity)}>
            <i className={classes["bi-plus-circle-fill"]} />
          </button>
        </div>
      ) : null}
    </button>
  );
};

export default RecommendationCard;