.option-group-heading  {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #111111;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
@media (max-width:470px) {
    .option-group-heading {
        margin-top: 10px;
        margin-left: 25px;
    } 
}