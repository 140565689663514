"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
var superstruct_1 = require("superstruct");
/**
 * BaseModel class to be extended by all models
 */
var BaseModel = /** @class */ (function () {
    /**
     * Constructor of BaseModel
     * @param {Object} obj
     */
    function BaseModel(obj) {
        Object.assign(this, obj);
    }
    // eslint-disable-next-line valid-jsdoc
    /**
     * Validate this class based on given schema
     */
    BaseModel.prototype.doValidation = function () {
        return superstruct_1.validate(this, this.schema());
    };
    /**
     * Schema for valiation
     * @return {ObjectSchema} - used for validation
     */
    BaseModel.prototype.schema = function () {
        return superstruct_1.object({});
    };
    return BaseModel;
}());
exports.BaseModel = BaseModel;
