.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal {
    top: 26vh;
    width: 80%;
    z-index: 301;
    overflow: hidden;
    margin: 0 auto;
    max-width: 600px;
    min-height: 150px;
    position: fixed;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    display: flex;
  }

  .close-button {
    position: absolute;
    padding: 26.36px;
    width: 18px;
    height: auto;
    box-sizing: border-box;
  }
  .error-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    flex-grow: 1;
  }
  .error-message {
    display: flex;
    flex-grow: 1;
    align-items: center;
    text-align: center;
  }