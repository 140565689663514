.heading {
    font-style: normal;
    font-weight: 900;
    font-size: 44px;
    line-height: 52px;
    text-transform: uppercase;
    color: #111111;
}
@media(max-width: 960px) {
    .heading  {
        font-size: 33px;
        line-height: auto;
    }
}
@media(max-width: 470px) {
    .heading {
        font-size: 28px;
        line-height: auto;
    }
}