import { Fragment } from "react";
import classes from "./MenuList.module.css";
import MenuItem from "./MenuItem";

const SearchMenuList = (props) => {
  const catelog = props.catelog;
  const searchText = props.searchText;
  const categories = Object.keys(catelog);

  let catelogToShow = [];
  let searchContent = null;

  if (searchText) {
    for (let category of categories) {
      catelog[category].forEach((item) => {
        item.products.forEach((product) => {
          if (
            product.name.toLowerCase().includes(searchText.toLowerCase()) ||
            product.description.toLowerCase().includes(searchText.toLowerCase())
          ) {
            catelogToShow.push(product);
          }
        });
      });
    }
  }
  if (catelogToShow.length) {
    searchContent = (
      <div className={classes["menu-list-main"]}>
        {catelogToShow.map((catelogItem) => (
          <MenuItem
            key={catelogItem.id}
            product={catelogItem}
            onItemSelect={props.onProductSelect}
            addedProduct={props.addedProduct}
          />
        ))}
      </div>
    );
  } else {
    searchContent = (
      <div className="alert alert-danger" role="alert">
        No products found
      </div>
    );
  }
  return <Fragment>{searchContent}</Fragment>;
};

export default SearchMenuList;
