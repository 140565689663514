"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavouriteBasket = void 0;
var validations_1 = require("../../utils/validations");
var BaseModel_1 = require("../BaseModel");
/**
 * Data required to make FavouriteBasket request
 */
var FavouriteBasket = /** @class */ (function (_super) {
    __extends(FavouriteBasket, _super);
    /**
     * Constructor for FavouriteBasket
     * @param {FavouriteBasket} obj
     */
    function FavouriteBasket(obj) {
        return _super.call(this, obj) || this;
    }
    /**
     * Validation schema for FavouriteBasket
     * @return {ObjectSchema}
     */
    FavouriteBasket.prototype.schema = function () {
        return validations_1.object({
            basketid: validations_1.string(),
            description: validations_1.string(),
            isdefault: validations_1.optional(validations_1.boolean()),
            ignoreunavailableproducts: validations_1.boolean(),
        });
    };
    return FavouriteBasket;
}(BaseModel_1.BaseModel));
exports.FavouriteBasket = FavouriteBasket;
